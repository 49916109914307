import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {
    ModalHeader,
    ModalBody,
    Col,
    Modal,
    Label,
    FormGroup,
    InputGroupAddon, Input, InputGroup, Button
} from 'reactstrap';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import SingleDatePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'moment-timezone';
import {toast} from "react-toastify";

moment.tz.setDefault("America/Halifax");

class FscSchedule extends React.Component {
    constructor(props) {
        super(props);
        this.updateDates = this.updateDates.bind(this);
        this.toggle = this.toggle.bind(this);
        this.updateValue = this.updateValue.bind(this);
        this.state = {
            fsc: [],
            new_fsc: {
                amount: "",
                date: moment().day(1)
            },
            fsc_modal: false,
            loading: true
        };
    }

    componentDidMount() {
        document.title = "FSC Schedule | Custom TMS";
        this.update_fsc();
    }

    update_fsc() {
        var self = this;
        axios.get(API_ROOT + '/fsc')
            .then(function (response) {
                self.setState({
                    fsc: response.data,
                    loading: false
                })
            })
    }

    // on date range change
    updateDates(event, picker) {
        if (event.type == "apply") {
            var new_fsc = this.state.new_fsc;
            new_fsc['date'] = picker.startDate;
            this.setState({
                new_fsc: new_fsc
            });
        }
    }

    addFsc = () => {
        var self = this;
        axios.defaults.withCredentials = true;
        axios.post(API_ROOT + '/fsc', this.state.new_fsc)
            .then(function (response) {
                self.update_fsc()
                toast.success("FSC Added", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                self.setState({
                    new_fsc: {
                        amount: 0,
                        date: moment().day(1)
                    }
                });
                self.toggle();
            })
            .catch(function (error) {
                if (typeof error.response != "undefined") {
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error", error.response.data.Message, "error");
                    }
                }
            });
    };

    updateValue(event, name, value) {
        if (event.target) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (typeof name == "undefined") {
            name = event.target.name
        }
        if (typeof value == "undefined") {
            value = event.target.value
        }
        var new_fsc = this.state.new_fsc;
        new_fsc[name] = value;
        this.setState({
            new_fsc: new_fsc
        });
    }

    toggle = () => {
        console.log('toggle clicked')
        this.setState({
            fsc_modal: !this.state.fsc_modal
        });
    }

    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>FSC Schedule</div>
                    <Button color="primary" size="sm" onClick={this.toggle} className="ml-auto"><i className="fas fa-plus"></i> Rate</Button>
                </div>
                <div className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <div>
                        <div className="card-body">
                            <div className="alert alert-info">Entered FSC rates will be applied when a load is created that took/takes places on the scheduled date or onwards</div>
                            <table className={(this.state.fsc.length > 0 ? "table table-sm text-right" : "d-none")}>
                                <thead>
                                <tr>
                                    <th className="text-left">Start Date</th>
                                    <th className="text-left">FSC Rate</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.fsc.map(function (item, key) {
                                    return (
                                        <tr key={key}>
                                            <td className="text-left">{moment(item.start_date).utc().format("ddd MMM Do, YYYY")}</td>
                                            <td className="text-left">{item.amount.toFixed(2)}%</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.fsc_modal} fade={false} toggle={this.toggle} size="sm">
                    <ModalHeader>
                        <div className="h3 mborder-0">Add FSC Rate</div>
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup row>
                            <Label sm={4}>Start Date:</Label>
                            <Col sm={8}>
                                <SingleDatePicker
                                    startDate={this.state.new_fsc.date}
                                    endDate={this.state.new_fsc.date}
                                    onEvent={this.updateDates}
                                    autoUpdateInput={true}
                                    singleDatePicker={true}
                                >
                                    <button type="button" className="btn btn-light selected-date-range-btn mr-1">
                                        {this.state.new_fsc.date.format("MMM Do/YY")}
                                    </button>
                                </SingleDatePicker>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col sm={4}>Amount</Col>
                            <Col sm={8}>
                                <InputGroup size="sm">
                                    <Input type="text" name="amount" className="text-right" placeholder="0.000" value={this.state.new_fsc.amount} onChange={this.updateValue}/>
                                    <InputGroupAddon addonType="append">%</InputGroupAddon>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                        <button type="button" className="btn btn-primary" onClick={() => this.addFsc()}>Add FSC Rate</button>
                    </ModalBody>
                </Modal>
            </ContentWrapper>
        );
    }
}

export default (FscSchedule);