import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'moment-timezone';
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown} from "reactstrap";
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';
import {getEquipmentDetails} from "../Equipment/EquipmentFunctions";
import AddMaintenance from "./AddMaintenance";

moment.tz.setDefault("America/Halifax");

var money = require("money-math");

class MaintenanceList extends React.Component {
    constructor(props) {
        super(props);

        this.updateDates = this.updateDates.bind(this);

        this.state = {
            vehicles: {},
            vehicle_id: (localStorage.getItem('vehicle_id') != null ? localStorage.getItem('vehicle_id') : null),
            vehicles_sort: [],
            maint_id: null,
            maint_recs: {
                list: [],
                loading: true,
            },
            bar_data: [],
            startDate: (localStorage.getItem('fu_startDate') != null ? moment(localStorage.getItem('fu_startDate')) : moment("14 May 2023")),
            endDate: (localStorage.getItem('fs_endDate') != null ? moment(localStorage.getItem('fs_endDate')) : moment().endOf('day')),
            ranges: {
                'Today': [moment(), moment()],
                'Last 7 Days': [moment().subtract(6, 'days'), moment().endOf('day')],
                'Last 30 Days': [moment().subtract(29, 'days'), moment().endOf('day')],
                'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                'This Year': [moment().startOf('year'), moment().subtract(1, 'day').endOf('day')],
                'All Time': [moment("14 May 2023"), moment().endOf('day')]
            },
            status: null,
            statuses: {0: "Pending Auth.", 1: "Declined", 2: "Approved", 3: "Completed, Not Billed", 4: "Completed"},
            sort: (localStorage.getItem('sort') != null ? localStorage.getItem('sort') : "name"),
            sort_order: (localStorage.getItem('sort_order') != null ? localStorage.getItem('sort_order') * -1 : -1),
        };
    }

    componentDidMount() {
        document.title = "Maintenance | Custom TMS";
        this.refreshMaintenance();
    }

    // on date range change
    updateDates(event, picker) {
        if (event.type == "apply") {
            localStorage.setItem('fu_startDate', picker.startDate);
            localStorage.setItem('fu_endDate', picker.endDate);
            this.setState({
                startDate: picker.startDate,
                endDate: picker.endDate
            }, function () {
                this.refreshMaintenance();
            });
        }
    }

    updateValue(event, name, value) {
        var self = this;
        if (event.target) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (typeof name == "undefined") {
            name = event.target.name
        }
        if (typeof value == "undefined") {
            value = event.target.value
        }
        localStorage.setItem(name, value);
        this.setState({
            [name]: value
        }, () => {
            self.refreshMaintenance();
        });
    }

    onSort(event, sortKey) {
        localStorage.setItem('sort', sortKey);
        localStorage.setItem('sort_order', this.state.sort_order);
        var sort_order = this.state.sort_order * -1;
        if (sortKey != this.state.sort) {
            sort_order = 1;
        }

        function format_search_val(val) {
            if (isNaN(val)) {
                return val.toLowerCase();
            } else {
                return parseFloat(val);
            }
        }

        var maint_recs = this.state.maint_recs;
        maint_recs['list'] = maint_recs.list.sort((a, b) => {
            if (typeof format_search_val(a[sortKey]) == "string" && typeof format_search_val(b[sortKey]) != "string") {
                return (sort_order == -1 ? -1 : 1);
            } else {
                if (format_search_val(a[sortKey]) > format_search_val(b[sortKey])) {
                    return (sort_order == -1 ? -1 : 1);
                }
                if (format_search_val(a[sortKey]) < format_search_val(b[sortKey])) {
                    return (sort_order == -1 ? 1 : -1);
                }
            }
            return 0;
        });
        this.setState({
            maint_recs: maint_recs,
            sort_order: sort_order,
            sort: sortKey
        });
    }

    refreshMaintenance = () => {
        var self = this;
        getEquipmentDetails(null, 0, this.state.startDate, function (vehicles) {
            if (Object.keys(vehicles).length > 0) {
                var vehicles_sort = [];
                for (const vehicle_id of Object.keys(vehicles)) {
                    if (vehicles[vehicle_id].customer_id == 0) {
                        vehicles_sort.push(vehicles[vehicle_id])
                    }
                }
                vehicles_sort = vehicles_sort.sort(function (a, b) {
                    if (a.name < b.name) {
                        return -1
                    } else {
                        return 1
                    }
                });
            }

            axios.get(API_ROOT + '/maintenance/?equipment_id=' + self.state.vehicle_id + "&startDate=" + self.state.startDate + "&endDate=" + self.state.endDate)
            .then(function (response) {
                var bar_data = [];
                for (var equipment of vehicles_sort) {
                    var total_spent = 0;
                    for (var fillup of response.data) {
                        if (fillup.equipment_id == equipment.equipment_id) {
                           total_spent = total_spent + (fillup.cost_actual == null ? fillup.cost_est : fillup.cost_actual);
                        }
                    }
                    if (total_spent>0) {
                        bar_data.push({
                            name: equipment.name,
                            Spent: total_spent,
                            CostPerMile: (equipment.distance_travelled>100?(total_spent/equipment.distance_travelled/1.609344).toFixed(2):0)
                        });
                    }
                }
                self.setState({
                    vehicles: vehicles,
                    vehicles_sort: vehicles_sort,
                    bar_data: bar_data,
                    maint_recs: {
                        list: response.data,
                        loading: false,
                    },
                    maint_id: null
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
        });
    }

    viewMaintenance = (maint_id) => {
        this.setState({
            maint_id: maint_id
        })
    }

    render() {
        // date range selector variables
        let start = this.state.startDate.format('MMM. D/YY');
        let end = this.state.endDate.format('MMM. D/YY');
        let label = start + ' - ' + end;
        if (start === end) {
            label = start;
        }

        var total_actual_cost = money.floatToAmount(0);
        var total_est_cost = money.floatToAmount(0);

        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>Maintenance</div>
                    <div className="ml-auto">
                        <AddMaintenance maint_id={null} equipment={this.state.vehicles} equipment_id={null} refreshMaintenance={this.refreshMaintenance}/>
                    </div>
                </div>
                <div className="mb-3 form-inline" style={{zIndex: 1000}}>
                    <DateRangePicker
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        ranges={this.state.ranges}
                        maxDate={moment()}
                        onEvent={this.updateDates}
                        autoUpdateInput={true}
                    >
                        <button type="button" className="btn btn-primary selected-date-range-btn mr-1">
                            Date: <b>{label}</b> <span className="fas fa-caret-down fa-sm"></span>
                        </button>
                    </DateRangePicker>
                    <UncontrolledButtonDropdown className="ml-1">
                        <DropdownToggle color="primary" caret style={{fontSize: '13px', width: 'auto'}}>
                            {(this.state.vehicle_id == null || typeof this.state.vehicles[this.state.vehicle_id] == "undefined" ? "All Equipment" : this.state.vehicles[this.state.vehicle_id].name)}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem key="null" onClick={() => this.updateValue(false, 'vehicle_id', null)}>All Equipment</DropdownItem>
                            <DropdownItem divider/>
                            {this.state.vehicles_sort.map((equipment, i) => {
                                return (<DropdownItem key={equipment.equipment_id}
                                                      onClick={() => this.updateValue(false, 'vehicle_id', equipment.equipment_id)}>{equipment.name}</DropdownItem>)
                            })}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                    <UncontrolledButtonDropdown className="ml-1">
                        <DropdownToggle color="primary" caret style={{fontSize: '13px', width: 'auto'}}>
                            {(this.state.status == null ? "All Statuses" : this.state.statuses[this.state.status])}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem key="null" onClick={() => this.updateValue(false, 'status', null)}>All Statuses</DropdownItem>
                            <DropdownItem divider/>
                            {Object.keys(this.state.statuses).map((key, i) => {
                                return (<DropdownItem key={key} onClick={() => this.updateValue(false, 'status', key)}>{this.state.statuses[key]}</DropdownItem>)
                            })}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
                <div className={(this.state.maint_recs.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <div>
                        <div className="card-header">
                            <div className="card-title">{this.state.maint_recs.list.length.toLocaleString()} Total Maintenance Items
                                <div className="d-none">
                                    <AddMaintenance maint_id={this.state.maint_id} equipment={this.state.vehicles} equipment_id={null}
                                                    refreshMaintenance={this.refreshMaintenance}/>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div style={{width: "100%", height: "300px"}} className={(this.state.bar_data.length > 0 ? "" : "d-none")}>
                                <ResponsiveContainer width="100%" height="100%">
                                    <BarChart
                                        width={500}
                                        height={300}
                                        data={this.state.bar_data}
                                        margin={{
                                            top: 20,
                                            right: 20,
                                            left: 20,
                                            bottom: 30,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3"/>
                                        <XAxis dataKey="name" angle={-45} textAnchor="end"/>
                                        <YAxis yAxisId="left" orientation="left" stroke="#31b1e0" tickFormatter={(tickValue) => "$"+(tickValue).toLocaleString()}
                                               padding={{left: 0, right: 0}}/>
                                        <YAxis yAxisId="right" orientation="right" stroke="#6e57d2" tickFormatter={(tickValue) => "$"+(tickValue).toFixed(2) + '/Mile'}/>
                                        <Tooltip formatter={(value) => value && "$"+value.toLocaleString()} />
                                        <Legend layout="horizontal" verticalAlign="top" align="center"/>
                                        <Bar yAxisId="left" dataKey="Spent" fill="#31b1e0"/>
                                        <Bar yAxisId="right" dataKey="CostPerMile" fill="#6e57d2"/>
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                            <div className="table-responsive">
                                <table
                                    className={(this.state.maint_recs.list.length > 0 && !this.state.maint_recs.loading ? "table table-sm table-hover table-pointer" : "d-none")}>
                                    <thead>
                                    <tr>
                                        <th className="d-none d-lg-table-cell">#</th>
                                        <th>Equip.</th>
                                        <th>Date Ent.</th>
                                        <th>Category</th>
                                        <th>Description</th>
                                        <th>Urgency</th>
                                        <th>Vendor</th>
                                        <th>Est. Cost</th>
                                        <th>Act. Cost</th>
                                        <th>Date Comp.</th>
                                        <th>Status</th>
                                        <th className="d-none d-lg-table-cell">Reminder</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.maint_recs.list.map(function (item, key) {
                                        if (this.state.status == null || item.status == this.state.status) {
                                            if (item.cost_est > 0) {
                                                total_est_cost = money.add(total_est_cost, money.floatToAmount(item.cost_est));
                                            }
                                            if (item.cost_actual > 0) {
                                                total_actual_cost = money.add(total_actual_cost, money.floatToAmount(item.cost_actual));
                                            }
                                            return (
                                                <tr key={key} onClick={this.viewMaintenance.bind(this, item.maint_id)}>
                                                    <td className="d-none d-lg-table-cell">{item.maint_id}</td>
                                                    <td>{(typeof this.state.vehicles[item.equipment_id] == "undefined" ? "(select)" : this.state.vehicles[item.equipment_id].name)}</td>
                                                    <td>{moment(item.date_enter).format("MMM. D/YY HH:mm")}</td>
                                                    <td>{item.category}</td>
                                                    <td>{item.description}</td>
                                                    <td>{(item.urgency == 0 ? "Low" : (item.urgency == 1 ? "Medium" : "High (OOS)"))}</td>
                                                    <td>{item.vendor_name}</td>
                                                    <td>{(item.cost_est > 0 ? "$" + money.format("USD", money.floatToAmount(item.cost_est)) : "")}</td>
                                                    <td>{(item.cost_actual > 0 ? "$" + money.format("USD", money.floatToAmount(item.cost_actual)) : "")}</td>
                                                    <td>{(item.date_completed == null ? "" : moment(item.date_completed).format("MMM. D/YY HH:mm"))}</td>
                                                    <td className="text-left">
                                                        <div className={(item.status == 0 ? "badge badge-dark" : "d-none")}>Pending Auth.</div>
                                                        <div className={(item.status == 1 ? "badge badge-danger" : "d-none")}>Declined</div>
                                                        <div className={(item.status == 2 ? "badge badge-info" : "d-none")}>Approved</div>
                                                        <div className={(item.status == 3 ? "badge badge-purple" : "d-none")}>Completed, Not Billed</div>
                                                        <div className={(item.status == 4 ? "badge badge-green" : "d-none")}>Completed</div>
                                                    </td>
                                                    <td className="d-none d-lg-table-cell">{(item.reminder_date != null ? "Yes" : "No")}</td>
                                                </tr>
                                            )
                                        }
                                    }, this)}
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <th className="text-right" colSpan="7">Total:</th>
                                        <th>${money.format("USD", total_est_cost)}</th>
                                        <th>${money.format("USD", total_actual_cost)}</th>
                                        <th colSpan="3"></th>
                                    </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default (MaintenanceList);