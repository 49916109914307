const Menu = [
    {
        name: 'Dashboard',
        path: 'dashboard',
        icon: 'fa fa-home',
        roles: [1, 2],
    },
    {
        name: 'Fleet Map',
        path: 'map',
        icon: 'fas fa-map-marker-alt',
        roles: [1],
        code: 'FM'
    }, {
        name: 'Dispatch',
        path: 'dispatch',
        icon: 'fas fa-broadcast-tower',
        roles: [1],
        code: 'DI'
    }, {
        name: 'Loads',
        icon: 'fas fa-truck-loading',
        roles: [1, 2],
        code: 'LO',
        submenu: [
            {
                name: 'List',
                path: 'loads',
                icon: 'fas fa-list-ol',
            },
            {
                name: 'Timeline',
                path: 'timeline',
                icon: 'fas fa-stream',
            },
            {
                name: 'Create',
                path: 'loads/create',
                icon: 'far fa-plus',
            },
            {
                name: 'Templates',
                path: 'loads/templates',
                icon: 'far fa-clipboard',
            },
            {
                name: 'Quote',
                path: 'loads/quote',
                icon: 'fas fa-calculator',
            }
        ]
    }, {
        name: 'Invoices',
        path: 'invoices',
        icon: 'fas fa-file-invoice',
        roles: [1],
        code: 'IN'
    }, {
        name: 'Vehicles',
        path: 'vehicles',
        icon: 'fas fa-truck',
        roles: [1],
        code: 'VE'
    },
    {
        name: 'Equipment',
        path: 'equipment',
        icon: 'fas fa-trailer',
        roles: [1],
        code: 'EQ'
    }, {
        name: 'Maintenance',
        icon: 'fa-solid fa-toolbox',
        minRole: 1,
        code: 'MA',
        submenu: [
            {
                name: 'Messages',
                path: 'maint_mssg',
                icon: 'fas fa-comments',
            },
            {
                name: 'List',
                path: 'maintenance',
                icon: 'fa-solid fa-toolbox',
            }
        ]
    }, {
        name: 'Customers',
        path: 'customers',
        icon: 'fas fa-store',
        roles: [1],
        code: 'CU'
    }, {
        name: 'Drivers',
        path: 'drivers',
        icon: 'far fa-id-card',
        roles: [1],
        code: 'DR'
    }, {
        name: 'Locations',
        path: 'locations',
        icon: 'fas fa-street-view',
        roles: [1],
        code: 'LC'
    }, {
        name: 'Reports',
        icon: 'fas fa-chart-line',
        minRole: 1,
        code: 'RE',
        submenu: [
            {
                name: 'Idle Time',
                path: 'reports/idle',
                icon: 'fas fa-smog',
            },
            {
                name: 'Fill-Ups',
                path: 'fillups',
                icon: 'fa-solid fa-gas-pump',
                code: 'FU'
            }
        ]
    },
    {
        name: 'Admin',
        icon: 'fa-solid fa-gear',
        roles: [1, 2],
        code: 'AD',
        submenu: [
            {
                name: 'Staff',
                path: 'staff',
                icon: 'fas fa-id-card-alt',
                code: 'ST'
            },{
                name: 'Trackers',
                path: 'trackers',
                icon: 'fas fa-satellite-dish',
                code: 'TR'
            },{
                name: 'FSC',
                path: 'fsc',
                icon: 'fa-solid fa-percent',
                code: 'FS'
            }, {
                name: 'Shares',
                path: 'shares',
                icon: 'fas fa-share-square',
                code: 'SH'
            }
        ]
    }
];

export default Menu;
