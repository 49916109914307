import React from "react";
import axios from 'axios';
import moment from 'moment';
import {API_ROOT} from '../../api-config';

export async function getEquipment(companies = 0, response) {
    axios.defaults.withCredentials = true;
    await axios.get(`${API_ROOT}/equipment/company/` + companies, {withCredentials: true})
        .then(async function (ajax_response) {
            var vehicles = {};
            vehicles.data = {};
            vehicles.data = ajax_response.data;
            response(vehicles.data);
        })
        .catch(async function (error) {
            response({});
        });
}

export function getEquipmentDetails(is_vehicle, customer_id, startDate, response) {
    axios.defaults.withCredentials = true;
    axios.get(API_ROOT + "/equipment/details/?is_vehicle=" + is_vehicle + "&customer_id=" + customer_id+ "&startDate=" + startDate)
        .then(function (ajax_response) {
            var vehicles = {};
            vehicles.data = {};
            vehicles.data = ajax_response.data;
            response(vehicles.data);
        })
        .catch(function (error) {
            response({});
        });
}

export function getEquipmentTypes(response) {
    var fetch_data = true;
    var current_timestamp = Math.round((new Date()).getTime() / 1000);
    if (localStorage.getItem('equipment_types') !== null) {
        var vehicles = JSON.parse(localStorage.getItem('equipment_types'));
        if (vehicles.date_fetched > (current_timestamp - (60 * 60))) {
            fetch_data = false;
            response(vehicles.data);
        }
    }
    if (fetch_data) {
        axios.defaults.withCredentials = true;
        axios.get(`${API_ROOT}/equipment/types`, {withCredentials: true})
            .then(function (ajax_response) {
                var vehicles = {};
                vehicles.data = {};
                vehicles.data = ajax_response.data;
                vehicles.date_fetched = current_timestamp;
                localStorage.setItem('equipment_types', JSON.stringify(vehicles));
                response(vehicles.data);
            })
            .catch(function (error) {
                response({});
            });
    }
}

export function getEquipmentManufacturers(response) {
    var fetch_data = true;
    var current_timestamp = Math.round((new Date()).getTime() / 1000);
    if (localStorage.getItem('equipment_manufacturers') !== null) {
        var vehicles = JSON.parse(localStorage.getItem('equipment_manufacturers'));
        if (vehicles.date_fetched > (current_timestamp - (60 * 60))) {
            fetch_data = false;
            response(vehicles.data);
        }
    }
    if (fetch_data) {
        axios.defaults.withCredentials = true;
        axios.get(`${API_ROOT}/equipment/manufacturers`, {withCredentials: true})
            .then(function (ajax_response) {
                var vehicles = {};
                vehicles.data = {};
                vehicles.data = ajax_response.data;
                vehicles.date_fetched = current_timestamp;
                localStorage.setItem('equipment_manufacturers', JSON.stringify(vehicles));
                response(vehicles.data);
            })
            .catch(function (error) {
                response({});
            });
    }
}

export function getEquipmentTypesSub(response) {
    var fetch_data = true;
    var current_timestamp = Math.round((new Date()).getTime() / 1000);
    if (localStorage.getItem('equipment_types_sub') !== null) {
        var vehicles = JSON.parse(localStorage.getItem('equipment_types_sub'));
        if (vehicles.date_fetched > (current_timestamp - (60 * 60))) {
            fetch_data = false;
            response(vehicles.data);
        }
    }
    if (fetch_data) {
        axios.defaults.withCredentials = true;
        axios.get(`${API_ROOT}/equipment/types/sub`, {withCredentials: true})
            .then(function (ajax_response) {
                var vehicles = {};
                vehicles.data = {};
                vehicles.data = ajax_response.data;
                vehicles.date_fetched = current_timestamp;
                localStorage.setItem('equipment_types_sub', JSON.stringify(vehicles));
                response(vehicles.data);
            })
            .catch(function (error) {
                response({});
            });
    }
}

class EquipmentStatus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status_print: "",
            status_css: ""
        };
    }

    componentDidMount() {
        this.update_badge()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.status !== this.props.status) {
            this.update_badge()
        }
    }

    update_badge() {
        if (this.props.status == 1) {
            var status_print = (this.props.size == "sm" ? "OK" : "In Service");
            var status_css = "dark";
        } else if (this.props.status == 2) {
            var status_print = (this.props.size == "sm" ? "OOS" : "Out of Service");
            var status_css = "warning";
        } else if (this.props.status == 3) {
            var status_print = "Rented";
            var status_css = "light";
        } else {
            var status_print = "Retired";
            var status_css = "purple";
        }
        this.setState({
            status_print: status_print,
            status_css: "badge badge-" + status_css
        });
    }

    render() {
        return (
            <div className={this.state.status_css} style={(this.props.size == "sm" ? {
                padding: "1px 5px",
                fontSize: "9px",
                marginLeft: "3px",
                textTransform: "uppercase",
                opacity: "70%"
            } : {})}>{this.state.status_print}</div>
        );
    }
}

export class FuelLevel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status_css: ""
        };
    }

    componentDidMount() {
        this.update_badge()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.fuel !== this.props.fuel) {
            this.update_badge()
        }
    }

    update_badge() {
        if (this.props.fuel == null) {
            var status_css = "d-none";
        } else if (this.props.fuel >= 85) {
            var status_css = "fa-battery-full text-success";
        } else if (this.props.fuel >= 65 && this.props.fuel < 85) {
            var status_css = "fa-battery-three-quarters text-success";
        } else if (this.props.fuel >= 40 && this.props.fuel < 65) {
            var status_css = "fa-battery-half text-success";
        } else if (this.props.fuel >= 15 && this.props.fuel < 40) {
            var status_css = "fa-battery-quarter text-warning";
        } else {
            var status_css = "fa-battery-empty text-danger";
        }
        this.setState({
            status_css: "fas fa-lg " + status_css
        });
    }

    render() {
        return (
            <div className={this.state.status_css} title={"Fuel Level: " + this.props.fuel + "%"}></div>
        );
    }
}

export function addEquipmentPhoto(response) {

}

export default EquipmentStatus;