import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {Button, InputGroup, DropdownItem, DropdownToggle, DropdownMenu, UncontrolledButtonDropdown} from 'reactstrap';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'moment-timezone';
import AddEquipment from './AddEquipment';
import EquipmentStatus, {getEquipmentDetails, getEquipmentTypes, getEquipmentTypesSub, getEquipmentManufacturers} from "./EquipmentFunctions";
import getCustomers from "../Customers/CustomerFunctions";


moment.tz.setDefault("America/Halifax");

var money = require("money-math");

class EquipmentList extends React.Component {
    constructor(props) {
        super(props);

        this.updateDates = this.updateDates.bind(this);
        this.goToEquipment = this.goToEquipment.bind(this);

        this.state = {
            equipment: {},
            equipment_sort: [],
            loading: true,
            customer_id: 0,
            customers: {},
            customers_sort: [],
            staff: [],

            startDate: (localStorage.getItem('acc_startDate') != null ? moment(localStorage.getItem('acc_startDate')) : moment("01 Jan 2023")),
            endDate: (localStorage.getItem('acc_endDate') != null ? moment(localStorage.getItem('acc_endDate')) : moment().endOf('day')),
            equipment_type: (localStorage.getItem('equipment_type') != null ? localStorage.getItem('equipment_type') : null),
            equipment_status_name: (localStorage.getItem('equipment_status_name') != null ? localStorage.getItem('equipment_status_name') : "All"),
            equipment_type_name: (localStorage.getItem('equipment_type_name') != null ? localStorage.getItem('equipment_type_name') : "All"),
            equipment_types: {},
            vehicle_manufacturers: {},
            equipment_types_sub: {},
            equipment_statuses: {1: "In Service", 2: "Out of Service", 0: "Retired", 3: "Rented"},
            ranges: {
                'Today': [moment(), moment()],
                'Last 7 Days': [moment().subtract(6, 'days'), moment().endOf('day')],
                'Last 30 Days': [moment().subtract(29, 'days'), moment().endOf('day')],
                'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                'All Time': [moment("01 Jan 2023"), moment().endOf('day')]
            },
            loadanyway: false,
            sort: (localStorage.getItem('eq_sort') != null ? localStorage.getItem('eq_sort') : "name"),
            sort_order: (localStorage.getItem('eq_sort_order') != null ? localStorage.getItem('eq_sort_order') * 1 : -1),
        };
    }

    componentDidMount() {
        document.title = "Equipment | Custom TMS";
        var self = this;
        getEquipmentTypes(function (equipment_types) {
            getEquipmentTypesSub(function (equipment_types_sub) {
                getEquipmentManufacturers(function (vehicle_manufacturers) {
                    getCustomers(function (customers) {
                        var customers_sort = [];
                        for (const customer_id of Object.keys(customers)) {
                            customers_sort.push(customers[customer_id]);
                        }
                        var customers_sort = customers_sort.sort(function (a, b) {
                            if (a.name < b.name) {
                                return -1
                            } else {
                                return 1
                            }
                        });
                        self.setState({
                            vehicle_manufacturers: vehicle_manufacturers,
                            equipment_types: equipment_types,
                            equipment_types_sub: equipment_types_sub,
                            customers: customers,
                            customers_sort: customers_sort,
                        },);
                        self.update_equipment();
                    });
                });
            });
        });
    }

    update_equipment = () => {
        var self = this;
        getEquipmentDetails(0, self.state.customer_id, null,function (equipment) {
            self.setState({
                equipment: equipment,
                loading: false
            },);
            self.onSort(null, self.state.sort)
        });
    }

    // on date range change
    updateDates(event, picker) {
        if (event.type == "apply") {
            localStorage.setItem('acc_startDate', picker.startDate);
            localStorage.setItem('acc_endDate', picker.endDate);
            this.setState({
                startDate: picker.startDate,
                endDate: picker.endDate
            });
        }
    }

    changeVehicleType = (equipment_type) => {
        if (equipment_type == null) {
            var equipment_type_name = "All"
        } else {
            var equipment_type_name = this.state.equipment_types[equipment_type]
        }
        localStorage.setItem('equipment_type', equipment_type);
        localStorage.setItem('equipment_type_name', equipment_type_name);
        this.setState({
            equipment_type: equipment_type,
            equipment_type_name: equipment_type_name
        });
    };

    changeEquipmentStatus = (equipment_status) => {
        if (equipment_status == null) {
            var equipment_status_name = "All"
        } else {
            var equipment_status_name = this.state.equipment_statuses[equipment_status]
        }
        localStorage.setItem('equipment_status', equipment_status);
        localStorage.setItem('equipment_status_name', equipment_status_name);
        this.setState({
            equipment_status: equipment_status,
            equipment_status_name: equipment_status_name
        });
    };

    goToEquipment = (quipment_id) => {
        this.props.history.push("/equipment/" + quipment_id);
    };

    changeCustomer = (customer_id) => {
        this.setState({
            customer_id: customer_id,
        }, function () {
            this.update_equipment()
        });
    };

    onSort(event, sortKey) {
        localStorage.setItem('eq_sort', sortKey);
        localStorage.setItem('eq_sort_order', this.state.sort_order);
        var sort_order = this.state.sort_order * -1;
        if (sortKey != this.state.sort) {
            sort_order = 1;
        }

        function format_search_val(val) {
            if (typeof val == "undefined" || val == "") {
                return "";
            } else if (isNaN(val)) {
                return val.toLowerCase();
            } else {
                return parseFloat(val);
            }
        }

        var equipment_sort = [];
        for (const equipment_id of Object.keys(this.state.equipment)) {
            if (this.state.equipment[equipment_id].is_vehicle == 0) {
                equipment_sort.push(this.state.equipment[equipment_id])
            }
        }
        var equipment = equipment_sort.sort((a, b) => {
            if (typeof format_search_val(a[sortKey]) == "string" && typeof format_search_val(b[sortKey]) != "string") {
                return (sort_order == -1 ? -1 : 1);
            } else {
                if (format_search_val(a[sortKey]) > format_search_val(b[sortKey])) {
                    return (sort_order == -1 ? -1 : 1);
                }
                if (format_search_val(a[sortKey]) < format_search_val(b[sortKey])) {
                    return (sort_order == -1 ? 1 : -1);
                }
            }
            return 0;
        });
        this.setState({
            sort: sortKey,
            sort_order: sort_order,
            equipment_sort: equipment
        });
    }

    render() {

        // date range selector variables
        let start = this.state.startDate.format('MMM. D/YY');
        let end = this.state.endDate.format('MMM. D/YY');
        let label = start + ' - ' + end;
        if (start === end) {
            label = start;
        }

        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>Equipment</div>
                    <div className="ml-auto">
                        <AddEquipment hidden={false} equipment_types={this.state.equipment_types} equipment_types_sub={this.state.equipment_types_sub}
                                      goToEquipment={this.goToEquipment}/>
                    </div>
                </div>
                <div className="mb-3 form-inline" style={{zIndex: 1000}}>
                    <DateRangePicker
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        ranges={this.state.ranges}
                        maxDate={moment()}
                        onEvent={this.updateDates}
                        autoUpdateInput={true}
                    >
                        <button type="button" className="btn btn-primary selected-date-range-btn mr-1">
                            Summary Period:* <b>{label}</b> <span className="fas fa-caret-down fa-sm"></span>
                        </button>
                    </DateRangePicker>
                    <InputGroup className="mr-1">
                        <UncontrolledButtonDropdown>
                            <DropdownToggle color={(String(this.state.equipment_type) == "null" ? "primary" : "warning")} caret style={{fontSize: '13px', width: 'auto'}}>
                                Type: <b>{this.state.equipment_type_name}</b>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem key="null" onClick={() => this.changeVehicleType(null)}>All</DropdownItem>
                                <DropdownItem divider/>
                                {Object.keys(this.state.equipment_types).map((key, i) => {
                                        if (typeof this.state.equipment_types != "undefined" && typeof this.state.equipment_types[key] != "undefined" && this.state.equipment_types[key].is_vehicle == 0) {
                                            return (<DropdownItem key={key} onClick={() => this.changeVehicleType(key)}>{this.state.equipment_types[key].name}</DropdownItem>)
                                        }
                                    }
                                )}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </InputGroup>
                    <InputGroup className="mr-1">
                        <UncontrolledButtonDropdown>
                            <DropdownToggle caret color={(String(this.state.equipment_status) == "1" ? "primary" : "warning")} style={{fontSize: '13px', width: 'auto'}}>
                                Status: <b>{this.state.equipment_status_name}</b>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem key="null" onClick={() => this.changeEquipmentStatus(null)}>All</DropdownItem>
                                <DropdownItem divider/>
                                {Object.keys(this.state.equipment_statuses).map((key, i) => {
                                        if (typeof this.state.equipment_statuses[key] != "undefined") {
                                            return (<DropdownItem key={key} onClick={() => this.changeEquipmentStatus(key)}>{this.state.equipment_statuses[key]}</DropdownItem>)
                                        }
                                    }
                                )}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </InputGroup>
                    <InputGroup className="mr-1">
                        <UncontrolledButtonDropdown>
                            <DropdownToggle caret color={(String(this.state.customer_id) == "0" ? "primary" : "warning")} style={{fontSize: '13px', width: 'auto'}}>
                                {(this.state.customer_id == 0 ? "Greenhaven" : this.state.customers[this.state.customer_id].name)}
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem key="0" onClick={() => this.changeCustomer(0)}>Greenhaven</DropdownItem>
                                <DropdownItem divider/>
                                {Object.keys(this.state.customers_sort).map((key, i) => {
                                    return (<DropdownItem key={key}
                                                          onClick={() => this.changeCustomer(this.state.customers_sort[key].customer_id)}>{this.state.customers_sort[key].name}</DropdownItem>)
                                })}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </InputGroup>
                </div>
                <div className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <div>
                        <div className="card-header">
                            <div className="card-title">{this.state.equipment_sort.length} Pieces of Equipment</div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className={(this.state.equipment_sort.length > 0 ? "table table-hover table-pointer text-right" : "d-none")}>
                                    <thead>
                                    <tr>
                                        <th className={(this.state.customer_id == 0 ? "d-none" : "text-left")} onClick={e => this.onSort(e, 'customer_id')}><i
                                            className="fas fa-sort"></i> Owner
                                        </th>
                                        <th className="text-left" onClick={e => this.onSort(e, 'name')}><i className="fas fa-sort"></i> Name</th>
                                        <th className="text-left" onClick={e => this.onSort(e, 'name')}><i className="fas fa-sort"></i> Type</th>
                                        <th className="text-left" onClick={e => this.onSort(e, 'name')}><i className="fas fa-sort"></i> Sub-Type</th>
                                        <th className="text-left" onClick={e => this.onSort(e, 'make_model')}><i className="fas fa-sort"></i> Make / Model</th>
                                        <th className="text-left" onClick={e => this.onSort(e, 'status')}><i className="fas fa-sort"></i> Operational</th>
                                        <th className="text-left"> Located At</th>
                                        <th className="text-left" onClick={e => this.onSort(e, 'last_moved')}><i className="fas fa-sort"></i> Last Moved</th>
                                        <th className="text-right" onClick={e => this.onSort(e, 'distance_travelled')}><i className="fas fa-sort"></i> Distance Moved</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {Object.entries(this.state.equipment_sort).map(([key, equipment]) => {
                                        if (equipment.is_vehicle == 0 && equipment.customer_id == this.state.customer_id) {
                                            return (
                                                <tr key={key} onClick={this.goToEquipment.bind(this, equipment.equipment_id)}>
                                                    <td className={(this.state.customer_id == 0 ? "d-none" : "text-left")}>{(equipment.customer_id == 0 ? "Greenhaven" : this.state.customers[equipment.customer_id].name)}</td>
                                                    <td className="text-left">{equipment.name}</td>
                                                    <td className="text-left">{(equipment.equipment_type_id > 0 ? this.state.equipment_types[equipment.equipment_type_id].name : "")}</td>
                                                    <td className="text-left">{(equipment.equipment_type_sub_id > 0 ? this.state.equipment_types_sub[equipment.equipment_type_sub_id].name : "")}</td>
                                                    <td className="text-left">{(typeof this.state.vehicle_manufacturers[equipment.equipment_manufacturer_id] != "undefined" ? this.state.vehicle_manufacturers[equipment.equipment_manufacturer_id].name : "")} / {equipment.model}</td>
                                                    <td className="text-left"><EquipmentStatus status={equipment.status}/></td>
                                                    <td className="text-left">{equipment.location.name}</td>
                                                    <td className="text-left"
                                                        title={equipment.last_moved}>{(equipment.last_moved == null ? "NA" : moment(equipment.last_moved).fromNow())}</td>
                                                    <td className="text-right">{Math.round(equipment.distance_travelled).toLocaleString("en-US")} km</td>
                                                </tr>
                                            )
                                        }
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default (EquipmentList);