import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import 'moment-timezone';

moment.tz.setDefault("America/Halifax");

class Shares extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shares: [],
            loading: true
        };
    }

    componentDidMount() {
        document.title = "Shared Tracking Links | Custom TMS";
        this.update();
    }

    update() {
        var self = this;
        axios.get(API_ROOT + '/shares')
            .then(function (response) {
                self.setState({
                    shares: response.data,
                    loading: false
                })
            })
    }

    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>Shared Tracking Links</div>
                </div>
                <div className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <div>
                        <div className="card-body">
                            <table className={(this.state.shares.length > 0 ? "table table-sm text-right" : "d-none")}>
                                <thead>
                                <tr>
                                    <th className="text-left">Created</th>
                                    <th className="text-left">Vehicle</th>
                                    <th className="text-left">Start</th>
                                    <th className="text-left">End</th>
                                    <th className="text-left">Notes</th>
                                    <th className="text-left">URL</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.shares.map(function (share, key) {
                                    return (
                                        <tr key={key}>
                                            <td className="text-left">{moment(share.date_created).format("MMM Do, YYYY")}</td>
                                            <td className="text-left">{share.vehicle_name}</td>
                                            <td className="text-left">{moment(share.start_date).format("YYYY-MM-DD HH:mm:ss")}</td>
                                            <td className="text-left">{moment(share.end_date).format("YYYY-MM-DD HH:mm:ss")}</td>
                                            <td className="text-left">{share.note}</td>
                                            <td className="text-left"><a href={"https://"+window.location.hostname+"/share/"+share.share_id+"/"+share.hash} target="_blank">Link</a></td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default (Shares);