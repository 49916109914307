import * as React from 'react';
import {useState, useMemo, useEffect, useRef, useCallback} from 'react';
import Map, {
    Marker,
    Popup,
    NavigationControl,
    FullscreenControl,
    ScaleControl,
    Source,
    Layer,
    GeolocateControl,
} from 'react-map-gl/maplibre';
import 'maplibre-gl/dist/maplibre-gl.css';

import Pin from './pin';
import Landmark from './landmark';

import EquipmentStatus, {FuelLevel, getEquipment, getEquipmentTypes, getEquipmentTypesSub} from "../Equipment/EquipmentFunctions";
import {getDrivers} from "../Drivers/DriverFunctions";

import {Button, Dropdown, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import moment from 'moment';
import Moment from 'react-moment'
import 'moment-timezone';
import ReactSlider from 'react-slider';
import AsyncSelect from 'react-select/async';
import ShareLocation from "../Equipment/ShareLocation";


const screenfull = require('screenfull');

moment.tz.setDefault("America/Halifax");

const weatherLayer = {
    id: 'weatherLayer',
    type: 'raster',
    paint: {
        'raster-opacity': 0.15
    },
    'background-opacity': 1,
    minzoom: 0,
    maxzoom: 22,
};


export function App(props) {
    const mapRef = useRef(null);
    const [popupInfo, setPopupInfo] = useState(null);

    const zoomTo = useCallback(({longitude, latitude, zoom}) => {
        mapRef.current?.flyTo({center: [longitude, latitude], zoom: zoom, duration: 2000});
    }, []);

    const setBounds = useCallback((bounds) => {
        mapRef.current?.fitBounds([
            [bounds.x1, bounds.y1],
            [bounds.x2, bounds.y2]
        ], {padding: 50});
    }, []);

    useEffect(() => { // OPen popup
        if (props.open_popup == false) {
            setBounds(props.bounds);
            setPopupInfo(null);
        } else {
            props.pings_display.map((ping, index) => {
                if (ping.equipment_id == props.open_popup) {
                    setPopupInfo(ping);
                    mapRef.current?.flyTo({center: [ping.longitude, ping.latitude]});
                }
            })
        }
        props.hideTrace();
    }, [props.open_popup])

    useEffect(() => {
        zoomTo(props.zoom_to);
    }, [props.zoom_to])

    useEffect(() => {
        setPopupInfo(null) // close popup on state change
        props.hideTrace();
    }, [props.pings_display])

    useEffect(() => {
        setTimeout(function () {
            setBounds(props.bounds) // use a timeout as it was not reliable without. no idea why
        }, 100);
    }, [props.bounds])

    // This implements `StyleImageInterface` to draw a pulsing dot icon on the map.
    const size = 200;
    const pulsingDot = {
        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),

        onAdd: function () { // When the layer is added to the map, get the rendering context for the map canvas.
            const canvas = document.createElement('canvas');
            canvas.width = this.width;
            canvas.height = this.height;
            this.context = canvas.getContext('2d');
        },

        render: function () { // Call once before every frame where the icon will be used.
            const duration = 1250;
            const t = (performance.now() % duration) / duration;

            const radius = (size / 2) * 0.2;
            const outerRadius = (size / 2) * 0.7 * t + radius;
            const context = this.context;

            // Draw the outer circle.
            context.clearRect(0, 0, this.width, this.height);
            context.beginPath();
            context.arc(
                this.width / 2,
                this.height / 2,
                outerRadius,
                0,
                Math.PI * 2
            );
            context.fillStyle = `rgba(0, 134, 228, ${1 - t})`;
            context.fill();

            // Draw the inner circle.
            context.beginPath();
            context.arc(
                this.width / 2,
                this.height / 2,
                radius,
                0,
                Math.PI * 2
            );
            context.fillStyle = 'rgba(255, 100, 100, 1)';
            context.strokeStyle = 'white';
            context.lineWidth = 2 + 4 * (1 - t);
            context.fill();
            context.stroke();

            this.data = context.getImageData( // Update this image's data with data from the canvas.
                0,
                0,
                this.width,
                this.height
            ).data;
            mapRef.current?.triggerRepaint(); // Continuously repaint the map, resulting in the smooth animation of the dot.
            return true; // Return `true` to let the map know that the image was updated.
        }
    };


    var pins = useMemo(
        () =>
            props.pings_display.map((ping, index) => (
                <Marker
                    key={`marker-${index}`}
                    longitude={ping.longitude}
                    latitude={ping.latitude}
                    anchor={"center"}
                    onClick={e => {
                        // If we let the click event propagates to the map, it will immediately close the popup
                        // with `closeOnClick: true`
                        e.originalEvent.stopPropagation();
                        setPopupInfo(ping);
                    }}
                >
                    <Pin speed={ping.speed} dir={ping.dir} is_ignit={ping.is_ignit} timestamp={ping.timestamp}
                         timestamp_map={(props.timestamp == 0 ? moment().unix() : props.timestamp)} name={props.equipment[ping.equipment_id].name}
                         status={props.equipment[ping.equipment_id].status} show_names={props.show_names}
                         show_active={props.show_active}/>
                </Marker>
            )),
        [props.pings_display]
    );

    var landmarks = useMemo(
        () =>
            props.landmarks.map((landmark, index) => (
                <Marker
                    key={`marker-${index}`}
                    longitude={landmark.lon}
                    latitude={landmark.lat}
                    anchor={"center"}
                >
                    <Landmark name={(landmark.name != null ? landmark.name : landmark.address != null ? landmark.address : landmark.city)}/>
                </Marker>
            )),
        [props] // not using props.landmarks as it won't re-render for some reason on state change
    );


    let filterTimeout

    const loadOptions = (address, callback) => {
        clearTimeout(filterTimeout)
        filterTimeout = setTimeout(() => {
            if (address.length > 1) {
                axios.defaults.withCredentials = true;
                axios.get(`${API_ROOT}/search_map/${address}`, {withCredentials: true})
                    .then(function (ajax_response) {
                        callback(ajax_response.data)
                    });
            }
        }, 300);
    };

    const flagLocation = (selectedOption) => {
        console.log('addLandmark triggering')
        props.addLandmark(selectedOption)
    };

    return (
        <>
            <Map
                ref={mapRef}
                initialViewState={{
                    longitude: -64.842166,
                    latitude: 46.085063,
                    zoom: 8,
                    bearing: 0,
                    pitch: 0,
                    touchZoomRotate: true,
                    dragRotate: false,
                    showCompass: false,
                    visualizePitch: false,
                    attributionControl: false,
                }}
                onStyleLoad={props.onStyleLoad}
                mapStyle={props.mapstyle}
                onLoad={() => {
                    mapRef.current?.addImage('pulsing-dot', pulsingDot, {pixelRatio: 2});
                }}
            >
                <Button onClick={() => props.toggleValue('show_search', !props.show_search)} className="btn btn-primary btn-sm"
                        style={{position: "absolute", top: "10px", left: "10px", zIndex: 1}}><em className="fas fa-search fa-lg"></em></Button>
                <div className={(props.show_search ? "" : "d-none")} style={{marginRight: "55px", marginLeft: "55px", marginTop: "10px", maxWidth: "300px"}}>
                    <AsyncSelect cacheOptions loadOptions={loadOptions} defaultOptions onChange={flagLocation} placeholder="Enter an address or customer name"
                                 blurInputOnSelect={true} closeMenuOnSelect={true}
                                 value={{
                                     label: "Enter an address or customer name"
                                 }}
                    />
                </div>
                <GeolocateControl position="top-right"/>
                <FullscreenControl position="top-right"/>
                <NavigationControl position="top-right" showCompass={false} visualizePitch={false}/>
                <ScaleControl/>
                <UncontrolledButtonDropdown size="sm" className="shadow-sm" style={{position: "absolute", bottom: "10px", right: "10px", zIndex: 1}}>
                    <DropdownToggle className="p-1 rounded" style={{lineHeight: "1.2"}}>
                        <i className="fas fa-cogs"></i>
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem onClick={() => props.toggleValue('show_names', !props.show_names)}><i
                            className={(props.show_names === true ? "fas fa-check-circle text-success" : "d-none")}></i> <i className="fas fa-hashtag mr-1"></i> Show asset
                            names</DropdownItem>
                        <DropdownItem onClick={() => props.toggleValue('show_active', !props.show_active)}><i
                            className={(props.show_active === true ? "fas fa-check-circle text-success" : "d-none")}></i> <i className="fas fa-tachometer-alt mr-1"></i> Show only
                            active
                            assets</DropdownItem>
                    </DropdownMenu>
                </UncontrolledButtonDropdown>

                {pins}
                {landmarks}

                <Source id='weatherSource' type='raster' tiles={[props.weather_source]} key={[props.weather_source]} tileSize={512}>
                    <Layer {...weatherLayer} />
                </Source>

                <Source id="dot-point" type="geojson" data={props.highlight_point} key={JSON.stringify(props.highlight_point)}>
                    <Layer type="symbol" id="layer-with-pulsing-dot" source="dot-point" layout={{
                        'icon-image': 'pulsing-dot',
                        'icon-allow-overlap': true // important fot display
                    }}/>
                </Source>

                <Source id="polylineLayer" type="geojson" data={props.travel_line} key={JSON.stringify(props.travel_line)}>
                    <Layer
                        id="lineLayer"
                        type="line"
                        source="my-data"
                        layout={{
                            "line-join": "round",
                            "line-cap": "round"
                        }}
                        paint={{
                            "line-color": "rgba(0, 0, 0, 0.5)",
                            "line-width": 4
                        }}
                    />
                </Source>

                {popupInfo && (
                    <Popup
                        anchor="top"
                        longitude={Number(popupInfo.longitude)}
                        latitude={Number(popupInfo.latitude)}
                        offset={10}
                        onClose={() => setPopupInfo(null)}
                    >
                        <div style={{minWidth: "220px"}}>
                            <div className="h5 mb-0">
                                <a href={"./equipment/" + popupInfo.equipment_id}>{props.equipment[popupInfo.equipment_id].name}</a>
                                <div className="btn btn-xs btn-light float-right p-0" title="Zoom in on point"
                                     onClick={() => zoomTo({longitude: popupInfo.longitude, latitude: popupInfo.latitude, zoom: 16})}><i
                                    className="fas fa-search-plus fa-sm ml-1 mr-1"></i></div>
                                <div className={(popupInfo.timestamp < moment().unix() - (60 * 60 * 24) ? "d-none" : "btn btn-xs btn-light float-right mr-1 p-0")}
                                     title="Show past 24hr route" onClick={props.showTrace(popupInfo.equipment_id)}><i className="fas fa-route fa-sm ml-1 mr-1"></i></div>
                                <div className="btn btn-xs btn-light float-right p-0" title="Share location tracking" onClick={props.triggerShare(popupInfo.equipment_id)}>
                                    <i className="fa fa-share-square fa-sm ml-1 mr-1"></i>
                                </div>
                            </div>
                            <div
                                className={(popupInfo.driver_id == null ? "d-none" : "")}>Driver: <b>{(typeof props.drivers[popupInfo.driver_id] != "undefined" ? props.drivers[popupInfo.driver_id].first_name + " " + props.drivers[popupInfo.driver_id].last_name : "")}</b>
                            </div>
                            <div>
                                <span
                                    className={(popupInfo.is_ignit === null || (popupInfo.speed !== null && popupInfo.speed > 0) ? "d-none" : "")}>Ignition <b>{(popupInfo.is_ignit == 1 ? "ON" : "OFF")}</b></span>
                                <span className={(popupInfo.is_ignit === 1 && popupInfo.speed == 0 ? "" : "d-none")}>, </span>
                                <span
                                    className={(popupInfo.speed == null || popupInfo.is_ignit == 2 ? "d-none" : "")}><b>{(popupInfo.is_ignit == null ? "~" : "")}{(popupInfo.speed == null ? 0 : popupInfo.speed)}</b> km/hr<span
                                    className={(popupInfo.dir == null || popupInfo.dir == "" ? "d-none" : "")}>, heading <b>{(popupInfo.dir == null ? "" : popupInfo.dir.toUpperCase())}</b></span>
                                </span>
                                <span className={(popupInfo.fuel != null ? "pl-2" : "d-none")}>
                                    <FuelLevel fuel={popupInfo.fuel}/>
                                </span>
                            </div>
                            <div className={(popupInfo.desc === null ? "d-none" : "")}>
                                <i>{(popupInfo.desc != null ? popupInfo.desc.replace(', NS', '').replace(', NB', '').replace(', PE', '') : "")}</i></div>
                            <small className="text-muted">As
                                of <b>{(props.timestamp == 0 ? moment.duration(moment().unix() - popupInfo.timestamp, 'seconds').humanize() + " ago" : moment(popupInfo.timestamp * 1000).format("hh:mm A MMM. D"))}</b></small>
                        </div>
                        <div className="text-center">
                            <img style={{imageRendering: 'crisp-edges'}} src={"./img/photos/" + props.equipment[popupInfo.equipment_id].photo + "_sm.jpg"}
                                 className={(props.equipment[popupInfo.equipment_id].photo == null ? "d-none" : "")}/>
                        </div>
                    </Popup>
                )}
            </Map>
        </>
    );
}

class MapFleet extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show_sidebar: true,
            equipment: {},
            equipment_sort: [],
            equipment_types: [],
            equipment_sub_types: [],
            landmarks: [],
            show_search: false,
            pings: {},
            pings_display: [],
            staff: [],
            drivers: {},
            travel_line: {
                type: "Feature",
                properties: {
                    stroke: "#f00"
                },
                geometry: {
                    type: "LineString",
                    coordinates: []
                }
            },
            highlight_point: {
                type: 'FeatureCollection',
                features: [
                    {
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: [0, 0]
                        }
                    }
                ]
            },
            filter_name: "All Trucks & Trailers",
            filter_type: null,
            filter_sub_type: null,
            popupInfo: false,
            loading: true,
            timestamp: 0,
            timestamp_committed: true,
            timestamp_pending: 0,
            slider_value: 0,
            weather_source: false,
            bounds: {x1: -63.564351, y1: 46.811111, x2: -66.618194, y2:44.628703 },
            zoom_to: false,
            open_popup: false,
            update_bounds: true,
            show_names: (localStorage.getItem('show_names') == 'true' ? true : false),
            show_active: (localStorage.getItem('show_active') == 'true' ? true : false),
            map_fullscreen: (localStorage.getItem('map_fullscreen') != null ? localStorage.getItem('map_fullscreen') : false),
            last_update: false,
            share_equipment_id:false,
            share_name:"",
            mapheight: "calc(100vh - 140px)",
            mapstyle: "https://api.maptiler.com/maps/streets-v2/style.json?key=6P8HshICDGDXoVd9nuYs" // dynamic satelite styling: https://api.maptiler.com/maps/2953f8d9-b027-4e3f-8406-ed5081684e6d/style.json?key=onodmQmB6o4gGxbTx34B
        };
    }

    intervalID = 0;

    componentDidMount() {
        document.title = "Fleet Map | Custom TMS";
        var self = this;
        this.resetWeatherStamp();
        this.intervalID = setInterval(() => this.updatePings('setInterval'), 30000);
        //equipment_types
        getEquipment(0, function (equipment) {
            getEquipmentTypes(function (equipment_types) {
                getEquipmentTypesSub(function (equipment_sub_types) {
                    getDrivers(function (drivers) {
                        if (typeof equipment != "undefined") {
                            var equipment_sort = [];
                            for (const equipment_id of Object.keys(equipment)) {
                                if (equipment.name != "test" && equipment.status != 0) {
                                    equipment_sort.push(equipment[equipment_id])
                                }
                            }
                            equipment_sort = equipment_sort.sort((a, b) => {
                                if (a['name'] > b['name']) {
                                    return 1;
                                }
                                if (a['name'] < b['name']) {
                                    return -1;
                                }
                            });
                            self.setState({
                                equipment: equipment,
                                equipment_sort: equipment_sort,
                                equipment_types: equipment_types,
                                equipment_sub_types: equipment_sub_types,
                                drivers: drivers
                            }, () => {
                                self.updatePings('load')
                            });
                        }
                    });
                });
            });
        });
        window.addEventListener("focus", () => this.updatePings('focus'))

        if (screenfull.isEnabled) {
            screenfull.on('change', () => {
                if (screenfull.isFullscreen){
                    this.setState({
                        mapheight: "calc(100vh - 40px)",
                        map_fullscreen: true
                    });
                    [].forEach.call(document.querySelectorAll('.maplibregl-ctrl'), function (el) {
                        el.style.visibility = 'hidden';
                    });
                } else {
                    this.setState({
                        mapheight: "calc(100vh - 140px)",
                        map_fullscreen: false
                    });
                    [].forEach.call(document.querySelectorAll('.maplibregl-ctrl'), function (el) {
                        el.style.visibility = 'visible';
                    });
                }
            });
        }
    }
    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    resetWeatherStamp = event => {
        var weather_stamp = this.state.timestamp
        if (weather_stamp == 0) {
            const start = moment();
            const remainder = 10 - (start.minute() % 10);
            weather_stamp = moment(start).add(remainder, "minutes").startOf('minute').startOf('second').unix() - (60 * 10);
        }
        if (moment().unix() < (weather_stamp + 60)) { // within last minute
            weather_stamp = weather_stamp - (60 * 10);
        }
        this.setState({
            weather_source: "https://tilecache.rainviewer.com/v2/radar/" + weather_stamp + "/512/{z}/{x}/{y}/7/1_1.png"
        });
    }
    updatePings = (source) => {
        console.log('updatePings')
        if (screenfull.isEnabled && screenfull.isFullscreen) {
            this.setState({
                update_bounds: true,
            });
        }
        var timestamp_pending = moment().unix();
        if (timestamp_pending != this.state.timestamp_pending && (this.state.last_update == false || timestamp_pending > this.state.last_update.unix() + 10)) {
            this.setState({
                timestamp_pending: timestamp_pending
            });
            var self = this;
            axios.defaults.withCredentials = true;
            axios.get(API_ROOT + '/equipment/pings?id=' + timestamp_pending)
                .then(function (response) {
                    if (response.data != "" && Object.keys(response.data).length > 0) {
                        self.setState({
                                pings: response.data,
                                loading: false,
                                last_update: moment()
                            },
                            self.selectPings
                        );
                        if (typeof self.props.match.params.equipment_id != "undefined") {
                            setTimeout(function () {
                                self.zoomTo({
                                    equipment_id: self.props.match.params.equipment_id,
                                    longitude: response.data[self.props.match.params.equipment_id][0].longitude,
                                    latitude: response.data[self.props.match.params.equipment_id][0].latitude,
                                    zoom: 9
                                })
                                self.openPopup(self.props.match.params.equipment_id);
                                delete self.props.match.params.equipment_id;
                            }, 1000);
                        }
                    }
                })
                .catch(function (error) {
                    if (error.response.status === 404) {
                        // do nothing
                    } else if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error", error.response.data.Message, "error");
                    }
                });
            //this.selectPings();
            if (this.state.timestamp_committed) {
                this.resetWeatherStamp();
            }
        }
    }
    updatePingsRefresh = event => {
        this.hideTrace();
        this.setState({
            update_bounds: true,
            slider_value: 0,
            landmarks: [],
            timestamp: 0
        });
        this.updatePings('updatePingsRefresh');
    }

    toggleValue = (key) => {
        var self = this;
        if (key == "show_names" || key == "show_active") {
            localStorage.setItem(key, !this.state[key]);
            self.selectPings();
        }
        this.setState({
                [key]: !this.state[key]
            },
            this.updatePingsRefresh
        );
    }

    addLandmark = (landmark) => {
        if (landmark != null) {
            var landmarks = this.state.landmarks;
            landmarks.push(landmark);
            this.setState({
                    landmarks: landmarks,
                    zoom_to: {longitude: landmark.lon, latitude: landmark.lat, zoom: 10}
                }
            );
        }
    }

    showTrace = (equipment_id) => event => {
        var travel_line = this.state.travel_line;
        var bounds = {x1: false, y1: false, x2: false, y2: false}
        travel_line.geometry.coordinates = [];
        for (var id of Object.keys(this.state.pings[equipment_id])) {
            travel_line.geometry.coordinates.push([this.state.pings[equipment_id][id].longitude, this.state.pings[equipment_id][id].latitude]);
            if (this.state.pings[equipment_id][id].latitude < bounds.y1 || bounds.y1 == false) { // sw
                bounds.y1 = this.state.pings[equipment_id][id].latitude;
            }
            if (this.state.pings[equipment_id][id].longitude < bounds.x1 || bounds.x1 == false) { //sw
                bounds.x1 = this.state.pings[equipment_id][id].longitude;
            }
            if (this.state.pings[equipment_id][id].latitude > bounds.y2 || bounds.y2 == false) { //ne
                bounds.y2 = this.state.pings[equipment_id][id].latitude;
            }
            if (this.state.pings[equipment_id][id].longitude > bounds.x2 || bounds.x2 == false) { //ne
                bounds.x2 = this.state.pings[equipment_id][id].longitude;
            }
        }
        this.setState({
            travel_line: travel_line,
            bounds: bounds
        });
        event.stopPropagation();
    }
    hideTrace = event => {
        var travel_line = this.state.travel_line;
        travel_line.geometry.coordinates = [];
        this.setState({
            travel_line: travel_line,
        });
    }
    highlightPoint = (equipment_id) => event => {
        var highlight_point = this.state.highlight_point;
        highlight_point.features[0].geometry.coordinates = [0, 0];
        this.state.pings_display.map((ping, index) => {
            if (ping.equipment_id == equipment_id) {
                highlight_point.features[0].geometry.coordinates = [ping.longitude, ping.latitude];
            }
        })
        this.setState({
            highlight_point: highlight_point
        });
    }

    selectPings = event => {
        var pings_display = [];
        var bounds = {x1: false, y1: false, x2: false, y2: false}
        for (var equipment_id of Object.keys(this.state.equipment)) {
            if (typeof this.state.pings[equipment_id] != "undefined") {
                if (
                    (this.state.filter_type == null || this.state.filter_type == this.state.equipment[equipment_id].equipment_type_id) &&
                    (this.state.filter_sub_type == null || this.state.filter_sub_type == this.state.equipment[equipment_id].equipment_sub_type_id)
                ) {
                    for (var id of Object.keys(this.state.pings[equipment_id])) {
                        var obj = this.state.pings[equipment_id][id];
                        if (this.state.timestamp == 0 || obj.timestamp < this.state.timestamp) {
                            var is_active = true;
                            if ((obj.speed != null && obj.speed > 0 && obj.dir == null) || (obj.is_ignit != 2 && obj.timestamp < (this.state.timestamp == 0 ? moment().unix() : this.state.timestamp) - (60 * 60 * 1))) {
                                is_active = false;
                            } else if (obj.is_ignit === 2 || obj.speed == null) {
                                is_active = false;
                            }
                            if (this.state.show_active !== true || is_active) {
                                obj['equipment_id'] = equipment_id
                                pings_display.push(obj)
                                if (obj.latitude < bounds.y1 || bounds.y1 == false) { // sw
                                    bounds.y1 = obj.latitude;
                                }
                                if (obj.longitude < bounds.x1 || bounds.x1 == false) { //sw
                                    bounds.x1 = obj.longitude;
                                }
                                if (obj.latitude > bounds.y2 || bounds.y2 == false) { //ne
                                    bounds.y2 = obj.latitude;
                                }
                                if (obj.longitude > bounds.x2 || bounds.x2 == false) { //ne
                                    bounds.x2 = obj.longitude;
                                }
                                break;
                            }
                        }
                    }
                }
            }
        }
        if (pings_display.length > 0) {
            if (this.state.update_bounds) {
                if (bounds.y1 == bounds.y2 && bounds.x1 == bounds.x2) {
                    bounds.y1 = bounds.y1 - .01;
                    bounds.y2 = bounds.y2 + .01;
                    bounds.x1 = bounds.x1 - .01;
                    bounds.x2 = bounds.x2 + .01;
                }
                this.setState({
                    pings_display: pings_display,
                    bounds: bounds,
                    update_bounds: false
                });
            } else {
                this.setState({
                    pings_display: pings_display,
                });
            }
        }
    }

    goToVehicle = (vehicle_id) => {
        this.props.history.push("/equipment/" + vehicle_id);
    };

    onStyleLoad = (map, e) => {
        this.setState({map});
    }

    componentWillUpdate(nextProps, nextState) {
        const {map, weather_source} = nextState;
        if (map) {
            map.getSource('weatherSource').setData(weather_source);
        }
    }

    handleSliderChangeCommitted = (change) => {
        this.setState({
                timestamp_committed: true
            },
            this.updatePings('handleSliderChangeCommitted')
        );
    };

    openPopup = (params) => {
        if (params == this.state.open_popup) {
            this.setState({
                    open_popup: false
                },
            );
        } else {
            this.setState({
                    open_popup: params
                },
            );
        }
    };
    zoomTo = (params) => event => {
        this.setState({
                zoom_to: params
            },
        );
        event.stopPropagation();
    };

    handleSliderChange = (change) => {
        var raw_stamp = moment().add(change * 10, "minutes");
        var remainder = 10 - (raw_stamp.minute() % 10);
        var timestamp = moment().add((change * 10) + remainder, "minutes").startOf('minute').startOf('second').unix();
        this.setState({
                slider_value: change,
                timestamp: timestamp,
                timestamp_committed: false
            },
            this.updatePings('handleSliderChange')
        );
    };

    changeSearchFilter = (filter_type, filter_sub_type) => {
        var filter_name = "All Trucks & Trailers";
        if (filter_sub_type != null) {
            filter_name = this.state.equipment_sub_types[filter_sub_type].name;
        } else if (filter_type != null) {
            filter_name = "All " + this.state.equipment_types[filter_type].name;
        }
        this.setState({
                filter_type: filter_type,
                filter_sub_type: filter_sub_type,
                filter_name: filter_name,
                update_bounds: true
            },
            function () {
                this.selectPings()
            }
        );
    }

    toggleFullScreen = () => {
        if (screenfull.isEnabled) {
            screenfull.toggle(document.getElementById('map_fullscreen'));
        }
    }

    triggerShare = (equipment_id) => event => {
        var self = this;
        this.setState({
            share_name: self.state.equipment[equipment_id].name,
            share_equipment_id: equipment_id
        });
    }

    render() {

        var formatTime = (change) => {
            if (change == 0) {
                return "Now"
            } else {
                var raw_stamp = moment().add(change * 10, "minutes");
                var remainder = 10 - (raw_stamp.minute() % 10);
                var timestamp = moment().add((change * 10) + remainder, "minutes").startOf('minute').startOf('second').format("hh:mm A");
                return timestamp;
            }
        }

        return (
            <div className="content-wrapper g-0 pl-0 pb-0" style={{marginBottom: "-40px", borderTop: "1px solid #292C33"}} id="map_fullscreen">
                <div className="content-heading mb-0 ml-0 pl-1 pt-0 border-0 text-light" style={{backgroundColor: "#292C33"}}>
                    <div>
                        <div className="btn btn-xs btn-dark mr-1 d-inline d-lg-none d-xl-none d-xxl-none" title="Toggle sidebar list"
                             onClick={() => this.toggleValue('show_sidebar')}><i
                            className={(this.state.show_sidebar == true ? "fas fa-angle-double-left" : "fas fa-angle-double-right")}></i></div>
                        <span className="pl-2">Fleet Map</span>
                    </div>
                    <div className="ml-auto text-right">
                        <small className={(this.state.last_update != false && this.state.last_update > 0 ? "inline mt-2 small text-light pr-2" : "d-none")}>
                            Updated
                            &nbsp;<Moment interval={1000} date={(this.state.last_update == false ? moment() : this.state.last_update)} format="s" durationFromNow/>&nbsp;
                            sec. ago.
                        </small>
                        <Button onClick={this.updatePingsRefresh} size={"xs"}><i className="fas fa-sync-alt"></i></Button>
                        <Button onClick={this.toggleFullScreen} size={"xs"} className="ml-2"><i className="fas fa-expand-arrows-alt"></i></Button>
                    </div>
                </div>

                <div className="row ml-0">
                    <div id="map_sidebar" className={(this.state.show_sidebar == true ? "text-light p-0" : "d-none")}>
                        <div className="text-center" style={{borderBottom: "1px solid #111"}}>
                            <UncontrolledButtonDropdown style={{width: '100%'}}>
                                <DropdownToggle size="sm" color="dark" caret
                                                style={{fontSize: '13px', width: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                                    {this.state.filter_name}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem key="null" onClick={() => this.changeSearchFilter(null, null)}><b>All Trucks & Trailers</b></DropdownItem>
                                    {Object.keys(this.state.equipment_types).map(function (key, i) {
                                        if (typeof this.state.equipment_types != "undefined" && typeof this.state.equipment_types[key] != "undefined" && [1, 5, 6].indexOf(this.state.equipment_types[key].equipment_type_id) >= 0) {
                                            return (
                                                <span>
                                                    <DropdownItem divider/>
                                                    <DropdownItem key={key}
                                                                  onClick={() => this.changeSearchFilter(this.state.equipment_types[key].equipment_type_id, null)}><b>All {this.state.equipment_types[key].name}s</b></DropdownItem>
                                                    {Object.keys(this.state.equipment_sub_types).map(function (key2, i) {
                                                        if (this.state.equipment_sub_types[key2].equipment_type_id != "undefined" && this.state.equipment_sub_types[key2].equipment_type_id == this.state.equipment_types[key].equipment_type_id) {
                                                            return (<DropdownItem key={key2}
                                                                                  onClick={() => this.changeSearchFilter(this.state.equipment_types[key].equipment_type_id, this.state.equipment_sub_types[key2].equipment_type_sub_id)}> - {this.state.equipment_sub_types[key2].name}</DropdownItem>)
                                                        }
                                                    }, this)}
                                                </span>
                                            )
                                        }
                                    }, this)}
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>
                        <div className="vehicle_list" style={{maxHeight: 'calc(100vh - 115px)', overflowY: "auto"}}>
                            {this.state.equipment_sort.map((equipment, i) => {
                                var equipment_id = equipment.equipment_id;
                                if (
                                    typeof this.state.pings[equipment_id] != "undefined" &&
                                    (this.state.filter_type == null || this.state.filter_type == this.state.equipment[equipment_id].equipment_type_id) &&
                                    (this.state.filter_sub_type == null || this.state.filter_sub_type == this.state.equipment[equipment_id].equipment_type_sub_id)
                                ) {
                                    return (
                                        <div>
                                            {this.state.pings_display.map((ping, index) => {
                                                if (ping.equipment_id == equipment_id) {
                                                    var days_ago = Math.floor((moment().unix() - ping.timestamp) / 60 / 60 / 24);
                                                    var is_active = true;
                                                    if ((ping.speed != null && ping.speed > 0 && ping.dir == null) || (ping.is_ignit != 2 && ping.timestamp < (this.state.timestamp == 0 ? moment().unix() : this.state.timestamp) - (60 * 60 * 1))) {
                                                        is_active = false;
                                                    } else if (ping.is_ignit === 2 || ping.speed == null) {
                                                        is_active = false;
                                                    }
                                                    return (
                                                        <div style={{borderBottom: "1px solid #111"}}
                                                             className={(this.state.show_active !== true || is_active ? "pl-2 pr-2" : "d-none")}
                                                             onMouseEnter={this.highlightPoint(equipment_id)}
                                                             onMouseLeave={this.highlightPoint(false)} onClick={() => this.openPopup(equipment_id)}>
                                                            <div>
                                                                <div className="mb-1">
                                                                    <span className="text-bold"><i
                                                                        className={(this.state.equipment[equipment_id].is_vehicle ? "fas fa-truck text-dark" : "fas fa-trailer text-dark")}></i> {this.state.equipment[equipment_id].name}
                                                                        <EquipmentStatus status={this.state.equipment[equipment_id].status} size="sm"/>
                                                                    </span>
                                                                    <div className="btn btn-xs btn-dark float-right mt-1 p-0" title="Zoom in on point" onClick={this.zoomTo({
                                                                        equipment_id: equipment_id,
                                                                        longitude: ping.longitude,
                                                                        latitude: ping.latitude,
                                                                        zoom: 16
                                                                    })}><i className="fas fa-search-plus fa-sm ml-1 mr-1"></i></div>
                                                                    <div className={(days_ago >= 1 ? "d-none" : "btn btn-xs btn-dark float-right mt-1 mr-1 p-0")}
                                                                         title="Show past 24hr route" onClick={this.showTrace(equipment_id)}><i
                                                                        className="fas fa-route fa-sm ml-1 mr-1"></i></div>
                                                                </div>
                                                                <div className="small">
                                                                    <table className="w-100">
                                                                        <tr>
                                                                            <td colSpan="3" className={(ping.driver_id == null ? "d-none" : "")}><i
                                                                                className="fa fa-user"></i> {(typeof this.state.drivers[ping.driver_id] != "undefined" ? this.state.drivers[ping.driver_id].first_name + " " + this.state.drivers[ping.driver_id].last_name : "")}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{width: "33%"}}
                                                                                title={"Last update " + moment.duration(moment().unix() - ping.timestamp, 'seconds').humanize() + " ago"}>
                                                                                <i className="fas fa-stopwatch"></i> {(days_ago >= 1 ? days_ago + " day" + (days_ago > 1 ? "s" : "") : moment.utc(((moment().unix() - ping.timestamp) * 1000)).format("HH:mm"))}
                                                                            </td>
                                                                            <td style={{width: "27%"}} title={(ping.is_ignit == 1 ? "Ignition is on" : "Ignition is off")}>
                                                                                <div className={(ping.is_ignit == null ? "d-none" : "")}><i
                                                                                    className={(ping.is_ignit == 1 ? "fas fa-toggle-on text-success" : "fas fa-toggle-off text-danger")}></i> {(ping.is_ignit == 1 ? "On" : "Off")}
                                                                                </div>
                                                                            </td>
                                                                            <td style={{width: "40%"}}><span className={(ping.speed != null ? "" : "d-none")}><i
                                                                                className="fas fa-tachometer-alt"></i> {(ping.is_ignit == null ? "~" : "")}{(ping.speed == null ? 0 : ping.speed)} km/hr</span>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                    <div className={(ping.desc == null ? "d-none" : "font-italic")} style={{
                                                                        overflow: 'hidden',
                                                                        whiteSpace: 'nowrap',
                                                                        textOverflow: 'ellipsis'
                                                                    }}>{(ping.desc != null ? ping.desc.replace(', NS', '').replace(', NB', '').replace(', PE', '') : "")}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </div>
                    <div className="col pl-2 pt-2 pr-2">
                        <div className={(this.state.loading ? "whirl traditional mb-1" : "mb-1")} style={{"height": this.state.mapheight}}>
                            <div style={{height: this.state.mapheight, width: '100%'}}>
                                <App
                                    showTrace={this.showTrace}
                                    travel_line={this.state.travel_line}
                                    hideTrace={this.hideTrace}
                                    bounds={this.state.bounds}
                                    mapstyle={this.state.mapstyle}
                                    timestamp={this.state.timestamp}
                                    pings_display={this.state.pings_display}
                                    weather_source={this.state.weather_source}
                                    equipment={this.state.equipment}
                                    zoom_to={this.state.zoom_to}
                                    open_popup={this.state.open_popup}
                                    highlight_point={this.state.highlight_point}
                                    drivers={this.state.drivers}
                                    show_names={this.state.show_names}
                                    show_active={this.state.show_active}
                                    landmarks={this.state.landmarks}
                                    show_search={this.state.show_search}
                                    addLandmark={this.addLandmark}
                                    toggleValue={this.toggleValue}
                                    onStyleLoad={this.onStyleLoad}
                                    triggerShare={this.triggerShare}
                                />
                            </div>
                        </div>
                        <ReactSlider
                            className="customSlider"
                            marks={[-138, -132, -126, -120, -114, -108, -102, -96, -90, -84, -78, -72, -66, -60, -54, -48, -42, -36, -30, -24, -18, -12, -6]}
                            markClassName="customSlider-mark"
                            min={-144}
                            max={0}
                            value={this.state.slider_value}
                            onChange={(props) => this.handleSliderChange(props)}
                            onAfterChange={(props) => this.handleSliderChangeCommitted(props)}
                            thumbClassName="customSlider-thumb"
                            trackClassName="customSlider-track"
                            renderThumb={(props, state) => <div {...props}>{formatTime(state.valueNow)}</div>}
                        />
                    </div>
                </div>
                <ShareLocation name={this.state.share_name} equipment_id={this.state.share_equipment_id} hidden={true}/>
            </div>
        );
    }
}

export default (MapFleet);
