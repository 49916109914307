import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {Button, InputGroup, DropdownItem, DropdownToggle, DropdownMenu, UncontrolledButtonDropdown} from 'reactstrap';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'moment-timezone';
import AddVehicle from './AddVehicle';
import EquipmentStatus, {FuelLevel, getEquipmentTypes, getEquipmentTypesSub, getEquipmentManufacturers} from "../Equipment/EquipmentFunctions";

moment.tz.setDefault("America/Halifax");

class VehicleList extends React.Component {
    constructor(props) {
        super(props);

        this.updateDates = this.updateDates.bind(this);
        this.goToVehicle = this.goToVehicle.bind(this);

        this.state = {
            vehicles: {},
            vehicles_sort: [],
            loading: true,
            staff: [],

            startDate: (localStorage.getItem('acc_startDate') != null ? moment(localStorage.getItem('acc_startDate')) : moment("01 Jan 2023")),
            endDate: (localStorage.getItem('acc_endDate') != null ? moment(localStorage.getItem('acc_endDate')) : moment().endOf('day')),
            vehicle_type_id: (localStorage.getItem('vehicle_type_id') != null ? localStorage.getItem('vehicle_type_id') : null),
            vehicles_status_name: (localStorage.getItem('vehicles_status_name') != null ? localStorage.getItem('vehicles_status_name') : "All"),
            vehicles_type_name: (localStorage.getItem('vehicles_type_name') != null ? localStorage.getItem('vehicles_type_name') : "All"),
            vehicle_types: {},
            vehicles_status: (localStorage.getItem('vehicles_status') != null ? localStorage.getItem('vehicles_status') : 1),
            vehicle_manufacturers: {},
            vehicle_types_sub: {},
            vehicles_statuses: {1: "In Service", 2: "Out of Service", 0: "Retired"},
            ranges: {
                'Today': [moment(), moment()],
                'Last 7 Days': [moment().subtract(6, 'days'), moment().endOf('day')],
                'Last 30 Days': [moment().subtract(29, 'days'), moment().endOf('day')],
                'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                'All Time': [moment("01 Jan 2023"), moment().endOf('day')]
            },
            loadanyway: false,
            sort: (localStorage.getItem('sort_ve') != null ? localStorage.getItem('sort_ve') : "name"),
            sort_order: (localStorage.getItem('sort_order_ve') != null ? localStorage.getItem('sort_order_ve') * -1 : -1),
        };
    }

    componentDidMount() {
        document.title = "Vehicles | Custom TMS";
        var self = this;
        getEquipmentTypes(function (vehicle_types) {
            getEquipmentTypesSub(function (vehicle_types_sub) {
                getEquipmentManufacturers(function (vehicle_manufacturers) {
                    self.setState({
                        vehicle_manufacturers: vehicle_manufacturers,
                        vehicle_types: vehicle_types,
                        vehicle_types_sub: vehicle_types_sub,
                    });
                    self.updateVehicles();
                });
            });
        });
    }

    updateVehicles() {
        var self = this;
        self.setState({
            loading: true
        });
        axios.get(API_ROOT + '/vehicles/details/?vehicle_type_id=' + self.state.vehicle_type_id + "&startDate=" + self.state.startDate + "&endDate=" + self.state.endDate + "&status=" + self.state.vehicles_status)
            .then(function (response) {
                self.setState({
                    vehicles: response.data,
                    loading: false
                }, function () {
                    self.onSort(null, self.state.sort)
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    }

    // on date range change
    updateDates(event, picker) {
        if (event.type == "apply") {
            localStorage.setItem('acc_startDate', picker.startDate);
            localStorage.setItem('acc_endDate', picker.endDate);
            this.setState({
                startDate: picker.startDate,
                endDate: picker.endDate
            }, function () {
                this.updateVehicles()
            });
        }
    }

    changeVehicleType = (vehicle_type_id) => {
        if (vehicle_type_id == null) {
            var vehicles_type_name = "All"
        } else {
            var vehicles_type_name = this.state.vehicle_types[vehicle_type_id].name;
        }
        localStorage.setItem('vehicle_type_id', vehicle_type_id);
        localStorage.setItem('vehicles_type_name', vehicles_type_name);
        this.setState({
            vehicle_type_id: vehicle_type_id,
            vehicles_type_name: vehicles_type_name
        }, function () {
            this.updateVehicles()
        });
    };

    changeEquipmentStatus = (vehicles_status) => {
        if (vehicles_status == null) {
            var vehicles_status_name = "All"
        } else {
            var vehicles_status_name = this.state.vehicles_statuses[vehicles_status]
        }
        localStorage.setItem('vehicles_status', vehicles_status);
        localStorage.setItem('vehicles_status_name', vehicles_status_name);
        this.setState({
            vehicles_status: vehicles_status,
            vehicles_status_name: vehicles_status_name
        }, function () {
            this.updateVehicles()
        });
    };

    goToVehicle = (vehicle_id) => {
        this.props.history.push("/vehicles/" + vehicle_id);
    };

    onSort(event, sortKey) {
        localStorage.setItem('sort_ve', sortKey);
        localStorage.setItem('sort_order_ve', this.state.sort_order);
        var sort_order = this.state.sort_order * -1;
        if (sortKey != this.state.sort) {
            sort_order = 1;
        }

        function format_search_val(val) {
            if (isNaN(val)) {
                return val.toLowerCase();
            } else {
                return parseFloat(val);
            }
        }

        var vehicles_sort = [];
        for (const vehicle_id of Object.keys(this.state.vehicles)) {
            if (this.state.vehicles[vehicle_id].is_vehicle == 1) {
                vehicles_sort.push(this.state.vehicles[vehicle_id])
            }
        }
        var vehicles = vehicles_sort.sort((a, b) => {
            if (typeof format_search_val(a[sortKey]) == "string" && typeof format_search_val(b[sortKey]) != "string") {
                return (sort_order == -1 ? -1 : 1);
            } else {
                if (format_search_val(a[sortKey]) > format_search_val(b[sortKey])) {
                    return (sort_order == -1 ? -1 : 1);
                }
                if (format_search_val(a[sortKey]) < format_search_val(b[sortKey])) {
                    return (sort_order == -1 ? 1 : -1);
                }
            }
            return 0;
        });
        this.setState({
            sort: sortKey,
            sort_order: sort_order,
            vehicles_sort: vehicles
        });
    }

    render() {

        // date range selector variables
        let start = this.state.startDate.format('MMM. D/YY');
        let end = this.state.endDate.format('MMM. D/YY');
        let label = start + ' - ' + end;
        if (start === end) {
            label = start;
        }

        var total_mpg = 0;
        var total_hrs = 0;
        var total_km = 0;

        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>Vehicles</div>
                    <div className="ml-auto">
                        <AddVehicle size={"sm"} equipment_id={this.props.match.equipment_id} vehicle_types={this.state.vehicle_types}
                                    vehicle_types_sub={this.state.vehicle_types_sub} goToVehicle={this.goToVehicle}/>
                    </div>
                </div>
                <div className="mb-3 form-inline" style={{zIndex: 1000}}>
                    <DateRangePicker
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        ranges={this.state.ranges}
                        maxDate={moment()}
                        onEvent={this.updateDates}
                        autoUpdateInput={true}
                    >
                        <button type="button" className="btn btn-primary selected-date-range-btn mr-1">
                            Summary Period:* <b>{label}</b> <span className="fas fa-caret-down fa-sm"></span>
                        </button>
                    </DateRangePicker>
                    <InputGroup className="mr-1">
                        <UncontrolledButtonDropdown>
                            <DropdownToggle color={(String(this.state.vehicle_type_id) == "null" ? "primary" : "warning")} caret style={{fontSize: '13px', width: 'auto'}}>
                                Type: <b>{this.state.vehicles_type_name}</b>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem key="null" onClick={() => this.changeVehicleType(null)}>All</DropdownItem>
                                <DropdownItem divider/>
                                {Object.keys(this.state.vehicle_types).map((key, i) => {
                                        if (typeof this.state.vehicle_types != "undefined" && typeof this.state.vehicle_types[key] != "undefined" && this.state.vehicle_types[key].is_vehicle == 1) {
                                            return (<DropdownItem key={key} onClick={() => this.changeVehicleType(key)}>{this.state.vehicle_types[key].name}</DropdownItem>)
                                        }
                                    }
                                )}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </InputGroup>
                    <InputGroup className="mr-1">
                        <UncontrolledButtonDropdown>
                            <DropdownToggle caret color={(String(this.state.vehicles_status) == "1" ? "primary" : "warning")} style={{fontSize: '13px', width: 'auto'}}>
                                Status: <b>{this.state.vehicles_status_name}</b>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem key="null" onClick={() => this.changeEquipmentStatus(null)}>All</DropdownItem>
                                <DropdownItem divider/>
                                {Object.keys(this.state.vehicles_statuses).map((key, i) => {
                                        if (typeof this.state.vehicles_statuses[key] != "undefined") {
                                            return (<DropdownItem key={key} onClick={() => this.changeEquipmentStatus(key)}>{this.state.vehicles_statuses[key]}</DropdownItem>)
                                        }
                                    }
                                )}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </InputGroup>
                </div>
                <div className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <div>
                        <div className="card-header">
                            <div className="card-title">{this.state.vehicles_sort.length} Vehicles</div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className={(this.state.vehicles_sort.length > 0 ? "table table-hover table-pointer text-right" : "d-none")}>
                                    <thead>
                                    <tr>
                                        <th className="text-left" onClick={e => this.onSort(e, 'name')}><i className="fas fa-sort"></i> Name</th>
                                        <th className="text-left" onClick={e => this.onSort(e, 'equipment_manufacturer_id')}><i className="fas fa-sort"></i> Make / Model</th>
                                        <th className="text-left" onClick={e => this.onSort(e, 'status')}><i className="fas fa-sort"></i> Operational</th>
                                        <th className="text-left"><i className="fas fa-sort d-none"></i> Current Location</th>
                                        <th className="text-left"><i className="fas fa-sort d-none"></i> Fuel</th>
                                        <th className="text-left" onClick={e => this.onSort(e, 'last_moved')}><i className="fas fa-sort"></i> Last Moved</th>
                                        <th className="text-right" onClick={e => this.onSort(e, 'odometer')}><i className="fas fa-sort"></i> Odo (km)</th>
                                        <th className="text-right" onClick={e => this.onSort(e, 'avg_mpg')}><i className="fas fa-sort"></i> Avg MPG</th>
                                        <th className="text-right" onClick={e => this.onSort(e, 'hrs')}><i className="fas fa-sort"></i> Hours*</th>
                                        <th className="text-right" onClick={e => this.onSort(e, 'km')}><i className="fas fa-sort"></i> KMs*</th>

                                    </tr>
                                    </thead>
                                    <tbody>
                                    {Object.entries(this.state.vehicles_sort).map(([key, vehicles]) => {
                                        total_mpg = total_mpg + vehicles.avg_mpg;
                                        total_hrs = total_hrs + vehicles.hrs;
                                        total_km = total_km + vehicles.km;
                                        var odometer = vehicles.odometer;
                                        if (vehicles.location.odometer > 0) {
                                            odometer = parseInt(vehicles.location.odometer);
                                        }

                                        return (
                                            <tr key={key} onClick={this.goToVehicle.bind(this, vehicles.equipment_id)}>
                                                <td className="text-left">{vehicles.name}</td>
                                                <td className="text-left">{(typeof this.state.vehicle_manufacturers[vehicles.equipment_manufacturer_id] != "undefined" ? this.state.vehicle_manufacturers[vehicles.equipment_manufacturer_id].name : "")} / {vehicles.model}</td>
                                                <td className="text-left"><EquipmentStatus status={vehicles.status}/></td>
                                                <td className="text-left">{vehicles.location.name}</td>
                                                <td className="text-left"><FuelLevel fuel={vehicles.location.fuel}/></td>
                                                <td className="text-left"
                                                    title={vehicles.last_moved}>{(vehicles.last_moved == null ? "NA" : moment(vehicles.last_moved).fromNow())}</td>
                                                <td className="text-right">{(odometer == null ? "" : odometer.toLocaleString("en-US"))}</td>
                                                <td className="text-right">{vehicles.avg_mpg}</td>
                                                <td className="text-right">{vehicles.hrs}</td>
                                                <td className="text-right">{vehicles.km.toLocaleString("en-US")}</td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <th colspan="7"></th>
                                        <th className="text-right">{(total_mpg / Object.entries(this.state.vehicles_sort).length).toFixed(1)} mpg</th>
                                        <th className="text-right">{total_hrs.toFixed(1)} hrs</th>
                                        <th className="text-right">{total_km.toLocaleString("en-US")} km</th>
                                    </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default (VehicleList);