import React, {Component} from 'react';
import {
    Button,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Input
} from 'reactstrap';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'

var moment = require("moment-timezone");
moment.tz.setDefault("America/Halifax");

class ShareLocation extends Component {
    constructor(props) {
        super(props);
        this.updateDates = this.updateDates.bind(this);
        var start_today = moment().clone().startOf('day');
        var end_today = moment().clone().endOf('day');
        this.state = {
            modal: false,
            note: "",
            show_history: true,
            show_load: true,
            equipment_id: false,
            startDate: start_today,
            endDate: end_today,
            ranges: {
                'Today': [start_today, end_today],
                'Tomorrow': [start_today.clone().add(1, 'days'), end_today.clone().add(1, 'days')],
                'Next 8 Hours': [moment(), moment().add(8, 'hours')],
                'Next 12 Hours': [moment(), moment().add(12, 'hours')],
                'Next 24 Hours': [moment(), moment().add(24, 'hours')],
                'Next 48 Hours': [moment(), moment().add(48, 'hours')],
            },
            url: ""
        };
    }

    componentDidUpdate() {
        var self = this;
        if (typeof this.props.equipment_id != "undefined" && this.props.hidden==true && this.props.equipment_id != this.state.equipment_id && this.props.equipment_id != false) {
            this.setState({
                modal: true,
                equipment_id: self.props.equipment_id,
            })
        }
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    };

    updateDates(event, picker) {
        console.log(picker)
        if (event.type == "apply") {
            console.log(picker.endDate)
            this.setState({
                startDate: picker.startDate,
                endDate: picker.endDate
            });
        }
    }

    updateField = (name, value) => {
        if (typeof value != "string" && typeof value.target != "undefined") {
            value = value.target.value;
        }
        this.setState({
            [name]: value
        });
    };

    toggleOption = (name) => {
        this.setState({
            [name]: !this.state[name]
        });
    }

    AddShare = async event => {
        event.preventDefault()
        this.setState({
            processing: true
        });
        var self = this;
        axios.defaults.withCredentials = true;
        axios.post(API_ROOT + '/share', {
            equipment_id: this.props.equipment_id,
            start: this.state.startDate,
            end: this.state.endDate,
            show_history: this.state.show_history,
            show_load: this.state.show_load,
            note: this.state.note
        })
            .then(function (response) {
                self.setState({
                    modal: false,
                    processing: false
                });
                var url = "https://" + window.location.hostname + '/' + response.data.url;
                var button = 'Close & Copy Link to Clipboard';
                if (navigator.clipboard) {
                    navigator.clipboard.writeText(url);
                } else { // Fallback for older browsers
                    const textArea = document.createElement('textarea');
                    textArea.value = url;
                    document.body.appendChild(textArea);
                    textArea.select();
                    try {
                        document.execCommand('copy');
                    } catch (err) {
                        button = "Close";
                    }
                    document.body.removeChild(textArea);
                }
                if (['iPhone'].includes(navigator.platform)){
                    button = "Close";
                    url = "Please copy: "+url;
                }
                Swal.fire({
                    title: "Share Link Created",
                    text: url,
                    showCancelButton: false,
                    confirmButtonText: button,
                    type: 'success'
                })
            })
    };

    render() {
        let start = this.state.startDate.format('HH:mm MMM. D/YY');
        let end = this.state.endDate.format('HH:mm MMM. D/YY');
        let label = start + ' to ' + end;
        if (start === end) {
            // label = start;
        }
        return (
            <>
                <Button className={this.props.hidden ? "d-none" : "mt-2"} color="light" size="sm" onClick={this.toggle}><i
                    className="fa fa-share-square fa-sm mr-1"></i> Share</Button>
                <FormGroup>
                    <Modal isOpen={this.state.modal} toggle={this.toggle} bsSize="lg">
                        <ModalHeader>
                            <div className="h3 mb-0"><i className="fa fa-share-square"></i> Share Live Location: {this.props.name}</div>
                        </ModalHeader>
                        <ModalBody>
                            <Form>
                                <FormGroup row className="mb-1">
                                    <Label sm={4}>Internal Note:</Label>
                                    <Col sm={8}>
                                        <Input type="textarea" placeholder="Who are you sharing it with, and why?" style={{height: "50px"}} size="sm" name="name"
                                               value={this.state.note} onChange={(event) => this.updateField("note", event)}/>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="mb-0">
                                    <Label sm={4}>Period of Time:</Label>
                                    <Col sm={8}>
                                        <DateRangePicker
                                            timePicker={true}
                                            timePicker24Hour={true}
                                            startDate={this.state.startDate}
                                            endDate={this.state.endDate}
                                            ranges={this.state.ranges}
                                            onEvent={this.updateDates}
                                            autoUpdateInput={true}
                                        >
                                            <button type="button" className="btn btn-light selected-date-range-btn mr-1">
                                                {label} <span className="fas fa-caret-down fa-sm"></span>
                                            </button>
                                        </DateRangePicker>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="mb-0">
                                    <Label sm={4} className="text-right pr-0">
                                        <Input type="checkbox" id="show_history" value="1" checked={this.state.show_history}
                                               onChange={(event) => this.toggleOption('show_history')}/>
                                    </Label>
                                    <Label sm={8} for="show_history">Show Route History & Details</Label>
                                </FormGroup>
                                <FormGroup row className="mb-1">
                                    <Label sm={4} className="text-right pr-0">
                                        <Input type="checkbox" id="show_load" value="1" checked={this.state.show_load} onChange={(event) => this.toggleOption('show_load')}/>
                                    </Label>
                                    <Label sm={8} for="show_load">Show Load Information</Label>
                                </FormGroup>
                            </Form>
                            <div className="bg-light p-1 border rounded">
                                <b>The customer will be able to:</b>
                                <ul className="mb-0">
                                    <li>View live position of {this.props.name} from {label}</li>
                                    <li className={(this.state.show_history ? "" : "d-none")}>View ping location, speed, and engine status during that period*</li>
                                    <li className={(this.state.show_load ? "" : "d-none")}>For any matching loads assigned to {this.props.name} in that period, view:*</li>
                                    <ul className={(this.state.show_load ? "" : "d-none")}>
                                        <li>Load number, BOL, description</li>
                                        <li>Planned route and timing for billable segments</li>
                                        <li>Trip descriptions</li>
                                        <li>Trailer and container numbers</li>
                                    </ul>
                                </ul>
                                <div className={(this.state.show_history || this.state.show_load ? "small text-muted" : "d-none")}>* Customers can view this information
                                    indefinitely, but only relating to the specified period
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={this.AddShare}>
                                <i className={(this.state.processing ? "fa fa-spinner fa-spin mr-2" : "d-none")}></i> Create Share Link
                            </Button>
                        </ModalFooter>
                    </Modal>
                </FormGroup>
            </>
        );
    };
}

export default (ShareLocation);
