import React from "react";
import axios from 'axios';
import moment from 'moment';
import {API_ROOT} from '../../api-config';

export async function getDrivers(response) {
    axios.defaults.withCredentials = true;
    await axios.get(`${API_ROOT}/drivers/`, {withCredentials: true})
        .then(async function (ajax_response) {
            var vehicles = {};
            vehicles.data = {};
            vehicles.data = ajax_response.data;
            response(vehicles.data);
        })
        .catch(async function (error) {
            response({});
        });
}

export function getDriversLogged(driver_id, response) {
    axios.defaults.withCredentials = true;
    axios.get(`${API_ROOT}/drivers/logged/` + driver_id, {withCredentials: true})
        .then(function (ajax_response) {
            var vehicles = {};
            vehicles.data = {};
            vehicles.data = ajax_response.data;
            response(vehicles.data);
        })
        .catch(function (error) {
            response({});
        });
}

class DriverStatus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status_print: "",
            status_css: ""
        };
    }

    componentDidMount() {
        this.update_badge()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.status !== this.props.status) {
            this.update_badge()
        }
    }

    update_badge() {
        if (this.props.status == 1) {
            var status_print = "Employed";
            var status_css = "success";
        } else if (this.props.status == 2) {
            var status_print = "On Leave/Pause";
            var status_css = "warning";
        } else {
            var status_print = "Retired";
            var status_css = "dark";
        }
        this.setState({
            status_print: status_print,
            status_css: "badge badge-" + status_css
        });
    }

    render() {
        return (
            <div className={this.state.status_css}>{this.state.status_print}</div>
        );
    }
}

export default DriverStatus;