import React, {Component} from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import 'moment-timezone';
import {animateScroll} from "react-scroll";
import {
    Row,
    Col,
    DropdownToggle,
    UncontrolledButtonDropdown,
    DropdownMenu,
    DropdownItem, Modal, ModalBody
} from 'reactstrap';
import ComposeMessage from "./ComposeMessage";
import {Link} from "react-router-dom";
import NewMessage from "./NewMessage";
import {getDrivers, getDriversLogged} from "../Drivers/DriverFunctions";
import {getStaff} from "../Staff/StaffFunctions";
import {getVehicles} from "../Vehicles/VehicleFunctions";
import Swal from "sweetalert2";
import LoggedTrip from "../Loads/LoggedTrip";
import LoadDisplay from "../Loads/LoadDisplay";
import AddMaintenance from "../Maintenance/AddMaintenance";

moment.tz.setDefault("America/Halifax");

class ReadThead extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vehicles: {},
            driver_id: null,
            name: "",
            default_message: "",
            default_attachments: [],
            messages: {
                list: [],
                loading: true,
            },
            messages_maint: {
                list: [],
                loading: true,
            },
            location: {
                lat: null,
                lon: null,
                description: "",
                timestamp: 0
            },
            offset: 0,
            eld: [],
            loads: {},
            staff: {},
            drivers: {},
            drivers_sort: [],
            selected_log_id: null,
            maint_id: null,
            show_saved: 0,
            highlighted_message: false
        };
        this.toggle_load = this.toggle_load.bind(this);
        this.update_load = this.update_load.bind(this);
        this.update_eld = this.update_eld.bind(this);
        this.getMessages = this.getMessages.bind(this);
    }

    componentDidMount() {
        document.title = "Inbox | Custom TMS";
        this.setState({
            driver_id: this.props.match.params.driver_id,
            }, () => {
                this.getMessages(0);
            }
        )
        var self = this;
        getDrivers(function (drivers) {
            var drivers_sort = [];
            for (const driver_id of Object.keys(drivers)) {
                var obj = drivers[driver_id];
                obj['name'] = obj.first_name + ' ' + obj.last_name;
                drivers_sort.push(obj)
            }
            self.setState({
                drivers: drivers,
                drivers_sort: drivers_sort
            });
        });
        getStaff(function (staff) {
            self.setState({staff: staff});
        });
        getVehicles(function (vehicles) {
            self.update_load()
            self.update_eld();
            self.setState({vehicles: vehicles});
            self.updateDriverPosition();
            setInterval(self.updateDriverPosition, 60000);
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (typeof this.props.last_updated_thread != "undefined" && prevProps.last_updated_hash !== this.props.last_updated_hash) {
            if (this.props.last_updated_thread == this.state.driver_id) {
                this.getMessages(0);
                new Audio("newmessage.mp3").play();
            }
        }
    }

    updateDriverPosition = () => {
        var self = this;
        if (this.state.driver_id != null) {
            getDriversLogged(this.state.driver_id, function (drivers_logged) {
                if (typeof drivers_logged[self.state.driver_id] != "undefined" && drivers_logged[self.state.driver_id].ping_info != null) {
                    var vars = drivers_logged[self.state.driver_id].ping_info.split('|');
                    self.setState({
                        location: {
                            lon: vars[0],
                            lat: vars[1],
                            description: vars[2],
                            timestamp: vars[3]
                        }
                    });
                } else {
                    self.setState({
                        location: {
                            lat: null,
                            lon: null,
                            description: "",
                            timestamp: 0
                        }
                    });
                }
            });
        }
    }

    scrollToBottom = () => {
        animateScroll.scrollToBottom({
            duration: 200,
            smooth: true,
            delay: 0,
            isDynamic: true,
            containerId: "message-list"
        });
    }
    scrollToMessage = (id) => {
        document.getElementById("id" + id).scrollIntoView({behavior: 'smooth'})
    }

    forward_message = (message_id) => {
        this.setState({
            default_message: "From " + (typeof this.state.drivers[this.state.driver_id] == "undefined" ? this.state.messages.list[message_id].from : this.state.drivers[this.state.driver_id].first_name + ' ' + this.state.drivers[this.state.driver_id].last_name) + ": " + this.state.messages.list[message_id].body,
            default_attachments: this.state.messages.list[message_id].media.split(',').map(media => "mms/" + media)
        })
    }

    getMessages = (offset) => {
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(`${API_ROOT}/messages/` + this.state.driver_id + '/' + offset + '?show_saved=' + this.state.show_saved + "&mode=" + this.props.mode, {withCredentials: true})
            .then(function (response) {
                if (offset == 0) {
                    self.setState({
                        name: response.data.name,
                        messages: {
                            list: response.data.messages,
                            loading: false
                        },
                        messages_maint: {
                            list: response.data.messages_maint,
                            loading: false
                        },
                        offset: offset
                    }, function () {
                        if (typeof window.location.hash == "undefined" || !window.location.hash.includes("#jumpto")) {
                            setTimeout(() => {
                                self.scrollToBottom()
                            }, 100);
                            setTimeout(() => {
                                self.scrollToBottom()
                            }, 750);
                        }
                        self.props.refreshMessages();
                    });
                } else {
                    var num_added = response.data.messages.length;
                    var new_messages = response.data.messages.concat(self.state.messages.list);
                    self.setState({
                        messages: {
                            list: new_messages,
                            loading: false
                        },
                        offset: offset
                    }, function () {

                    });
                }
                if (typeof window.location.hash != "undefined" && window.location.hash.includes("#jumpto")) {
                    var message_id = window.location.hash.replace('#jumpto', '');
                    // loop through to see if the message is loaded. If not, request next block
                    var found = false;
                    for (var i = 0; i < self.state.messages.list.length; i++) {
                        if (self.state.messages.list[i].message_id == message_id) {
                            setTimeout(() => {
                                self.scrollToMessage(message_id)
                            }, 800);
                            setTimeout(() => {
                                self.setState({highlighted_message: message_id})
                            }, 1200);
                            found = true;
                            window.location.hash = "";
                        }
                    }
                    if (found == false) {
                        self.getMessages(self.state.offset + 100)
                    }
                }
            })
            .catch(function (error) {
                self.userSignOut()
                self.setState({isAuthenticating: false});
            });
    }

    update_load() {
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + "/loads/?driver_id=" + self.state.driver_id + "&startDate=" + moment().subtract(30, 'day').startOf('day') + "&endDate=" + moment().add(4, 'day').startOf('day'))
            .then(function (response) {
                self.setState({
                        loads: response.data,
                    }
                );
            })
    }

    update_eld() {
        var self = this;
        axios.get(`${API_ROOT}/drivers/log?days=3&driver_id=` + self.state.driver_id, {withCredentials: true})
            .then(function (ajax_response) {
                self.setState({
                    eld: ajax_response.data.reverse(),
                });
            })
    }

    toggleSubscribe = (key) => {
        var self = this;
        axios.defaults.withCredentials = true;
        axios.post(API_ROOT + '/messages/subscribe', {action: (self.props.messages.subscribed.includes(key) ? "unsubscribe" : "subscribe"), key: key})
            .then(function (response) {
                self.props.refreshMessages();
            })
    }

    toggleSearch = (show_saved) => {
        this.setState({
            show_saved: show_saved,
        }, function () {
            this.getMessages(0);
        });
    }

    toggleSaved = (action, message_id) => {
        var self = this;
        axios.defaults.withCredentials = true;
        axios.post(API_ROOT + '/messages/save', {action: action, message_id: message_id})
            .then(function (response) {
                self.getMessages(0);
            })
    }

    openLoad = (load_id) => {
        this.setState({
            load_id: load_id,
            load_modal: true
        })
    }
    toggle_load = () => {
        this.setState({
            load_modal: !this.state.load_modal
        });
        if (this.state.load_modal) {
            this.update_load()
        }
    };

    openEld = (selected_log_id) => {
        this.setState({
            selected_log_id: selected_log_id,
        })
    }

    attachTo = (target, body, url) => {
        axios.defaults.withCredentials = true;
        if (target == "maint") {
            var self = this;
            axios.post(API_ROOT + '/maintenance/null', {
                maint_id: null,
                date_enter: moment(),
                equipment_id: null,
                date_completed: null,
                category: "(select)",
                description: body,
                urgency: 0,
                starting_urgency: 0,
                vendor_name: "",
                cost_est: 0,
                cost_actual: null,
                status: 0,
                starting_status: 0,
                reminder_date: null,
                reminder_text: "",
                mms_attachment: url
            })
                .then(function (response) {
                    self.setState({
                        maint_id: response.data.maint_id
                    });
                })
        } else {
            const formData = new FormData();
            axios.get('mms/' + url, {responseType: 'blob'}).then(function (response) {
                formData.append('file', response.data, 'attachment from dispatch.' + url.split('.').pop());
                axios.post(API_ROOT + '/loads/' + target + '/attachment', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })
                    .then(function () {
                        Swal("Done", "The file has been attached to load #" + target, "success");
                    }).catch(function (error) {
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error", error.response.data.Message, "error");
                    }
                });
            })
        }
    }

    render() {
        return (
            <ContentWrapper style={{marginBottom: "-100px", paddingBottom: "0px"}}>
                <div className="content-heading">
                    <div>
                        <Link
                            to={(this.props.mode == "maintenance" ? "/maint_mssg" : "/dispatch")}>{(this.props.mode == "maintenance" ? "Maintenance" : "Dispatch")}</Link> &nbsp;/&nbsp; Message: {this.state.name}
                    </div>
                    <div className={(this.props.messages.loading && this.props.mode != "dispatch" ? "d-none" : "btn btn-light btn-xs ml-auto")}
                         title={(this.props.messages.subscribed.includes(this.state.driver_id) ? "You are subscribed to SMS notifications for new messages" : "Click to subscribe to SMS notifications for new messages")}>
                        <i className={(this.props.messages.subscribed.includes(this.state.driver_id) ? "fas fa-bell text-warning" : "far fa-bell-slash")}
                           onClick={() => this.toggleSubscribe(this.state.driver_id)}></i>
                    </div>
                </div>
                <div className={(this.state.driver_id == 0 ? "alert alert-info" : "d-none")}><i className="fas fa-exclamation-circle"></i> Messages in this thread are from numbers
                    not
                    currently tied to any driver. Please add
                    the appropriate number(s) to drivers to have them categorized correctly and be able to respond.
                </div>
                <div className={(this.state.loading ? "card card-default whirl traditional pb-0" : "card card-default pb-0")}>
                    <div>
                        <div className="card-body pt-0 pb-0 mb-0" style={{marginTop: "-10px"}}>
                            <NewMessage default_message={this.state.default_message} default_attachments={this.state.default_attachments} drivers={this.state.drivers}
                                        drivers_sort={this.state.drivers_sort} hide_button={true}
                                        mode={this.props.mode}/>
                            <Row>
                                <Col xl={8} md={12}>
                                    <UncontrolledButtonDropdown className="mb-1">
                                        <DropdownToggle color="light" size="xs" caret>{this.state.show_saved == 0 ? "Show All Messages" : "Show Flagged Messages"}</DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={() => this.toggleSearch(0)}>Show All Messages</DropdownItem>
                                            <DropdownItem onClick={() => this.toggleSearch(1)}>Show Flagged Messages</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                    <div className={(!this.state.messages.loading ? "mb-1" : "whirl traditional mb-1")}
                                         style={{height: 'calc(100vh - 290px)', overflowY: "auto", border: "2px solid #e4e2e2"}}
                                         id="message-list">
                                        <div className={(this.state.messages.list.length > 0 && this.state.messages.list.length % 100 === 0 ? "text-center p-1" : "d-none")}>
                                            <div className={(this.state.messages.loading ? "d-none" : "btn btn-outline-danger")}
                                                 onClick={() => this.getMessages(this.state.offset + 100)}>
                                                <i className="fas fa-chevron-up"></i> Load More Messages <i className="fas fa-chevron-up"></i>
                                            </div>
                                        </div>
                                        <div className={(this.state.messages.loading ? "" : "d-none")}>
                                            <i className="fas fa-spinner fa-spin"></i> Loading
                                        </div>
                                        {Object.keys(this.state.messages.list).map((key, item) => {
                                            var media = [];
                                            if (this.state.messages.list[key].media != null) {
                                                media = this.state.messages.list[key].media.split(',');
                                            }
                                            var read_by = [];
                                            if (this.state.messages.list[key].read_by != null) {
                                                read_by = this.state.messages.list[key].read_by.split(',');
                                            }
                                            var border = "1px dashed #B1B1B1 ";
                                            if (key > 0 && moment.unix(this.state.messages.list[key].date_sent) - moment.unix(this.state.messages.list[key - 1].date_sent) < (60 * 30 * 1000)) {
                                                border = "1px solid #fff";
                                            }
                                            return (
                                                <div key={key} name={"id" + key} id={"id" + this.state.messages.list[key].message_id}>
                                                    <div className="row no-gutters p-1"
                                                         style={(this.state.messages.list[key].read_by == null || this.state.messages.list[key].read_by.split(',').indexOf(localStorage.getItem('staff_id')) == -1 || this.state.highlighted_message == this.state.messages.list[key].message_id ? {
                                                             animation: 'newMessageFade 3s',
                                                             borderTop: border
                                                         } : {borderTop: border})}>
                                                        <div className="col col-sm-3 col-lg-2">
                                                            {moment.unix(this.state.messages.list[key].date_sent).format("MMM D HH:mm")}
                                                            <UncontrolledButtonDropdown className="ml-2">
                                                                <DropdownToggle color="light" size="xs"><i className="fa-solid fa-ellipsis-vertical"></i></DropdownToggle>
                                                                <DropdownMenu>
                                                                    <DropdownItem onClick={() => this.forward_message(key)}><i className="fa-solid fa-share"></i> Forward
                                                                        Message</DropdownItem>
                                                                    <DropdownItem onClick={this.attachTo.bind(this, "maint", this.state.messages.list[key].body, media[0])}><i
                                                                        className="fa-solid fa-toolbox"></i> Create Maintenance Item</DropdownItem>
                                                                    {Object.keys(this.state.loads).map((key2, item2) => {
                                                                        var start = moment(this.state.loads[key2].start);
                                                                        if (media.length > 0 && moment(moment.unix(this.state.messages.list[key].date_sent)).diff(start, 'days')>=-1 && moment(moment.unix(this.state.messages.list[key].date_sent)).diff(start, 'days')<=1) {
                                                                            return (<DropdownItem
                                                                                onClick={this.attachTo.bind(this, this.state.loads[key2].load_id, this.state.messages.list[key].body, media[0])}>
                                                                                <i className="fa-solid fa-paperclip"></i> Attach File to Load #{this.state.loads[key2].load_id} ({start.format("MMM D HH:mm")})
                                                                            </DropdownItem>)
                                                                        }
                                                                    })}
                                                                    <DropdownItem divider/>
                                                                    <DropdownItem
                                                                        className={(typeof this.state.staff[this.state.messages.list[key].staff_id] == "undefined" ? "d-none" : "")}>By: {(typeof this.state.staff[this.state.messages.list[key].staff_id] == "undefined" ? "" : this.state.staff[this.state.messages.list[key].staff_id].first_name + ' ' + this.state.staff[this.state.messages.list[key].staff_id].last_name)}</DropdownItem>
                                                                    <DropdownItem>Date: {moment.unix(this.state.messages.list[key].date_sent).format("YYYY-MM-DD HH:mm:ss")}</DropdownItem>
                                                                    <DropdownItem>From #: {this.state.messages.list[key].from}</DropdownItem>
                                                                    <DropdownItem>To #: {this.state.messages.list[key].to}</DropdownItem>
                                                                    <DropdownItem divider/>
                                                                    {read_by.map((staff_id, item) => {
                                                                        return (<DropdownItem>Seen
                                                                            by {(typeof this.state.staff[staff_id] == "undefined" ? "" : this.state.staff[staff_id].first_name + ' ' + this.state.staff[staff_id].last_name)}</DropdownItem>)
                                                                    })}
                                                                </DropdownMenu>
                                                            </UncontrolledButtonDropdown>
                                                            <div className="btn btn-xs btn-light ml-1" title="Save this message"
                                                                 onClick={() => this.toggleSaved((this.state.messages.list[key].saved == 1 ? 0 : 1), this.state.messages.list[key].message_id)}>
                                                                <i className={(this.state.messages.list[key].saved == 1 ? "fas fa-star text-warning" : "far fa-star")}></i>
                                                            </div>
                                                        </div>
                                                        <div className="col col-sm-9 col-lg-10">
                                                            <div className={(this.state.messages.list[key].body == "" ? "d-none" : "clearfix")}>
                                                                <div
                                                                    className={(this.state.messages.list[key].from == "+19028137070" || this.state.messages.list[key].from == "+19512600066" ? "float-right" : "text-left")}
                                                                    style={{maxWidth: "75%", width: "fit-content"}}>
                                                                    <div
                                                                        className={(this.state.messages.list[key].from == "+19028137070" || this.state.messages.list[key].from == "+19512600066" ? "bg-info-dark rounded pl-2 pr-2 pt-1 pb-1 mr-2" : "bg-gray-light text-dark rounded pl-2 pr-2 pt-1 pb-1")}
                                                                    >
                                                                        <span
                                                                            className={(this.state.messages.list[key].is_call == 1 ? "d-none" : "")}>{this.state.messages.list[key].body}</span>
                                                                        <span className={(this.state.messages.list[key].is_call == 0 ? "d-none" : "font-italic small")}><i
                                                                            className="fas fa-phone"></i> Called Dispatch</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className={(media.length == 0 ? "d-none" : (this.state.messages.list[key].from == "+19028137070" || this.state.messages.list[key].from == "+19512600066" ? "text-right" : "text-left"))}>
                                                                {Object.keys(media).map((key2, item2) => {
                                                                    var extension = media[key2].split('.').pop();
                                                                    if (extension == 'jpeg' || extension == 'jpg' || extension == 'png' || extension == 'webp' || extension == 'gif') {
                                                                        return (
                                                                            <a href={"mms/" + media[key2]} target="_blank"><img
                                                                                src={"mms/" + media[key2]} className="img-fluid rounded p-1"
                                                                                style={{maxWidth: "400px", maxHeight: "250px", height: "250px"}} alt="View Attachment"
                                                                                title="Click to View"/>
                                                                            </a>
                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <a href={"mms/" + media[key2]} target="_blank" className="m-2 btn btn-xs btn-primary">View
                                                                                Attachment (.{extension})</a>
                                                                        )
                                                                    }
                                                                }, this)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }, this)}
                                    </div>
                                    <div className={(this.state.driver_id == 0 ? "d-none" : "")}>
                                        <ComposeMessage driver_id={this.state.driver_id} mode={this.props.mode}/>
                                    </div>
                                </Col>
                                <Col xl={4} md={12} className="pt-3">
                                    <div className={(this.state.location.lon == null ? "d-none" : "")}>
                                        <div className="h4 mb-0 text-bold">CURRENT LOCATION</div>
                                        <div>At {this.state.location.description} as
                                            of {moment.duration(moment().unix() - this.state.location.timestamp, 'seconds').humanize()} ago
                                        </div>
                                        <iframe width="100%" height="200" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"
                                                src={"https://www.google.com/maps?q=" + this.state.location.lat + "," + this.state.location.lon + "&hl=en&z=10&t=h&output=embed"}></iframe>
                                    </div>

                                    <div className="h4 mb-0 text-bold pt-2">LOAD SCHEDULE</div>
                                    <table className="table table-sm table-hover table-pointer mb-3">
                                        <tbody>
                                        {Object.keys(this.state.loads).map((key, item) => {
                                            if (moment(this.state.loads[key].start)>moment() || moment(moment()).diff(moment(this.state.loads[key].start), 'days')<=1) {
                                                var classes = "pt-0 pb-0 ";
                                                if (this.state.loads[key].status == 0) { // Draft
                                                    classes += "bg-light";
                                                } else if (this.state.loads[key].status == 1) { // scheduled
                                                    classes += "bg-info";
                                                } else if (this.state.loads[key].status == 2) { // Started
                                                    classes += "bg-warning";
                                                } else if (this.state.loads[key].status == 3) { // Completed
                                                    classes += "bg-success";
                                                } else if (this.state.loads[key].status >= 4) { // Billed
                                                    classes += "bg-dark text-light";
                                                }
                                                return (
                                                    <tr key={key} onClick={this.openLoad.bind(this, this.state.loads[key].load_id)}>
                                                        <td className={classes}>#{this.state.loads[key].load_id}</td>
                                                        <td className={classes}>{moment(this.state.loads[key].start).format("MMM. D HH:mm")}</td>
                                                        <td className={classes}
                                                            style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis",}}>{this.state.loads[key].description}</td>
                                                        <td className={classes}>{(typeof this.state.vehicles[this.state.loads[key].vehicle_id] == "undefined" ? "" : this.state.vehicles[this.state.loads[key].vehicle_id].name)}</td>
                                                    </tr>
                                                )
                                            }
                                        })}
                                        </tbody>
                                    </table>
                                    <div className="h4 mb-0 text-bold">ELD LOG</div>
                                    <table className="table table-sm table-hover table-pointer mb-3">
                                        <tbody>
                                        {this.state.eld.map((eld, key) => {
                                            return (
                                                <tr key={key} onClick={this.openEld.bind(this, eld.drivers_logged_id)}>
                                                    <td className="pt-0 pb-0">{moment(eld.in_time).format("MMM. D HH:mm")} to {(eld.out_time == null ? "(still logged in)" : moment(eld.out_time).format("MMM. D HH:mm"))}</td>
                                                    <td className="pt-0 pb-0">{(typeof this.state.vehicles[eld.equipment_id] == "undefined" ? "" : this.state.vehicles[eld.equipment_id].name)}</td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <LoggedTrip history={this.props.history} log_id={this.state.selected_log_id} drivers={this.state.drivers}
                            equipment={this.state.vehicles} update_load={this.update_load} openLoad={this.openLoad} modal_closed_callback={this.openEld}/>
                <Modal isOpen={this.state.load_modal} fade={false} toggle={() => {
                    this.toggle_load()
                }} fullscreen size="xl">
                    <div className="modal-header">
                        <div className="modal-title">
                            <div className="h3 mb-0">
                                Load # {this.state.load_id}
                            </div>
                        </div>
                        <Link to={"../loads/" + this.state.load_id} title="Open in new window"><i className="fas fa-external-link-alt"></i></Link>
                    </div>
                    <ModalBody className="pt-0">
                        <LoadDisplay {...this.state} props={this.props} load_id={this.state.load_id}/>
                    </ModalBody>
                </Modal>
                <AddMaintenance maint_id={this.state.maint_id} equipment={this.state.vehicles} equipment_id={null} hidden={true}/>
            </ContentWrapper>
        );
    }
}

export default (ReadThead);
