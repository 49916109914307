import React, {Component} from "react";
import {getDrivers} from "../Drivers/DriverFunctions";
import {Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Label, Modal, ModalBody, ModalHeader, UncontrolledButtonDropdown} from "reactstrap";
import ComposeMessage from "./ComposeMessage";

class NewMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            driver_id: null,
            default_message: "",
            default_attachments: [],
            drivers: {},
            drivers_sort: [],
            processing: false
        };
        this.newMessageCallback = this.newMessageCallback.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (typeof this.props.default_message != "undefined" && (typeof prevProps.default_message == "undefined" || prevProps.default_message !== this.props.default_message)) {
            this.setState({
                default_message: this.props.default_message,
                default_attachments: this.props.default_attachments,
                modal: true,
            })
        }
        if (typeof this.props.drivers != "undefined" && (typeof prevProps.drivers == "undefined" || prevProps.drivers !== this.props.drivers)) {
            console.log('set props')
            this.setState({
                drivers: this.props.drivers,
                drivers_sort: this.props.drivers_sort
            })
        }
    }

    newMessageCallback = () => {
        this.setState({
            modal: false
        });
    }

    updateField = (name, value) => {
        if (typeof value != "string" && typeof value.target != "undefined") {
            value = value.target.value;
        }
        this.setState({
            [name]: value
        });
    };


    toggle = () => {
        this.setState({
            modal: !this.state.modal,
            default_message: "",
            default_attachments: []
        });
    };

    render() {
        var to = "(select)";
        if (this.state.driver_id == "all") {
            to = "All Drivers";
        } else if (this.state.driver_id == "all_logged") {
            to = "All Logged-In Drivers";
        } else if (typeof this.state.drivers[this.state.driver_id] != "undefined") {
            to = this.state.drivers[this.state.driver_id].name;
        }
        return (
            <>
                <Button color="primary" size="sm" onClick={this.toggle} className={(typeof this.props.hide_button != "undefined" ? "d-none" : "")}><i
                    className="fas fa-plus"></i> Compose</Button>
                <FormGroup>
                    <Modal isOpen={this.state.modal} toggle={this.toggle} bsSize="lg">
                        <ModalHeader>
                            <div className="h3 mb-0">Compose New Message</div>
                        </ModalHeader>
                        <ModalBody>
                            <Form>
                                <FormGroup row className="mb-1">
                                    <Label sm={3}>To:</Label>
                                    <Col sm={9}>
                                        <UncontrolledButtonDropdown className="mr-1">
                                            <DropdownToggle color="secondary" caret style={{fontSize: '13px', width: 'auto'}}>
                                                {to}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem key="all" onClick={() => this.updateField('driver_id', 'all')}>All Drivers</DropdownItem>
                                                <DropdownItem key="all_logged" onClick={() => this.updateField('driver_id', 'all_logged')}>All Logged-In Drivers</DropdownItem>
                                                <DropdownItem divider/>
                                                {Object.keys(this.state.drivers_sort).map((key, i) => {
                                                    return (
                                                        <DropdownItem key={key} disabled={!this.state.drivers[this.state.drivers_sort[key].driver_id].phone.includes('+1')}
                                                                      onClick={() => this.updateField('driver_id', this.state.drivers_sort[key].driver_id)}>{this.state.drivers_sort[key].name}</DropdownItem>
                                                    )
                                                })}
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={3}>Message:</Label>
                                    <Col sm={9}>
                                        <ComposeMessage driver_id={this.state.driver_id} default_message={this.state.default_message}
                                                        default_attachments={this.state.default_attachments} newMessageCallback={this.newMessageCallback} mode={this.props.mode}/>
                                    </Col>
                                </FormGroup>
                            </Form>
                        </ModalBody>
                    </Modal>
                </FormGroup>
            </>
        )
    }
}

export default (NewMessage);
