import React, {Component} from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {Link} from 'react-router-dom';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import 'lity/dist/lity.min.js';
import 'lity/dist/lity.min.css';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import 'moment-timezone';
import LocationDisplay from "./LocationDisplay";

moment.tz.setDefault("America/Halifax");

class LocationPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            location_id: null,
            details: {
                loading: true,
                name: "",
                address: "",
                province: "",
                city: "",
                postal: "",
                country: "",
                lat: "",
                lon: "",
                radius: 0,
                is_stop: 1
            },
        };
    }

    componentDidMount() {
        document.title = "Location # " + this.props.match.params.location_id + " | Custom TMS";
        this.setState({location_id: this.props.match.params.location_id})
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + '/location/' + self.props.match.params.location_id)
            .then(function (response) {
                response.data.loading = false;
                self.setState({
                    details: response.data,
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    }

    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>
                        <Link to="../locations">Locations</Link>&nbsp;/&nbsp; {this.state.details.name}
                    </div>
                </div>
                <LocationDisplay location_id={this.state.location_id} show_trips={true}/>
            </ContentWrapper>
        );
    }
}
export default LocationPage;