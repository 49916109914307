import React, {Component} from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {Link} from 'react-router-dom';
import {
    Card,
    CardBody,
    Row,
    Col,
    InputGroup,
    Input,
    ButtonGroup,
    InputGroupAddon,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem, Button,
} from 'reactstrap';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import 'lity/dist/lity.min.js';
import 'lity/dist/lity.min.css';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import 'moment-timezone';

moment.tz.setDefault("America/Halifax");

class DriverPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            driver_id: null,
            details: {
                loading: true,
                first_name: "",
                last_name: "",
                is_company:0,
                address1: "",
                address2: "",
                province: "",
                city: "",
                postal: "",
                country: "",
                dob: null,
                notes: "",
                phone: "",
                phone_office: "",
                email: "",
                rate_hr: 0,
                rate_miles: 0,
                status: 0,
                driver_num: "",
                cdl_num: "",
                cdl_jurisdiction: "",
                cdl_expiry: null,
                start_date: null,
                available_days: [],
                available_hours: [],
            },
            drivers_statuses: {1: "Active", 2: "On Leave/Pause", 0: "Terminated"},
            province_list: ['NB', 'NS', 'PE', 'NL', 'ON', 'QC', 'SK', 'MB', 'AB', 'BC'],
        };
        this.updateValue = this.updateValue.bind(this);
    }

    componentDidMount() {
        document.title = "Driver # " + this.props.match.params.driver_id + " | Custom TMS";
        this.setState({driver_id: this.props.match.params.driver_id})
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + '/drivers/' + self.props.match.params.driver_id)
            .then(function (response) {
                response.data.loading = false;
                self.setState({
                    details: response.data,
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    }

    saveDetails = async event => {
        if (event) {
            event.preventDefault();
        }
        var self = this;
        axios.defaults.withCredentials = true;
        axios.put(API_ROOT + '/drivers/' + this.props.match.params.driver_id, {data: this.state.details})
            .then(function (response) {
                toast.success("Changes Saved", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    };

    updateValue(event, name, value) {
        var self = this;
        if (event.target) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (typeof name == "undefined") {
            name = event.target.name
        }
        if (typeof value == "undefined") {
            value = event.target.value
        }
        var details = this.state.details;
        details[name] = value;
        this.setState({
            details: details
        }, () => {
            self.saveDetails();
        });
    }

    changeStatus = (status) => {
        var details = this.state.details;
        details['is_company'] = status;
        var self = this;
        this.setState({
            details: details
        }, () => {
            self.saveDetails();
        });
    }


    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>
                        <Link to="../drivers">Drivers</Link>&nbsp;/&nbsp; {this.state.details.first_name} {this.state.details.last_name}
                    </div>
                </div>
                <Row>
                    <Col lg={6}>
                        <Card className={(this.state.details.loading ? "card card-default whirl traditional" : "card card-default")}>
                            <CardBody>
                                <p className="lead bb">Details</p>
                                <form className="form-horizontal">
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Type</label>
                                        <div className="col-xl-9">
                                            <ButtonGroup size="xs">
                                                <Button color="light" onClick={() => this.changeStatus(0)} active={this.state.details.is_company === 0} className={(this.state.details.is_company === 0?"text-bold":"")}>Employee</Button>
                                                <Button color="light" onClick={() => this.changeStatus(1)} active={this.state.details.is_company === 1} className={(this.state.details.is_company === 1?"text-bold":"")}>Company</Button>
                                            </ButtonGroup>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">{(this.state.details.is_company==1?"Company Name":"First Name")}</label>
                                        <div className="col-xl-9">
                                            <Input size="sm" type="text" name="first_name" value={this.state.details.first_name} required onChange={this.updateValue}/>
                                        </div>
                                    </Row>
                                    <Row className={(this.state.details.is_company==1?"d-none":"mb-1")}>
                                        <label className="col-xl-3 col-form-label pt-0">Last Name</label>
                                        <div className="col-xl-9">
                                            <Input size="sm" type="text" name="last_name" value={this.state.details.last_name} required onChange={this.updateValue}/>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Address</label>
                                        <div className="col-xl-9">
                                            <Input size="sm" type="text" name="address1" value={this.state.details.address1} required onChange={this.updateValue}/>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Suite/Unit</label>
                                        <div className="col-xl-9">
                                            <Input size="sm" type="text" name="address2" value={this.state.details.address2} onChange={this.updateValue}/>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">City</label>
                                        <div className="col-xl-9">
                                            <Input size="sm" type="text" name="city" value={this.state.details.city} required onChange={this.updateValue}/>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Province</label>
                                        <div className="col-xl-9">
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle color="secondary" size="sm" caret style={{fontSize: '13px', width: 'auto'}}>
                                                    {this.state.details.province}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {this.state.province_list.map((province, i) =>
                                                        <DropdownItem key={province} onClick={() => this.updateValue(false, 'province', province)}>{province}</DropdownItem>
                                                    )}
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Postal</label>
                                        <div className="col-xl-9">
                                            <Input size="sm" type="text" name="postal" value={this.state.details.postal} onChange={this.updateValue}/>
                                        </div>
                                    </Row>

                                    <Row className={(this.state.details.is_company==1?"d-none":"mb-1 mt-1")}>
                                        <label className="col-xl-3 col-form-label pt-0">Phone</label>
                                        <div className="col-xl-9">
                                            <div className="input-group mb-0">
                                                <Input type="tel" className="form-control" name="phone" bsSize="10" size="xs" onChange={this.updateValue}
                                                       value={this.state.details.phone}/>
                                                <a href={"tel:" + this.state.details.phone} title="Call Number"
                                                   className={(this.state.details.phone != null ? "btn btn-sm btn-primary input-group-addon" : "d-none")}><i
                                                    className="fas fa-phone"></i></a>
                                            </div>
                                            <small>Must be in +1XXXXXXXXXX format</small>
                                        </div>
                                    </Row>
                                    <Row className={(this.state.details.is_company==0?"d-none":"mb-1 mt-1")}>
                                        <label className="col-xl-3 col-form-label pt-0">Office Phone</label>
                                        <div className="col-xl-9">
                                            <div className="input-group mb-0">
                                                <Input type="tel" className="form-control" name="phone_office" bsSize="10" size="xs" onChange={this.updateValue}
                                                       value={this.state.details.phone_office}/>
                                                <a href={"tel:" + this.state.details.phone_office} title="Call Number"
                                                   className={(this.state.details.phone_office != null ? "btn btn-sm btn-primary input-group-addon" : "d-none")}><i
                                                    className="fas fa-phone"></i></a>
                                            </div>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Email</label>
                                        <div className="col-xl-9">
                                            <Input size="sm" type="text" name="email" value={this.state.details.email} onChange={this.updateValue}/>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Notes</label>
                                        <div className="col-xl-9">
                                            <Input type="textarea" style={{minHeight: "50px"}} name="notes" value={this.state.details.notes} onChange={this.updateValue}/>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Status</label>
                                        <div className="col-xl-9">
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle color="secondary" size="sm" caret style={{fontSize: '13px', width: 'auto'}}>
                                                    {this.state.drivers_statuses[this.state.details.status]}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {Object.keys(this.state.drivers_statuses).map((key, i) => {
                                                        return (<DropdownItem key={key}
                                                                              onClick={() => this.updateValue(false, 'status', key)}>{this.state.drivers_statuses[key]}</DropdownItem>)
                                                    })}
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </div>
                                    </Row>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={6}>
                        <Card className="card card-default">
                            <CardBody>
                                <p className="lead bb">Wage Rate</p>
                                <Row>
                                    <label className="pt-0 col-xl-4 col-form-label">Hourly</label>
                                    <div className="col-xl-8">
                                        <InputGroup size="sm">
                                            <InputGroupAddon addonType="append">$</InputGroupAddon>
                                            <Input type="text" name="rate_hr" className="text-right" value={this.state.details.rate_hr} onChange={this.updateValue}/>
                                        </InputGroup>
                                    </div>
                                </Row>
                                <Row className="pt-1">
                                    <label className="pt-0 col-xl-4 col-form-label">Per Mile</label>
                                    <div className="col-xl-8">
                                        <InputGroup size="sm">
                                            <InputGroupAddon addonType="append">$</InputGroupAddon>
                                            <Input type="text" name="rate_miles" className="text-right" value={this.state.details.rate_miles} onChange={this.updateValue}/>
                                        </InputGroup>
                                    </div>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <ToastContainer/>
            </ContentWrapper>
        );
    }
}

export default DriverPage;
