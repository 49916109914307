import React, {Component, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import axios from 'axios';

import {API_ROOT} from '../../api-config';
import Map, {
    Marker,
    Popup,
    NavigationControl,
    FullscreenControl,
    ScaleControl,
    Source,
    Layer,
    GeolocateControl,
} from 'react-map-gl/maplibre';
import 'maplibre-gl/dist/maplibre-gl.css';

import Pin from '../Map/pin';
import LoadPin from '../Loads/pin'

var moment = require("moment-timezone");
const money = require("money-math");
moment.tz.setDefault("America/Halifax");

class Share extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loads:[],
            loading:false,
            locations: {},
            equipment: {},
            equipment_name:"",
            photo:"",
            start:"",
            end:"",
            timestamp: 0,
            show_load: 0,
            show_history:0,
            zoom_to: false,
            pings: {},
            startend: [],
            pings_display: [],
            open_popup: false,
            show_pings: false,
            history:[],
            travel_line: {
                type: "Feature",
                properties: {
                    stroke: "#f00"
                },
                geometry: {
                    type: "LineString",
                    coordinates: []
                }
            },
            planned_line: {
                type: "Feature",
                properties: {
                    stroke: "#2688FF"
                },
                geometry: {
                    type: "LineString",
                    coordinates: []
                }
            },
            highlight_point: {
                type: 'FeatureCollection',
                features: [
                    {
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: [0, 0]
                        }
                    }
                ]
            },
            bounds: {x1: false, y1: false, x2: false, y2:false },
            mapstyle: "https://api.maptiler.com/maps/streets-v2/style.json?key=6P8HshICDGDXoVd9nuYs"
        };
    }

    intervalID = 0;

    componentDidMount() {
        document.title = "View Shared Equipment | Custom TMS";
        this.updateShare()
        this.intervalID = setInterval(() => this.updateShare(), 30000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    onStyleLoad = (map, e) => {
        this.setState({map});
    }
    openPopup = (params) => {
        if (params == this.state.open_popup) {
            this.setState({
                    open_popup: false
                },
            );
        } else {
            this.setState({
                    open_popup: params
                },
            );
        }
    };
    zoomTo = (params) => event => {
        this.setState({
                zoom_to: params
            },
        );
        event.stopPropagation();
    };

    togglePings() {
        this.setState({
            show_pings: !this.state.show_pings
        });
    }

    updateShare(){
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + '/share/' + this.props.match.params.share_id + "/"+this.props.match.params.hash)
            .then(function (response) {
                var travel_line = self.state.travel_line;
                var bounds = {x1: false, y1: false, x2: false, y2: false}
                travel_line.geometry.coordinates = [];
                for (var id of Object.keys(response.data.history)) {
                    travel_line.geometry.coordinates.push([response.data.history[id].longitude, response.data.history[id].latitude]);
                    if (response.data.history[id].latitude < bounds.y1 || bounds.y1 == false) { // sw
                        bounds.y1 = response.data.history[id].latitude;
                    }
                    if (response.data.history[id].longitude < bounds.x1 || bounds.x1 == false) { //sw
                        bounds.x1 = response.data.history[id].longitude;
                    }
                    if (response.data.history[id].latitude > bounds.y2 || bounds.y2 == false) { //ne
                        bounds.y2 = response.data.history[id].latitude;
                    }
                    if (response.data.history[id].longitude > bounds.x2 || bounds.x2 == false) { //ne
                        bounds.x2 = response.data.history[id].longitude;
                    }
                }
                var startend = [];
                var last_planned = {}
                var planned_line = self.state.planned_line;
                for (var id of Object.keys(response.data.loads)) {
                    for (var id2 of Object.keys(response.data.loads[id].segments)) {
                        if (response.data.loads[id].segments[id2].route!=null) {
                            var points = JSON.parse(response.data.loads[id].segments[id2].route).coordinates;
                            last_planned = {lat: points[points.length - 1][1], lon: points[points.length - 1][0], type: "end"}
                            if (Object.keys(startend).length == 0) {
                                startend.push({lat: points[0][1], lon: points[0][0], type: "start"})
                            }
                            var new_array = planned_line.geometry.coordinates.concat(points)
                            planned_line.geometry.coordinates = new_array;
                        }
                    }
                }
                if (typeof last_planned.lat!="undefined") {
                    startend.push(last_planned)
                }
                self.setState({
                    startend: startend,
                    equipment_name: response.data.equipment_name,
                    photo: response.data.photo,
                    start: response.data.start,
                    end: response.data.end,
                    show_history: response.data.show_history,
                    show_load: response.data.show_load,
                    bounds: bounds,
                    history: response.data.history,
                    travel_line: travel_line,
                    planned_line: planned_line,
                    loads: response.data.loads,
                });
                if (response.data.live_location!=false){
                    self.setState({
                        zoom_to: {longitude: response.data.live_location.lon, latitude: response.data.live_location.lat, zoom: 10},
                        pings_display: [response.data.live_location],
                        highlight_point: {
                            type: 'FeatureCollection',
                            features: [
                                {
                                    type: 'Feature',
                                    geometry: {
                                        type: 'Point',
                                        coordinates: [response.data.live_location.lon, response.data.live_location.lat]
                                    }
                                }
                            ]
                        }
                    });
                }
            });
    }

    render() {
        return (
            <div className="block-center container pt-2">
                <div className="clearfix">
                    <img className="block-center rounded" style={{width:"300px"}} src="img/logo2.svg" alt="Logo"/>
                    <div className="float-right h4 text-right text-bold pt-1">
                        <div>{this.state.equipment_name}</div>
                        <div>{this.state.start} to {this.state.end}</div>
                    </div>
                </div>
                <div className="card card-flat">
                    <div className="card-body">
                        <div className={(moment(this.state.start) > moment() ? "alert alert-warning" : "d-none")}>
                            <i className="far fa-lg fa-clock mr-1"></i> The tracking period has not started yet. When it does, you will be able to see live vehicle information below.
                        </div>
                        <div className={(moment(this.state.end) < moment() ? "alert alert-info mb-2" : "d-none")}>
                            <i className="fas fa-lg fa-history mr-1"></i> The live tracking period has ended. The information below stopped updating at {this.state.end}.
                        </div>
                        <div className="bg-light" style={{height: "400px", width: '100%'}}>
                            <MapContainer
                                travel_line={this.state.travel_line}
                                planned_line={this.state.planned_line}
                                bounds={this.state.bounds}
                                mapstyle={this.state.mapstyle}
                                timestamp={this.state.timestamp}
                                pings_display={this.state.pings_display}
                                open_popup={this.state.open_popup}
                                zoom_to={this.state.zoom_to}
                                highlight_point={this.state.highlight_point}
                                onStyleLoad={this.onStyleLoad}
                                triggerShare={this.triggerShare}
                                photo={this.state.photo}
                                equipment_name={this.state.equipment_name}
                                startend={this.state.startend}
                            />
                        </div>
                        <div className={(this.state.show_history == 0 ? "d-none" : "")}>
                            <h4 className="mb-0 pt-3">Detailed Movement Log</h4>
                            <div className={(this.state.show_pings == true ? "d-none" : "btn btn-xs btn-primary")} onClick={() => this.togglePings()}><i
                                className="fas fa-angle-down"></i> View Details
                            </div>
                            <table className={(this.state.show_pings == true ? "table table-sm small table-hover mb-0" : "d-none")}>
                                <thead className="thead-light">
                                <tr>
                                    <th className="p-1">Date / Time</th>
                                    <th className="p-1">Location</th>
                                    <th className="p-1">Ignition</th>
                                    <th className="p-1 text-right">Speed</th>
                                    <th className="p-1">Dir.</th>
                                    <th className="p-1 text-right">Dist. Travelled</th>
                                    <th className="p-1">Driver</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.history.map(function (ping, ping_num) {
                                    var rotate = false;
                                    if (ping.dir == "n") {
                                        rotate = 315;
                                    } else if (ping.dir == "ne") {
                                        rotate = 0;
                                    } else if (ping.dir == "e") {
                                        rotate = 45;
                                    } else if (ping.dir == "se") {
                                        rotate = 95;
                                    } else if (ping.dir == "s") {
                                        rotate = 135;
                                    } else if (ping.dir == "sw") {
                                        rotate = 180;
                                    } else if (ping.dir == "w") {
                                        rotate = 225;
                                    } else if (ping.dir == "nw") {
                                        rotate = 270;
                                    }
                                    return (
                                        <tr>
                                            <td className="p-0">{moment.unix(ping.timestamp).format("YYYY-MM-DD HH:mm")}</td>
                                            <td className="p-0">{ping.desc}</td>
                                            <td className={(ping.is_ignit == 1 ? "p-0 text-success" : (ping.is_ignit == 2 ? "p-0 text-dark" : ""))}>{(ping.is_ignit == 1 ? "On" : (ping.is_ignit == 2 ? "Off" : ""))}</td>
                                            <td className="p-0 text-right">{ping.speed} km/hr</td>
                                            <td className="p-0" title={ping.dir}><i
                                                className={(rotate === false ? "d-none" : "fas fa-location-arrow")}
                                                style={{transform: "rotate(" + rotate + "deg"}}></i></td>
                                            <td className="p-0 pr-1 text-right">{(ping.dist == null ? 0 : ping.dist)} km</td>
                                            <td className="p-0 pl-1">{ping.driver}</td>
                                        </tr>
                                    )
                                }, this)}
                                </tbody>
                            </table>
                        </div>

                        <div className={(this.state.show_load == 0 ? "d-none" : "")}>
                            {this.state.loads.map((load, i2) => {
                                var qty_drive_hrs = money.floatToAmount(0);
                                var qty_wait_hrs = money.floatToAmount(0);
                                var qty_miles = money.floatToAmount(0);
                                return (<div>
                                    <h4 className="mb-0 pt-3">Load # {load.load_id}</h4>
                                    <div className={(load.bol != "" || load.cust_load_num != "" || load.description != "" ? "row" : "d-none")}>
                                        <div className="col col-3"><b>BOL #:</b> {load.bol}</div>
                                        <div className="col col-3"><b>Cust. Load #:</b> {load.cust_load_num}</div>
                                        <div className="col col-6"><b>Description:</b> {load.description}</div>
                                    </div>
                                    <table className="table table-sm table-borderless">
                                        <thead className="thead-light">
                                        <tr>
                                            <th>Desc. / Container</th>
                                            <th>Equipment</th>
                                            <th>Origin</th>
                                            <th>Destination</th>
                                            <th>Planned Start</th>
                                            <th>Planned Miles</th>
                                            <th>Planned Drive Hrs</th>
                                            <th>Planned Wait Hrs</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {load['segments'].map((segment, key2) => {
                                            qty_drive_hrs = money.add(qty_drive_hrs, money.floatToAmount(segment.duration_hrs));
                                            qty_wait_hrs = money.add(qty_wait_hrs, money.floatToAmount(segment.wait_hrs));
                                            qty_miles = money.add(qty_miles, money.floatToAmount(segment.distance_miles));
                                            if (segment.description == null) {
                                                segment.description = "";
                                            }
                                            if (segment.container_num == null) {
                                                segment.container_num = "";
                                            }
                                            return (<tr>
                                                <td>{segment.description}{(segment.description != "" && segment.container_num != "" ? " / " : "")}{segment.container_num}</td>
                                                <td>{(segment.equipment_name == null ? "" : segment.equipment_name)}</td>
                                                <td>{(segment.origin_name == null ? "" : segment.origin_name)}</td>
                                                <td>{(segment.destination_name == null ? "" : segment.destination_name)}</td>
                                                <td>{moment(segment.start).utc().format('HH:mm')}</td>
                                                <td>{(segment.distance_miles == null ? "0" : segment.distance_miles)}</td>
                                                <td>{(segment.duration_hrs == null ? "0" : segment.duration_hrs)}</td>
                                                <td>{(segment.wait_hrs == null ? "0" : segment.wait_hrs)}</td>
                                            </tr>)
                                        }, this)}
                                        </tbody>
                                    </table>
                                </div>)
                            }, this)}
                        </div>
                    </div>
                </div>
                <div className="p-3 text-center small">
                    <span className="mr-2">&copy;</span>
                    <span>2024</span>
                    <span className="mx-1">-</span>
                    <span>Powered by <a href="https://www.customtms.ca">Custom TMS</a></span>
                </div>
            </div>
        );
    }
}

export default Share;



export function MapContainer(props) {
    const mapRef = useRef(null);
    const [popupInfo, setPopupInfo] = useState(null);

    const zoomTo = useCallback(({longitude, latitude, zoom}) => {
        if (typeof longitude!="undefined") {
            mapRef.current?.flyTo({center: [longitude, latitude], zoom: zoom, duration: 2000});
        }
    }, []);

    const setBounds = useCallback((bounds) => {
        if (bounds.y1>0){
            mapRef.current?.fitBounds([
                [bounds.x1, bounds.y1],
                [bounds.x2, bounds.y2]
            ], {padding: 50});
        }
    }, []);

    useEffect(() => { // OPen popup
        if (props.open_popup == false) {
            setBounds(props.bounds);
            setPopupInfo(null);
        } else {
            props.pings_display.map((ping, index) => {
                if (props.open_popup) {
                    setPopupInfo(ping);
                    mapRef.current?.flyTo({center: [ping.longitude, ping.latitude]});
                }
            })
        }
    }, [props.open_popup])

    useEffect(() => {
        zoomTo(props.zoom_to);
    }, [props.zoom_to])

    useEffect(() => {
        if (typeof props.pings_display[0] == "undefined"){
            setPopupInfo(null);
        } else {
            setPopupInfo(props.pings_display[0]);
        }
    }, [props.pings_display])

    useEffect(() => {
        setTimeout(function () {
            setBounds(props.bounds) // use a timeout as it was not reliable without. no idea why
        }, 100);
    }, [props.bounds])

    // This implements `StyleImageInterface` to draw a pulsing dot icon on the map.
    const size = 200;
    const pulsingDot = {
        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),

        onAdd: function () { // When the layer is added to the map, get the rendering context for the map canvas.
            const canvas = document.createElement('canvas');
            canvas.width = this.width;
            canvas.height = this.height;
            this.context = canvas.getContext('2d');
        },

        render: function () { // Call once before every frame where the icon will be used.
            const duration = 1250;
            const t = (performance.now() % duration) / duration;

            const radius = (size / 2) * 0.2;
            const outerRadius = (size / 2) * 0.7 * t + radius;
            const context = this.context;

            // Draw the outer circle.
            context.clearRect(0, 0, this.width, this.height);
            context.beginPath();
            context.arc(
                this.width / 2,
                this.height / 2,
                outerRadius,
                0,
                Math.PI * 2
            );
            context.fillStyle = `rgba(0, 134, 228, ${1 - t})`;
            context.fill();

            // Draw the inner circle.
            context.beginPath();
            context.arc(
                this.width / 2,
                this.height / 2,
                radius,
                0,
                Math.PI * 2
            );
            context.fillStyle = 'rgba(255, 100, 100, 1)';
            context.strokeStyle = 'white';
            context.lineWidth = 2 + 4 * (1 - t);
            context.fill();
            context.stroke();

            this.data = context.getImageData( // Update this image's data with data from the canvas.
                0,
                0,
                this.width,
                this.height
            ).data;
            mapRef.current?.triggerRepaint(); // Continuously repaint the map, resulting in the smooth animation of the dot.
            return true; // Return `true` to let the map know that the image was updated.
        }
    };


    var pins = useMemo(
        () =>
            props.pings_display.map((ping, index) => (
                <Marker
                    key={`marker-${index}`}
                    longitude={ping.lon}
                    latitude={ping.lat}
                    anchor={"center"}
                    onClick={e => {
                        // If we let the click event propagates to the map, it will immediately close the popup
                        // with `closeOnClick: true`
                        e.originalEvent.stopPropagation();
                        setPopupInfo(ping);
                    }}
                >
                    <Pin speed={ping.speed} dir={ping.dir} is_ignit={ping.is_ignit} timestamp={moment().unix()}
                         timestamp_map={moment().unix()} name={props.equipment_name}
                         status={1} show_names={false} driver={ping.driver}
                         show_active={props.show_active}/>
                </Marker>
            )),
        [props.pings_display]
    );

    var startend = useMemo(
        () =>
            props.startend.map((ping, index) => (
                <Marker
                    key={`marker-${index}`}
                    longitude={ping.lon}
                    latitude={ping.lat}
                    anchor={"center"}
                    onClick={e => {
                        // If we let the click event propagates to the map, it will immediately close the popup
                        // with `closeOnClick: true`
                        e.originalEvent.stopPropagation();
                        setPopupInfo(ping);
                    }}
                >
                    <LoadPin type={ping.type}/>
                </Marker>
            )),
        [props.startend]
    );

    return (
        <>
            <Map
                ref={mapRef}
                initialViewState={{
                    longitude: -64.842166,
                    latitude: 46.085063,
                    zoom: 8,
                    bearing: 0,
                    pitch: 0,
                    touchZoomRotate: true,
                    dragRotate: false,
                    showCompass: false,
                    visualizePitch: false,
                    attributionControl: false,
                }}
                onStyleLoad={props.onStyleLoad}
                mapStyle={props.mapstyle}
                onLoad={() => {
                    mapRef.current?.addImage('pulsing-dot', pulsingDot, {pixelRatio: 2});
                }}
            >
                <GeolocateControl position="top-right"/>
                <FullscreenControl position="top-right"/>
                <NavigationControl position="top-right" showCompass={false} visualizePitch={false}/>
                <ScaleControl/>

                {pins}
                {startend}

                <Source id="dot-point" type="geojson" data={props.highlight_point} key={JSON.stringify(props.highlight_point)}>
                    <Layer type="symbol" id="layer-with-pulsing-dot" source="dot-point" layout={{
                        'icon-image': 'pulsing-dot',
                        'icon-allow-overlap': true // important fot display
                    }}/>
                </Source>

                <Source id="polylineLayer" type="geojson" data={props.travel_line} key={JSON.stringify(props.travel_line)}>
                    <Layer
                        id="lineLayer"
                        type="line"
                        source="my-data"
                        layout={{
                            "line-join": "round",
                            "line-cap": "round"
                        }}
                        paint={{
                            "line-color": "rgba(0, 0, 0, 0.5)",
                            "line-width": 4
                        }}
                    />
                </Source>

                <Source id="polylineLayer2" type="geojson" data={props.planned_line} key={JSON.stringify(props.planned_line)}>
                    <Layer
                        id="lineLayer2"
                        type="line"
                        source="my-data"
                        layout={{
                            "line-join": "round",
                            "line-cap": "round"
                        }}
                        paint={{
                            "line-color": "rgba(9, 67, 255, 0.75)",
                            "line-width": 2,
                            "line-dasharray": [1, 2]
                        }}
                    />
                </Source>

                {popupInfo && (
                    <Popup
                        anchor="top"
                        longitude={Number(popupInfo.lon)}
                        latitude={Number(popupInfo.lat)}
                        offset={10}
                        onClose={() => setPopupInfo(null)}
                    >
                        <div style={{minWidth: "220px"}}>
                            <div className="h5 mb-0">
                                {props.equipment_name}
                                <div className="btn btn-xs btn-light float-right p-0" title="Zoom in on point"
                                     onClick={() => zoomTo({longitude: popupInfo.lon, latitude: popupInfo.lat, zoom: 16})}><i
                                    className="fas fa-search-plus fa-sm ml-1 mr-1"></i></div>
                            </div>
                            <div>
                                <span
                                    className={(popupInfo.is_ignit === null || (popupInfo.speed !== null && popupInfo.speed > 0) ? "d-none" : "")}>Ignition <b>{(popupInfo.is_ignit == 1 ? "ON" : "OFF")}</b></span>
                                <span className={(popupInfo.is_ignit === 1 && popupInfo.speed == 0 ? "" : "d-none")}>, </span>
                                <span
                                    className={(popupInfo.speed == null || popupInfo.is_ignit == 2 ? "d-none" : "")}><b>{(popupInfo.is_ignit == null ? "~" : "")}{(popupInfo.speed == null ? 0 : popupInfo.speed)}</b> km/hr<span
                                    className={(popupInfo.dir == null || popupInfo.dir == "" ? "d-none" : "")}>, heading <b>{(popupInfo.dir == null ? "" : popupInfo.dir.toUpperCase())}</b></span>
                                </span>
                            </div>
                            <div className={(popupInfo.desc === null ? "d-none" : "")}>
                                <i>{(popupInfo.desc != null ? popupInfo.desc.replace(', NS', '').replace(', NB', '').replace(', PE', '') : "")}</i></div>
                            <div
                                className={(popupInfo.driver == null ? "d-none" : "")}>Driver: <b>{popupInfo.driver}</b>
                            </div>
                            <small className="text-muted">As
                                of <b>{(props.timestamp == 0 ? moment.duration(moment().unix() - popupInfo.timestamp, 'seconds').humanize() + " ago" : moment(popupInfo.timestamp * 1000).format("hh:mm A MMM. D"))}</b></small>
                        </div>
                        <div className="text-center">
                            <img style={{imageRendering: 'crisp-edges'}} src={"./img/photos/" + props.photo + "_sm.jpg"}
                                 className={(props.photo == null ? "d-none" : "")}/>
                        </div>
                    </Popup>
                )}
            </Map>
        </>
    );
}