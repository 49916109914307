import React, {Component, useState, PureComponent} from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {Link} from 'react-router-dom';
import {
    Card,
    CardBody,
    Row,
    Col,
    InputGroup,
    Input,
    InputGroupAddon,
    DropdownToggle,
    UncontrolledButtonDropdown,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import 'lity/dist/lity.min.js';
import 'lity/dist/lity.min.css';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import 'moment-timezone';
import AddPhoto from './AddPhoto';
import {MapLoad} from "./Map";


import {getEquipmentTypes, getEquipmentTypesSub, getEquipmentManufacturers} from "./EquipmentFunctions";
import getCustomers from "../Customers/CustomerFunctions";
import {getDrivers} from "../Drivers/DriverFunctions";
import getLocations from "../Locations/LocationFunctions";
import LoadStatus from "../Loads/LoadFunctions";
import AddMaintenance from '../Maintenance/AddMaintenance';
import ShareLocation from "./ShareLocation";


moment.tz.setDefault("America/Halifax");

class EquipmentPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            equipment_id: null,
            drivers: {},
            locations: {},
            customers: {},
            customers_sort: [],
            maint_id: null,
            maint_recs: {
                list: [],
                loading: true,
            },
            equipment: {
                loading: true,
                name: "",
                equipment_type_id: null,
                equipment_type_sub_id: null,
                province: '',
                plate: "",
                vin: "",
                acquired_odo: 0,
                status: 1,
                oos_details: "",
                equipment_manufacturer_id: null,
                model: "",
                year: null,
                colour: "",
                height_inches: null,
                width_inches: null,
                axles: null,
                weight_empty_kg: null,
                weight_gawr_kgs: null,
                weight_gvwr_kgs: null,
                length_inches: null,
                acquired_cost: null,
                acquired_from: "",
                date_acquired: null,
                customer_id: 0,
                photos: [],
                location: {
                    name: '',
                    speed: 0,
                    timestamp: moment().unix()
                }
            },
            province_list: ['NB', 'NS', 'PE', 'NL', 'ON', 'QC', 'SK', 'MB', 'AB', 'BC'],

            status_name: "(select)",
            equipment_statuses: {1: "In Service", 2: "Out of Service", 0: "Retired", 3: "Rented"},

            equipment_type_name: "(select)",
            equipment_types: {},

            equipment_type_sub_name: "(select)",
            equipment_types_sub: {},

            equipment_manufacturer_name: "(select)",
            equipment_manufacturers: {},

            safety_inspections: [],

            order_id: null,
            subtotal: 0,
            tax: 0,
            deposit: 0,
            total: 0,
            items: [],
            staff: [],
            sales_rep_select: false,
            sales_rep_name: "- Unassigned -",
            call_frequency_select: false,

            trips: {
                list: [],
                loading: true,
            },
            dropdowns: {
                is_cold: false,

            },
            inventory_sources: [],
            call_frequency_options: {
                0: "Non-Visited",
                7: "Weekly (Tier 1)",
                14: "Bi-Weekly (Tier 2)",
                30: "Monthly (Tier 3)",
                90: "Quarterly (Tier 4)",
            },
            delivery_days: {
                "null": "---",
                "Daily": "Daily",
                "Monday": "Monday",
                "Tuesday": "Tuesday",
                "Wednesday": "Wednesday",
                "Thursday": "Thursday",
                "Friday": "Friday",
                "Saturday": "Saturday",
            },
            delivery_weeks: {
                "null": "---",
                "Weekly": "Weekly",
                "Odd": "Odd",
                "Even": "Even",
            },
            inventory_source_print: null,
            delivery_inventory_source_id: null,
            safety_inspections_modal: false
        };
        this.equipmentChange = this.equipmentChange.bind(this);
        this.addPhotos = this.addPhotos.bind(this);
    }

    componentDidMount() {
        document.title = "Equipment # " + this.props.match.params.equipment_id + " | Custom TMS";
        this.setState({equipment_id: this.props.match.params.equipment_id})
        var self = this;
        axios.defaults.withCredentials = true;
        getEquipmentTypes(function (equipment_types) {
            getEquipmentTypesSub(function (equipment_types_sub) {
                getEquipmentManufacturers(function (equipment_manufacturers) {
                    getCustomers(function (customers) {
                        getDrivers(function (drivers) {
                            getLocations(function (locations) {
                                var customers_sort = [];
                                for (const customer_id of Object.keys(customers)) {
                                    customers_sort.push(customers[customer_id]);
                                }
                                var customers_sort = customers_sort.sort(function (a, b) {
                                    if (a.name < b.name) {
                                        return -1
                                    } else {
                                        return 1
                                    }
                                });
                                axios.get(API_ROOT + '/equipment/' + self.props.match.params.equipment_id)
                                    .then(function (response) {
                                        response.data.loading = false;
                                        self.setState({
                                            customers: customers,
                                            customers_sort: customers_sort,
                                            drivers: drivers,
                                            locations: locations.locations,
                                            equipment_types: equipment_types,
                                            equipment_types_sub: equipment_types_sub,
                                            equipment_manufacturers: equipment_manufacturers,
                                            equipment: response.data,
                                            equipment_id: self.props.match.params.equipment_id,
                                            status_name: self.state.equipment_statuses[response.data.status],
                                            equipment_type_name: (response.data.equipment_type_id > 0 ? equipment_types[response.data.equipment_type_id].name : "(select)"),
                                            equipment_type_sub_name: (response.data.equipment_type_sub_id > 0 ? equipment_types_sub[response.data.equipment_type_sub_id].name : "(select)"),
                                            equipment_manufacturer_name: (response.data.equipment_manufacturer_id > 0 ? equipment_manufacturers[response.data.equipment_manufacturer_id].name : "(select)"),
                                        }, function () {
                                            self.refreshMaintenance()
                                        });
                                    })
                                axios.get(API_ROOT + '/loads/?&equipment_id=' + self.props.match.params.equipment_id)
                                    .then(function (response) {
                                        var trips_sort = [];
                                        for (const trip_id of Object.keys(response.data)) {
                                            trips_sort.push(response.data[trip_id]);
                                        }
                                        var trips_sort = trips_sort.sort(function (a, b) {
                                            if (a.start > b.start) {
                                                return -1
                                            } else {
                                                return 1
                                            }
                                        });
                                        self.setState({
                                            trips: {loading: false, list: trips_sort},
                                        });
                                    })
                            });
                        });
                    });
                });
            });
        });
    }

    loadCard() {
        var self = this;
        axios.get(API_ROOT + '/photos/?equipment_id=' + this.props.match.params.equipment_id)
            .then(function (response) {
                self.setState(
                    {
                        photos: {
                            list: response.data,
                            loading: false,
                        }
                    })
                ;
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    }

    equipmentChange(event) {
        var state = this.state.equipment;
        state[event.target.name] = event.target.value;
        this.setState({
            equipment: state
        });
    }

    updateField = (name, value) => {
        if (typeof value != "string" && typeof value.target != "undefined") {
            value = value.target.value;
        }
        var equipment = this.state.equipment;
        equipment[name] = value;
        this.setState({
            equipment: equipment,
        });
    };

    changeEquipmentType = (equipment_type_id) => {
        var equipment_type_name = this.state.equipment_types[equipment_type_id].name;
        var equipment = this.state.equipment;
        equipment.equipment_type_id = equipment_type_id;
        this.setState({
            equipment: equipment,
            equipment_type_name: equipment_type_name,
            equipment_type_sub_name: "(select)"
        });
    }

    changeEquipmentTypeSub = (equipment_type_sub_id) => {
        var equipment_type_sub_name = this.state.equipment_types_sub[equipment_type_sub_id].name
        var equipment = this.state.equipment;
        equipment.equipment_type_sub_id = equipment_type_sub_id;
        this.setState({
            equipment: equipment,
            equipment_type_sub_name: equipment_type_sub_name
        });
    }

    changeEquipmentManufacturer = (equipment_manufacturer_id) => {
        var equipment_manufacturer_name = this.state.equipment_manufacturers[equipment_manufacturer_id].name
        var equipment = this.state.equipment;
        equipment.equipment_manufacturer_id = equipment_manufacturer_id;
        this.setState({
            equipment: equipment,
            equipment_manufacturer_name: equipment_manufacturer_name
        });
    }

    changeEquipmentStatus = (status) => {
        var equipment = this.state.equipment;
        equipment.status = status;
        this.setState({
            equipment: equipment,
        });
        this.setState({
            status_name: this.state.equipment_statuses[status]
        });
    }

    saveEquipmentDetails = async event => {
        if (event) {
            event.preventDefault();
        }
        var self = this;
        axios.defaults.withCredentials = true;
        axios.put(API_ROOT + '/equipment/' + this.props.match.params.equipment_id, {data: this.state.equipment})
            .then(function (response) {
                toast.success("Changes Saved", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    };

    goToLoad = (load_id) => {
        this.props.history.push("/loads/" + load_id);
    };

    goToBOL = (bol_id) => {
        this.props.history.push("/bol/" + bol_id);
    };

    safety_inspections_toggle = () => {
        this.setState({
            safety_inspections_modal: !this.state.safety_inspections_modal
        });
    };

    addPhotos = (new_photo) => {
        console.log('photo added:' + new_photo)
        var equipment = this.state.equipment;
        equipment['photos'].push({hash: new_photo, description: ''});
        this.setState({
            equipment: equipment
        })
    };

    refreshMaintenance = () => {
        var self = this;
        axios.get(API_ROOT + '/maintenance/?equipment_id=' + this.props.match.params.equipment_id)
            .then(function (response) {
                self.setState(
                    {
                        maint_recs: {
                            list: response.data,
                            loading: false,
                        },
                        maint_id: null
                    })
                ;
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    }

    viewMaintenance = (maint_id) => {
        this.setState({
            maint_id: maint_id
        })
    }


    render() {
        var total_est_cost = 0;
        var total_actual_cost = 0;
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>
                        <Link to="../equipment">Equipment</Link>&nbsp;/&nbsp; {this.state.equipment.name}
                    </div>
                    <div className={(this.state.equipment.customer_id==0?"ml-auto":"d-none")}>
                        <ShareLocation name={this.state.equipment.name} equipment_id={this.props.match.params.equipment_id}/>
                    </div>
                </div>
                <Row>
                    <Col lg={6}>
                        <Card className={(this.state.equipment.loading ? "card card-default whirl traditional" : "card card-default")}>
                            <CardBody>
                                <p className="lead bb">Details</p>
                                <form className="form-horizontal" onSubmit={this.saveEquipmentDetails}>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Number</label>
                                        <div className="col-xl-9">
                                            <Input size="sm" type="text" name="name" value={this.state.equipment.name} required onChange={this.equipmentChange}/>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Owner:</label>
                                        <div className="col-xl-9">
                                            <UncontrolledButtonDropdown className="mr-1">
                                                <DropdownToggle color="secondary" caret style={{fontSize: '13px', width: 'auto'}}>
                                                    {(this.state.equipment.customer_id == null || this.state.equipment.customer_id == 0 ? "Custom TMS" : this.state.customers[this.state.equipment.customer_id].name)}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem key="null" onClick={() => this.updateField('customer_id', null)}>Custom TMS</DropdownItem>
                                                    <DropdownItem divider/>
                                                    {Object.keys(this.state.customers_sort).map((key, i) => {
                                                        return (<DropdownItem key={key}
                                                                              onClick={() => this.updateField('customer_id', this.state.customers_sort[key].customer_id)}>{this.state.customers_sort[key].name}</DropdownItem>)
                                                    })}
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Type</label>
                                        <div className="col-xl-9">
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle size="sm" color="secondary" caret style={{fontSize: '13px', width: 'auto'}}>
                                                    {this.state.equipment_type_name}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {Object.keys(this.state.equipment_types).map((key, i) => {
                                                            if (typeof this.state.equipment_types != "undefined" && typeof this.state.equipment_types[key] != "undefined") {
                                                                return (<DropdownItem key={key}
                                                                                      onClick={() => this.changeEquipmentType(key)}>{this.state.equipment_types[key].name}</DropdownItem>)
                                                            }
                                                        }
                                                    )}
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                            <i className="fas fa-angle-right ml-2 mr-2"></i>
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle size="sm" color="secondary" caret style={{fontSize: '13px', width: 'auto'}}>
                                                    {this.state.equipment_type_sub_name}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {Object.keys(this.state.equipment_types_sub).map((key, i) => {
                                                            if (typeof this.state.equipment_types_sub != "undefined" && typeof this.state.equipment_types_sub[key] != "undefined" && this.state.equipment.equipment_type_id == this.state.equipment_types_sub[key].equipment_type_id) {
                                                                return (
                                                                    <DropdownItem key={key}
                                                                                  onClick={() => this.changeEquipmentTypeSub(key)}>{this.state.equipment_types_sub[key].name}</DropdownItem>
                                                                )
                                                            }
                                                        }
                                                    )}
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </div>
                                    </Row>
                                    <Row className={this.state.equipment.equipment_type_id == 2 ? "mb-1" : "d-none"}>
                                        <label className="col-xl-3 col-form-label pt-0">Is a Reefer?</label>
                                        <div className="col-xl-9">

                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Make & Model</label>
                                        <div className="col-xl-9">
                                            <InputGroup size="sm">
                                                <UncontrolledButtonDropdown>
                                                    <DropdownToggle size="sm" color="secondary" caret style={{fontSize: '13px', width: 'auto'}}>
                                                        {this.state.equipment_manufacturer_name}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        {Object.keys(this.state.equipment_manufacturers).map((key, i) => {
                                                                if (typeof this.state.equipment_manufacturers != "undefined" && typeof this.state.equipment_manufacturers[key] != "undefined") {
                                                                    return (<DropdownItem key={key}
                                                                                          onClick={() => this.changeEquipmentManufacturer(key)}>{this.state.equipment_manufacturers[key].name}</DropdownItem>)
                                                                }
                                                            }
                                                        )}
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                                <i className="fas fa-angle-right mt-2 ml-2 mr-2"></i>
                                                <Input size="sm" type="text" name="model" value={this.state.equipment.model} onChange={this.equipmentChange}/>
                                            </InputGroup>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Year Made</label>
                                        <div className="col-xl-9">
                                            <Input size="sm" type="number" name="year" value={this.state.equipment.year} onChange={this.equipmentChange}/>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">VIN</label>
                                        <div className="col-xl-9">
                                            <Input size="sm" type="text" name="vin" value={this.state.equipment.vin} onChange={this.equipmentChange}/>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Colour</label>
                                        <div className="col-xl-9">
                                            <Input size="sm" type="text" name="colour" value={this.state.equipment.colour} onChange={this.equipmentChange}/>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Province of Reg.</label>
                                        <div className="col-xl-9">
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle color="secondary" size="sm" caret style={{fontSize: '13px', width: 'auto'}}>
                                                    {this.state.equipment.province}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {this.state.province_list.map((province, i) =>
                                                        <DropdownItem key={province} onClick={() => this.updateField('province', province)}>{province}</DropdownItem>
                                                    )}
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Plate #</label>
                                        <div className="col-xl-9">
                                            <Input size="sm" type="text" name="plate" value={this.state.equipment.plate} onChange={this.equipmentChange}/>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Status</label>
                                        <div className="col-xl-9">
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle color="secondary" size="sm" caret style={{fontSize: '13px', width: 'auto'}}>
                                                    {this.state.status_name}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {Object.keys(this.state.equipment_statuses).map((key, i) =>
                                                        <DropdownItem key={key} onClick={() => this.changeEquipmentStatus(key)}>{this.state.equipment_statuses[key]}</DropdownItem>
                                                    )}
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </div>
                                    </Row>
                                    <Row className={(this.state.equipment.status == 2 ? "mb-1" : "d-none")}>
                                        <label className="col-xl-3 col-form-label pt-0">OOS Reason</label>
                                        <div className="col-xl-9">
                                            <Input size="sm" type="text" name="oos_details" value={this.state.equipment.oos_details} onChange={this.equipmentChange}/>
                                        </div>
                                    </Row>
                                    <div className="border m-0 p-1 mb-1 bg-light rounded">
                                        <div className="row pb-1">
                                            <label className="col-xl-3 col-form-label pt-0"></label>
                                            <div className="col-xl-9 small text-bold">SPECIFICATIONS:</div>
                                        </div>
                                        <Row className="mb-1">
                                            <label className="col-xl-4 col-form-label pt-0"># of Axles</label>
                                            <div className="col-xl-8">
                                                <Input size="sm" type="text" name="axles" className="text-right" value={this.state.equipment.axles}
                                                       onChange={this.equipmentChange}/>
                                            </div>
                                        </Row>
                                        <Row className="mb-1">
                                            <label className="col-xl-4 col-form-label pt-0">Height <small>(exterior)</small></label>
                                            <div className="col-xl-8">
                                                <InputGroup size="sm">
                                                    <Input type="text" name="height_inches" className="text-right" value={this.state.equipment.height_inches}
                                                           onChange={this.equipmentChange}/>
                                                    <InputGroupAddon addonType="append">inches</InputGroupAddon>
                                                </InputGroup>
                                            </div>
                                        </Row>
                                        <Row className="mb-1">
                                            <label className="col-xl-4 col-form-label pt-0">Width <small>(exterior)</small></label>
                                            <div className="col-xl-8">
                                                <InputGroup size="sm">
                                                    <Input size="sm" type="text" name="width_inches" className="text-right" value={this.state.equipment.width_inches}
                                                           onChange={this.equipmentChange}/>
                                                    <InputGroupAddon addonType="append">inches</InputGroupAddon>
                                                </InputGroup>
                                            </div>
                                        </Row>
                                        <Row className="mb-1">
                                            <label className="col-xl-4 col-form-label pt-0">Length <small>(exterior)</small></label>
                                            <div className="col-xl-8">
                                                <InputGroup size="sm">
                                                    <Input size="sm" type="text" name="length_inches" className="text-right" value={this.state.equipment.length_inches}
                                                           onChange={this.equipmentChange}/>
                                                    <InputGroupAddon addonType="append">inches</InputGroupAddon>
                                                </InputGroup>
                                            </div>
                                        </Row>
                                        <Row className="mb-1">
                                            <label className="col-xl-4 col-form-label pt-0">Tare Weight <small>(excl. cargo)</small></label>
                                            <div className="col-xl-8">
                                                <InputGroup size="sm">
                                                    <Input size="sm" type="text" name="weight_empty_kg" className="text-right" value={this.state.equipment.weight_empty_kg}
                                                           onChange={this.equipmentChange}/>
                                                    <InputGroupAddon size="sm" addonType="append">kg</InputGroupAddon>
                                                </InputGroup>
                                            </div>
                                        </Row>
                                        <Row className="mb-1">
                                            <label className="col-xl-4 col-form-label pt-0">GAWR</label>
                                            <div className="col-xl-8">
                                                <InputGroup size="sm">
                                                    <Input size="sm" type="text" name="weight_gawr_kg" className="text-right" value={this.state.equipment.weight_gawr_kg}
                                                           onChange={this.equipmentChange}/>
                                                    <InputGroupAddon size="sm" addonType="append">kg</InputGroupAddon>
                                                </InputGroup>
                                            </div>
                                        </Row>
                                        <Row className="mb-0">
                                            <label className="col-xl-4 col-form-label pt-0">GVWR</label>
                                            <div className="col-xl-8">
                                                <InputGroup size="sm">
                                                    <Input size="sm" type="text" name="weight_gvwr_kg" className="text-right" value={this.state.equipment.weight_gvwr_kg}
                                                           onChange={this.equipmentChange}/>
                                                    <InputGroupAddon size="sm" addonType="append">kg</InputGroupAddon>
                                                </InputGroup>
                                            </div>
                                        </Row>
                                    </div>
                                    <div className="border m-0 p-1 mb-1 bg-light rounded">
                                        <div className="row pb-1">
                                            <label className="col-xl-3 col-form-label pt-0"></label>
                                            <div className="col-xl-9 small text-bold">ACQUIRED:</div>
                                        </div>
                                        <Row className="mb-1">
                                            <label className="col-xl-3 col-form-label pt-0">Purchase Date</label>
                                            <div className="col-xl-9">
                                                <Input size="sm" type="date" name="date_acquired" value={this.state.equipment.date_acquired} onChange={this.equipmentChange}/>
                                            </div>
                                        </Row>
                                        <Row className="mb-1">
                                            <label className="col-xl-3 col-form-label pt-0">Acquired From</label>
                                            <div className="col-xl-9">
                                                <Input size="sm" type="text" name="acquired_from" value={this.state.equipment.acquired_from} onChange={this.equipmentChange}/>
                                            </div>
                                        </Row>
                                        <Row className="mb-0">
                                            <label className="col-xl-3 col-form-label pt-0">Purchase Price</label>
                                            <div className="col-xl-9">
                                                <InputGroup size="sm">
                                                    <InputGroupAddon size="sm" addonType="append">$</InputGroupAddon>
                                                    <Input size="sm" type="text" name="acquired_odo" className="text-right" value={this.state.equipment.acquired_odo}
                                                           onChange={this.equipmentChange}/>
                                                </InputGroup>
                                            </div>
                                        </Row>
                                    </div>

                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Notes</label>
                                        <div className="col-xl-9">
                                            <Input type="textarea" style={{minHeight: "50px"}} name="notes" value={this.state.equipment.notes} onChange={this.equipmentChange}/>
                                        </div>
                                    </Row>
                                    <Row className="">
                                        <div className="offset-xl-3 col-xl-9">
                                            <button className="btn btn-primary" type="submit">Save Changes</button>
                                        </div>
                                    </Row>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={6}>
                        <Card className="card card-default d-none">
                            <CardBody>
                                <p className="lead bb">Maintenance</p>
                                <Row>
                                    <label className="pt-0 col-xl-4 col-form-label">Current Odometers</label>
                                    <div className="col-xl-8">
                                        230,000 km | 112,283 miles
                                    </div>
                                </Row>
                                <Row>
                                    <label className="pt-0 col-xl-4 col-form-label">Next Service Due</label>
                                    <div className="col-xl-8">
                                        240,000km (approximately 17 days, 14 hours to go)
                                    </div>
                                </Row>
                                <Row>
                                    <label className="pt-0 col-xl-4 col-form-label">Safety Inspection Expiry</label>
                                    <div className="col-xl-8">
                                        June 1, 2024 <small>in 330 days</small>
                                    </div>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className="card card-default d-none">
                            <CardBody>
                                <p className="lead bb">Statistics <div className="float-right small">period: <a href="#">30 days</a> | <a href="#">365 days</a> | lifetime</div></p>
                                <Row>
                                    <label className="pt-0 col-xl-4 col-form-label">Miles Driven</label>
                                    <div className="col-xl-8">
                                        <div className="text-bold">23,213 miles <small>(916 per day)</small></div>
                                    </div>
                                </Row>
                                <Row>
                                    <label className="pt-0 col-xl-4 col-form-label">Avg MPG</label>
                                    <div className="col-xl-8">
                                        <div className="text-bold">11.2 mpg</div>
                                    </div>
                                </Row>
                                <Row>
                                    <label className="pt-0 col-xl-4 col-form-label">Avg MPG</label>
                                    <div className="col-xl-8">
                                        <div className="text-bold">11.2 mpg</div>
                                    </div>
                                </Row>
                                <Row>
                                    <label className="pt-0 col-xl-4 col-form-label">Maintenance $/Mile</label>
                                    <div className="col-xl-8">
                                        <div className="text-bold">$10.2/mile / $8.1/km</div>
                                    </div>
                                </Row>
                                <Row>
                                    <label className="pt-0 col-xl-4 col-form-label">Cost $/Mile <small>(incl. fuel)</small></label>
                                    <div className="col-xl-8">
                                        <div className="text-bold">$19.2/mile / $14.1/km</div>
                                    </div>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                            <CardBody>
                                <div className="lead bb">
                                    {this.state.equipment.photos.length} Photo{(this.state.equipment.photos.length > 1 ? 's' : '')}
                                    <div className="float-right">
                                        <AddPhoto equipment_id={this.props.match.params.equipment_id} addPhotos={this.addPhotos}/>
                                    </div>
                                </div>
                                <div>
                                    {this.state.equipment.photos.map(function (id, key) {
                                        return (
                                            <img src={"https://app.ghtranspo.com/img/photos/" + id['hash'] + ".jpg"} title={id['description']} className="img-fluid rounded mx-auto d-block"
                                                 style={{maxHeight: '250px'}}/>
                                        )
                                    }, this)}
                                </div>
                            </CardBody>
                        </Card>
                        <Card className={(this.state.loading ? "card card-default whirl traditional" : (this.state.equipment.location == false ? "d-none" : "card card-default"))}>
                            <CardBody>
                                <div className="lead bb">
                                    Current Location
                                    <div className="float-right">
                                        <Link to={"../map/" + this.state.equipment_id}>
                                            <div className="btn btn-xs btn-primary mb-2">View on Fleet Map</div>
                                        </Link>
                                    </div>
                                </div>
                                <div>
                                    As
                                    of {moment.duration(moment().unix() - this.state.equipment.location.timestamp, 'seconds').humanize()} ago: {(this.state.equipment.location.type == "ping" ? "Located at " : "Scheduled to be ")} {this.state.equipment.location.name}, {(this.state.equipment.location.speed == null ? "0 km/hr" : "moving " + this.state.equipment.location.speed + " km/hr")}
                                </div>
                                <div style={{height: "250px"}}>
                                    <MapLoad point={this.state.equipment.location}/>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Card className={(this.state.trips.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <CardBody>
                        <p className="lead bb">{this.state.trips.list.length} Trips</p>
                        <div className="table-responsive">
                            <table className={(this.state.trips.list.length > 0 && !this.state.trips.loading ? "table table-sm table-hover table-pointer" : "d-none")}>
                                <thead>
                                <tr>
                                    <th className="d-none d-lg-table-cell">#</th>
                                    <th className="d-none d-md-table-cell">Date</th>
                                    <th className="d-none d-md-table-cell">Origin</th>
                                    <th className="d-none d-lg-table-cell">Destination</th>
                                    <th className="d-none d-lg-table-cell">Customer</th>
                                    <th className="d-none d-lg-table-cell">Driver</th>
                                    <th className="d-none d-lg-table-cell">Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.trips.list.map(function (item, key) {
                                    return (
                                        <tr key={key} onClick={this.goToLoad.bind(this, item.load_id)}>
                                            <td className="d-none d-lg-table-cell">{item.load_id}</td>
                                            <td className="d-none d-md-table-cell">{moment(item.start).format("MMM. D/YY HH:mm")}</td>
                                            <td className="d-none d-md-table-cell">{(item.origin != null && typeof this.state.locations[item.origin] != "undefined" ? this.state.locations[item.origin].name : "")}</td>
                                            <td className="d-none d-md-table-cell">{(item.destination != null && typeof this.state.locations[item.destination] != "undefined" ? this.state.locations[item.destination].name : "")}</td>
                                            <td className="d-none d-lg-table-cell">{(item.customer_id != null && typeof this.state.customers[item.customer_id] != "undefined" ? this.state.customers[item.customer_id].name : "")}</td>
                                            <td className="d-none d-lg-table-cell">{(item.driver_id != null && typeof this.state.drivers[item.driver_id] != "undefined" ? this.state.drivers[item.driver_id].first_name + " " + this.state.drivers[item.driver_id].last_name : "")}</td>
                                            <td><LoadStatus status={item.status}/></td>
                                        </tr>
                                    )
                                }, this)}
                                </tbody>
                            </table>
                        </div>
                    </CardBody>
                </Card>
                <Card style={(this.state.equipment.customer_id != 0 ? {display: "none"} : {})}
                      className={(this.state.maint_recs.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <CardBody>
                        <p className="lead bb">{this.state.maint_recs.list.length} Maintenance Records
                            <div className="float-right">
                                <AddMaintenance maint_id={this.state.maint_id} equipment_id={this.props.match.params.equipment_id} refreshMaintenance={this.refreshMaintenance}/>
                            </div>
                        </p>
                        <div className="table-responsive">
                            <table
                                className={(this.state.maint_recs.list.length > 0 && !this.state.maint_recs.loading ? "table table-sm table-hover table-pointer" : "d-none")}>
                                <thead>
                                <tr>
                                    <th className="d-none d-lg-table-cell">#</th>
                                    <th className="d-none d-md-table-cell">Date Ent.</th>
                                    <th className="d-none d-md-table-cell">Category</th>
                                    <th className="d-none d-md-table-cell">Description</th>
                                    <th className="d-none d-md-table-cell">Urgency</th>
                                    <th className="d-none d-lg-table-cell">Vendor</th>
                                    <th className="text-right d-none d-lg-table-cell">Est. Cost</th>
                                    <th className="text-right d-none d-lg-table-cell">Act. Cost</th>
                                    <th className="d-none d-lg-table-cell">Status</th>
                                    <th className="d-none d-lg-table-cell">Reminder</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.maint_recs.list.map(function (item, key) {
                                    if (item.cost_est > 0) {
                                        total_est_cost = total_est_cost + item.cost_est;
                                    }
                                    if (item.cost_actual > 0) {
                                        total_actual_cost = total_actual_cost + item.cost_actual;
                                    }
                                    return (
                                        <tr key={key} onClick={this.viewMaintenance.bind(this, item.maint_id)}>
                                            <td className="d-none d-lg-table-cell">{item.maint_id}</td>
                                            <td className="d-none d-md-table-cell">{moment(item.date_enter).format("MMM. D/YY HH:mm")}</td>
                                            <td className="d-none d-lg-table-cell">{item.category}</td>
                                            <td className="d-none d-lg-table-cell">{item.description}</td>
                                            <td className="d-none d-md-table-cell">{(item.urgency == 0 ? "Low" : (item.urgency == 1 ? "Medium" : "High (OOS)"))}</td>
                                            <td className="d-none d-lg-table-cell">{item.vendor_name}</td>
                                            <td className="text-right d-none d-lg-table-cell">{(item.cost_est > 0 ? "$" + item.cost_est : "")}</td>
                                            <td className="text-right d-none d-lg-table-cell">{(item.cost_actual > 0 ? "$" + item.cost_actual : "")}</td>
                                            <td className="text-left">
                                                <div className={(item.status == 0 ? "badge badge-dark" : "d-none")}>Pending Auth.</div>
                                                <div className={(item.status == 1 ? "badge badge-danger" : "d-none")}>Declined</div>
                                                <div className={(item.status == 2 ? "badge badge-info" : "d-none")}>Approved</div>
                                                <div className={(item.status == 3 ? "badge badge-purple" : "d-none")}>Completed, Not Billed</div>
                                                <div className={(item.status == 4 ? "badge badge-green" : "d-none")}>Completed</div>
                                            </td>
                                            <td className="d-none d-lg-table-cell">{(item.reminder_date != null ? "Yes" : "No")}</td>
                                        </tr>
                                    )
                                }, this)}
                                </tbody>
                                <tfoot>
                                <tr>
                                    <th className="text-right" colspan="6">Total:</th>
                                    <th className="text-right d-none d-lg-table-cell">${total_est_cost}</th>
                                    <th className="text-right d-none d-lg-table-cell">${total_actual_cost}</th>
                                    <th colSpan="2"></th>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                    </CardBody>
                </Card>
                <ToastContainer/>
            </ContentWrapper>
        );
    }

}

export default EquipmentPage;
