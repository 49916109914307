import React, {Component} from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import {Link} from 'react-router-dom';
import moment from 'moment';
import 'moment-timezone';
import {getEquipment} from "../Equipment/EquipmentFunctions";
import {
    Row,
    Col,
    Input, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledButtonDropdown
} from 'reactstrap';
import NewMessage from "./NewMessage"
import {getDriversLogged, getDrivers} from "../Drivers/DriverFunctions";
import axios from "axios";
import {API_ROOT} from "../../api-config";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {getStaff} from "../Staff/StaffFunctions";

moment.tz.setDefault("America/Halifax");

class DispatchInbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            equipment: {},
            staff: {},
            drivers_logged: {},
            drivers: {},
            drivers_sort: [],
            ranges: {
                'Today': [moment(), moment()],
                'Last 7 Days': [moment().subtract(6, 'days'), moment().endOf('day')],
                'Last 30 Days': [moment().subtract(29, 'days'), moment().endOf('day')],
                'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                'All Time': [moment("01 Apr 2024"), moment().endOf('day')]
            },
            search: {
                term: "",
                to: "all",
                from: "all",
                startDate: moment(),
                endDate: moment(),
                loading: false,
                searched: false,
                list: []
            },
            search_open: false,
        };
        this.updateDates = this.updateDates.bind(this);
    }

    componentDidMount() {
        document.title = "Dispatch | Demo Company Transportation";
        var self = this;
        getEquipment(0, function (vehicles) {
            if (Object.keys(vehicles).length > 0) {
                self.setState({
                    equipment: vehicles,
                },);
                self.onSort(null, self.state.sort)
            }
            self.updateDriverPosition();
            setInterval(self.updateDriverPosition, 60000);
        });
        getDrivers(function (drivers) {
            var drivers_sort = [];
            for (const driver_id of Object.keys(drivers)) {
                var obj = drivers[driver_id];
                obj['name'] = obj.first_name + ' ' + obj.last_name;
                drivers_sort.push(obj)
            }
            self.setState({
                drivers: drivers,
                drivers_sort: drivers_sort
            },);
        });
        if (this.props.messages.list.length == 0) {
            self.props.refreshMessages();
        }
    }

    goToThread = (driver_id) => {
        this.props.history.push((this.props.mode == "maintenance" ? "/maint_mssg/" : "/dispatch/") + driver_id);
    }

    updateDriverPosition = () => {
        var self = this;
        getDriversLogged(null, function (drivers_logged) {
            self.setState({
                drivers_logged: drivers_logged
            },);
        });
    }

    toggleSubscribe = (key) => {
        var self = this;
        axios.defaults.withCredentials = true;
        axios.post(API_ROOT + '/messages/subscribe', {action: (this.props.messages.subscribed.includes(key) ? "unsubscribe" : "subscribe"), key: key})
            .then(function (response) {
                self.props.refreshMessages();
            })
    }

    update_search() {
        var self = this;
        getStaff(function (staff) {
            self.setState({staff: staff});
            axios.defaults.withCredentials = true;
            var search = self.state.search;
            search.loading = true;
            search.searched = false;
            self.setState({
                search: search
            });
            axios.get(API_ROOT + "/messages/search?mode=" + self.props.mode + "&term=" + self.state.search.term + "&to=" + self.state.search.to + "&from=" + self.state.search.from + "&startDate=" + self.state.search.startDate + "&endDate=" + self.state.search.endDate)
                .then(function (response) {
                    search.list = response.data.messages;
                    search.loading = false;
                    search.searched = true;
                    self.setState({
                        search: search,
                    });
                })
        });
    }

    toggleSearch = (status) => {
        this.setState({
            search_open: status,
            search: {
                term: "",
                to: "all",
                from: "all",
                startDate: moment().subtract(6, 'days'),
                endDate: moment().endOf('day'),
                loading: false,
                searched: false,
                list: []
            }
        });
    }
    updateSearch = (name, value) => {
        var search = this.state.search
        search[name] = value
        this.setState({
            search: search
        });
    };

    updateDates(event, picker) {
        if (event.type == "apply") {
            var search = this.state.search;
            search['startDate'] = picker.startDate;
            search['endDate'] = picker.endDate;
            this.setState({
                search: search
            });
        }
    }

    toggleSaved = (action, message_id) => {
        var self = this;
        axios.defaults.withCredentials = true;
        axios.post(API_ROOT + '/messages/save', {action: action, message_id: message_id})
            .then(function (response) {
                self.update_search();
            })
    }

    forward_message = (message_id) => {
        this.setState({
            default_message: "From " + (typeof this.state.drivers[this.state.driver_id] == "undefined" ? this.state.search.list[message_id].from : this.state.drivers[this.state.driver_id].first_name + ' ' + this.state.drivers[this.state.driver_id].last_name) + ": " + this.state.search.list[message_id].body,
            default_attachments: ["mms/" + this.state.search.list[message_id].media]
        })
    }

    render() {
        let start = this.state.search.startDate.format('MMM. D/YY');
        let end = this.state.search.endDate.format('MMM. D/YY');
        let label = start + ' - ' + end;
        var from = "All Drivers & Dispatch";
        if (this.state.search.from == "all_drivers") {
            from = "All Drivers";
        } else if (this.state.search.from == 0) {
            from = "Dispatch";
        } else if (this.state.search.from > 0) {
            from = this.state.drivers[this.state.search.from].first_name + " " + this.state.drivers[this.state.search.from].last_name;
        }
        var to = "All Drivers & Dispatch";
        if (this.state.search.to == "all_drivers") {
            to = "All Drivers";
        } else if (this.state.search.to == 0) {
            to = "Dispatch";
        } else if (this.state.search.to > 0) {
            to = this.state.drivers[this.state.search.to].first_name + " " + this.state.drivers[this.state.search.to].last_name;
        }

        function boldMatchingWord(text, searchTerm) {
            const pattern = new RegExp(searchTerm, 'gi'); // 'gi' for case-insensitive and global search
            return text.replace(pattern, '<b class="text-inverse">$&</b>');
        }

        var messages = this.props.messages;
        if (this.props.mode == "maintenance") {
            messages = this.props.messages_maint;
        }
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>{(this.props.mode == "maintenance" ? "Maintenance Messages" : "Dispatch")}</div>
                    <div className={(this.props.messages.loading ? "d-none" : "btn btn-light btn-xs ml-auto")}
                         title={(this.props.messages.subscribed.includes((this.props.mode == "dispatch" ? 'all' : 'all_maint')) ? "You are subscribed to SMS notifications for new messages" : "Click to subscribe to SMS notifications for new messages")}>
                        <i className={(this.props.messages.subscribed.includes((this.props.mode == "dispatch" ? 'all' : 'all_maint')) ? "fas fa-bell text-warning" : "far fa-bell-slash")}
                           onClick={() => this.toggleSubscribe((this.props.mode == "dispatch" ? 'all' : 'all_maint'))}></i>
                    </div>
                </div>
                <div className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <div>
                        <div className="card-body">
                            <Row>
                                <Col xl={12} md={12}>
                                    <div className="h4 mb-0 text-bold clearfix">
                                        {(this.props.mode == "maintenance" ? "INBOX" : "DISPATCH MESSAGES")}
                                        <div className="float-right">
                                            <NewMessage default_message={this.state.default_message} key={this.state.drivers} drivers={this.state.drivers}
                                                        drivers_sort={this.state.drivers_sort} default_attachments={this.state.default_attachments} mode={this.props.mode}/>
                                        </div>
                                        <div className={(this.state.search_open || this.props.mode != "dispatch" ? "d-none" : "btn btn-sm mr-1 btn-primary float-right")}
                                             onClick={() => this.toggleSearch(true)}>
                                            <em className="fas fa-search"></em> Search
                                        </div>
                                        <small className="text-muted"><br/>{(this.props.mode == "maintenance" ? "Maintenance # 506-802-2060" : "Dispatch #: 506-802-2009")}</small>
                                    </div>
                                    <div className={(this.state.search_open ? "bg-dark p-2 form-inline input-group text-center" : "d-none")}>
                                        <input type="text" className="form-control from-control-sm" placeholder="Enter search term, or leave blank to show all messages"
                                               value={this.state.search.term} onKeyDown={(e) => (e.key === 'Enter' ? this.update_search() : console.log(1))}
                                               onChange={(e) => this.updateSearch('term', e.target.value)}/>
                                        <UncontrolledButtonDropdown>
                                            <DropdownToggle color="light" caret style={{fontSize: '13px', width: 'auto'}}>
                                                <b>From:</b> {from}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem key="all" onClick={() => this.updateSearch('from', 'all')}>All Drivers & Dispatch</DropdownItem>
                                                <DropdownItem key="all_drivers" onClick={() => this.updateSearch('from', 'all_drivers')}>All Drivers</DropdownItem>
                                                <DropdownItem key="-" onClick={() => this.updateSearch('from', '0')}>Dispatch</DropdownItem>
                                                <DropdownItem divider/>
                                                {Object.keys(this.state.drivers_sort).map((key, i) => {
                                                    return (
                                                        <DropdownItem key={key}
                                                                      onClick={() => this.updateSearch('from', this.state.drivers_sort[key].driver_id)}>{this.state.drivers_sort[key].name}</DropdownItem>
                                                    )
                                                })}
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                        <UncontrolledButtonDropdown>
                                            <DropdownToggle color="light" caret style={{fontSize: '13px', width: 'auto'}}>
                                                <b>To:</b> {to}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem key="all" onClick={() => this.updateSearch('to', 'all')}>All Drivers & Dispatch</DropdownItem>
                                                <DropdownItem key="all_drivers" onClick={() => this.updateSearch('to', 'all_drivers')}>All Drivers</DropdownItem>
                                                <DropdownItem key="0" onClick={() => this.updateSearch('to', '0')}>Dispatch</DropdownItem>
                                                <DropdownItem divider/>
                                                {Object.keys(this.state.drivers_sort).map((key, i) => {
                                                    return (
                                                        <DropdownItem key={key}
                                                                      onClick={() => this.updateSearch('to', this.state.drivers_sort[key].driver_id)}>{this.state.drivers_sort[key].name}</DropdownItem>
                                                    )
                                                })}
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                        <DateRangePicker
                                            startDate={this.state.search.startDate}
                                            endDate={this.state.search.endDate}
                                            ranges={this.state.ranges}
                                            maxDate={moment()}
                                            onEvent={this.updateDates}
                                            autoUpdateInput={true}
                                        >
                                            <button type="button" style={{fontSize: '13px'}} className="btn btn-light pb-2 selected-date-range-btn">
                                                <b>Between:</b> {label} <span className="fas fa-caret-down fa-sm"></span>
                                            </button>
                                        </DateRangePicker>
                                        <div className="btn btn-success" onClick={() => this.update_search()}>Search</div>
                                        <div className="btn btn-outline-light ml-1" onClick={() => this.toggleSearch(false)}>Close</div>
                                    </div>

                                    <div className={(this.state.search_open ? "" : "d-none")}>
                                        <div
                                            className={(this.state.search.loading == false ? (this.state.search.list.length > 0 || this.state.search.searched ? "mb-1" : "d-none") : "whirl traditional mb-1")}>
                                            <h4 className="mt-2 mb-0">Search Results:</h4>
                                            <div style={{height: 'calc(100vh - 250px)', overflowY: "auto", border: "2px solid #e4e2e2"}} id="message-list">
                                                <div className={(this.state.search.searched && this.state.search.list.length == 0 ? "" : "d-none")}>
                                                    <div className="text-center text-danger">No results found</div>
                                                </div>
                                                {Object.keys(this.state.search.list).map((key, item) => {
                                                    var media = [];
                                                    if (this.state.search.list[key].media != null) {
                                                        media = this.state.search.list[key].media.split(',');
                                                    }
                                                    var read_by = [];
                                                    if (this.state.search.list[key].read_by != null) {
                                                        read_by = this.state.search.list[key].read_by.split(',');
                                                    }
                                                    var driver = "Unknown Contact";
                                                    if (typeof this.state.drivers[this.state.search.list[key].driver_id] != "undefined") {
                                                        driver = this.state.drivers[this.state.search.list[key].driver_id].first_name + ' ' + this.state.drivers[this.state.search.list[key].driver_id].last_name;
                                                    }
                                                    return (
                                                        <div key={key} name={"id" + key} id={"id" + key}>
                                                            <div className="row no-gutters p-1" style={{borderTop: '1px dashed #B1B1B1', cursor: "pointer"}}
                                                                 onClick={this.goToThread.bind(this, this.state.search.list[key].driver_id + '#jumpto' + this.state.search.list[key].message_id)}>
                                                                <div className="col col-sm-3 col-lg-2">
                                                                    <div className="text-bold">{driver}</div>
                                                                    {moment.unix(this.state.search.list[key].date_sent).format("MMM D HH:mm")}
                                                                    <UncontrolledButtonDropdown className="ml-2">
                                                                        <DropdownToggle color="light" size="xs"><i className="fa-solid fa-ellipsis-vertical"></i></DropdownToggle>
                                                                        <DropdownMenu>
                                                                            <DropdownItem onClick={() => this.forward_message(key)}><i className="fa-solid fa-share"></i> Forward
                                                                                Message</DropdownItem>
                                                                            <DropdownItem divider/>
                                                                            <DropdownItem
                                                                                className={(typeof this.state.staff[this.state.search.list[key].staff_id] == "undefined" ? "d-none" : "")}>By: {(typeof this.state.staff[this.state.search.list[key].staff_id] == "undefined" ? "" : this.state.staff[this.state.search.list[key].staff_id].first_name + ' ' + this.state.staff[this.state.search.list[key].staff_id].last_name)}</DropdownItem>
                                                                            <DropdownItem>Date: {moment.unix(this.state.search.list[key].date_sent).format("YYYY-MM-DD HH:mm:ss")}</DropdownItem>
                                                                            <DropdownItem>From #: {this.state.search.list[key].from}</DropdownItem>
                                                                            <DropdownItem>To #: {this.state.search.list[key].to}</DropdownItem>
                                                                            <DropdownItem divider/>
                                                                            {read_by.map((staff_id, item) => {
                                                                                return (<DropdownItem>Seen
                                                                                    by {(typeof this.state.staff[staff_id] == "undefined" ? "" : this.state.staff[staff_id].first_name + ' ' + this.state.staff[staff_id].last_name)}</DropdownItem>)
                                                                            })}
                                                                        </DropdownMenu>
                                                                    </UncontrolledButtonDropdown>
                                                                    <div className="btn btn-xs btn-light ml-1" title="Save this message"
                                                                         onClick={() => this.toggleSaved((this.state.search.list[key].saved == 1 ? 0 : 1), this.state.search.list[key].message_id)}>
                                                                        <i className={(this.state.search.list[key].saved == 1 ? "fas fa-star text-warning" : "far fa-star")}></i>
                                                                    </div>
                                                                </div>
                                                                <div className="col col-sm-9 col-lg-10">
                                                                    <div className={(this.state.search.list[key].body == "" ? "d-none" : "clearfix")}>
                                                                        <div
                                                                            className={(this.state.search.list[key].from == "+19028137070" || this.state.search.list[key].from == "+19512600066" ? "float-right" : "text-left")}
                                                                            style={{maxWidth: "75%", width: "fit-content"}}>
                                                                            <div
                                                                                className={(this.state.search.list[key].from === "+19028137070" || this.state.search.list[key].from === "+19512600066" ? "bg-info-dark rounded pl-2 pr-2 pt-1 pb-1 mr-2" : "bg-gray-light text-dark rounded pl-2 pr-2 pt-1 pb-1")}
                                                                                dangerouslySetInnerHTML={{__html: boldMatchingWord(this.state.search.list[key].body, this.state.search.term)}}
                                                                            ></div>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className={(media.length == 0 ? "d-none" : (this.state.search.list[key].from == "+19028137070" || this.state.search.list[key].from == "+19512600066" ? "text-right" : "text-left"))}>
                                                                        {Object.keys(media).map((key2, item2) => {
                                                                            var extension = media[key2].split('.').pop();
                                                                            if (extension == 'jpeg' || extension == 'jpg' || extension == 'png' || extension == 'webp' || extension == 'gif') {
                                                                                return (
                                                                                    <a href={"mms/" + media[key2]} target="_blank"><img
                                                                                        src={"mms/" + media[key2]} className="img-fluid rounded p-1"
                                                                                        style={{maxWidth: "400px", maxHeight: "250px", height: "250px"}} alt="View Attachment"
                                                                                        title="Click to View"/>
                                                                                    </a>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <a href={"mms/" + media[key2]} target="_blank" className="m-2 btn btn-xs btn-primary">View
                                                                                        Attachment (.{extension})</a>
                                                                                )
                                                                            }
                                                                        }, this)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }, this)}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={(this.state.search_open ? "d-none" : "")}>
                                        <table
                                            className={(!messages.loading ? "table table-sm table-hover table-pointer" : "d-none")}>
                                            <tbody>
                                            {messages.list.map((thread, key) => {
                                                return (
                                                    <tr key={key} onClick={this.goToThread.bind(this, thread.driver_id)} className={(thread.num_unread > 0 ? "table-warning" : "")}>
                                                        <td>
                                                            <div className="row no-gutters">
                                                                <div className="col col-sm-5">
                                                                    <b>{(thread.name == null ? "Unknown Contact" : thread.name)}</b>
                                                                </div>
                                                                <div className="col text-muted">
                                                                    {(typeof this.state.drivers_logged[thread.driver_id] != "undefined" ? "Logged into truck " : "")}
                                                                    <b>{(typeof this.state.drivers_logged[thread.driver_id] != "undefined" ? this.state.equipment[this.state.drivers_logged[thread.driver_id].equipment_id].name : "")}</b>
                                                                    {(typeof this.state.drivers_logged[thread.driver_id] != "undefined" ? " since " + moment(this.state.drivers_logged[thread.driver_id].in_time).format('HH:mm') : "")}
                                                                    <span
                                                                        className={(thread.num_unread == 0 ? "d-none" : "badge badge-danger float-right")}>{thread.num_unread}</span>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="float-left mr-4">{moment.unix(thread.date_sent).format("MMM D/ HH:mm")}</div>
                                                                <span style={{
                                                                    display: "block",
                                                                    whiteSpace: "nowrap",
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                    maxWidth: "calc(100vw - 300px)",
                                                                    width: "fit-content"
                                                                }}
                                                                      className={(thread.from == "+19028137070" || thread.from == "+19512600066" ? "rounded bg-info-dark text-light pl-2 pr-2" : "rounded bg-gray-light text-dark pl-2 pr-2")}>
                                                                    <span className={(thread.is_call == 1 ? "d-none" : "")}>{thread.body}</span>
                                                                    <span className={(thread.is_call == 0 ? "d-none" : "font-italic small")}><i className="fas fa-phone"></i> Called Dispatch</span>
                                                                </span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }, this)}
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default (DispatchInbox);
