import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'moment-timezone';
import getCustomers from "./CustomerFunctions";
import AddCustomer from "../Customers/AddCustomer";

moment.tz.setDefault("America/Halifax");

class CustomerList extends React.Component {
    constructor(props) {
        super(props);

        this.updateDates = this.updateDates.bind(this);
        this.goToCustomer = this.goToCustomer.bind(this);

        this.state = {
            customers: {},
            customers_sort: [],
            loading: true,
            startDate: (localStorage.getItem('acc_startDate') != null ? moment(localStorage.getItem('acc_startDate')) : moment("01 Jan 2023")),
            endDate: (localStorage.getItem('acc_endDate') != null ? moment(localStorage.getItem('acc_endDate')) : moment().endOf('day')),
            ranges: {
                'Today': [moment(), moment()],
                'Last 7 Days': [moment().subtract(6, 'days'), moment().endOf('day')],
                'Last 30 Days': [moment().subtract(29, 'days'), moment().endOf('day')],
                'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                'All Time': [moment("01 Jan 2023"), moment().endOf('day')]
            },
            sort: (localStorage.getItem('cu_sort') != null ? localStorage.getItem('cu_sort') : "name"),
            sort_order: (localStorage.getItem('cu_sort_order') != null ? localStorage.getItem('cu_sort_order') * -1 : -1),
        };
    }

    componentDidMount() {
        document.title = "Customers | Custom TMS";
        var self = this;
        console.log('CustomerList')
        getCustomers(function (customers) {
            if (Object.keys(customers).length > 0) {
                console.log(customers)
                self.setState({
                    customers: customers,
                    loading: false,
                },);
                self.onSort(null, self.state.sort)
            }
        });
    }

    // on date range change
    updateDates(event, picker) {
        if (event.type == "apply") {
            localStorage.setItem('acc_startDate', picker.startDate);
            localStorage.setItem('acc_endDate', picker.endDate);
            this.setState({
                startDate: picker.startDate,
                endDate: picker.endDate
            });
        }
    }

    goToCustomer = (customer_id) => {
        this.props.history.push("/customer/" + customer_id);
    };

    onSort(event, sortKey) {
        localStorage.setItem('cu_sort', sortKey);
        localStorage.setItem('cu_sort_order', this.state.sort_order);
        var sort_order = this.state.sort_order * -1;
        if (sortKey != this.state.sort) {
            sort_order = 1;
        }

        function format_search_val(val) {
            if (isNaN(val)) {
                return val.toLowerCase();
            } else {
                return parseFloat(val);
            }
        }

        var customers_sort = [];
        for (const customer_id of Object.keys(this.state.customers)) {
            var obj = this.state.customers[customer_id];
            obj['name'] = obj.name;
            customers_sort.push(obj)
        }
        var customers = customers_sort.sort((a, b) => {
            if (typeof format_search_val(a[sortKey]) == "string" && typeof format_search_val(b[sortKey]) != "string") {
                return (sort_order == -1 ? -1 : 1);
            } else {
                if (format_search_val(a[sortKey]) > format_search_val(b[sortKey])) {
                    return (sort_order == -1 ? -1 : 1);
                }
                if (format_search_val(a[sortKey]) < format_search_val(b[sortKey])) {
                    return (sort_order == -1 ? 1 : -1);
                }
            }
            return 0;
        });
        this.setState({
            sort: sortKey,
            sort_order: sort_order,
            customers_sort: customers
        });
    }

    render() {

        // date range selector variables
        let start = this.state.startDate.format('MMM. D/YY');
        let end = this.state.endDate.format('MMM. D/YY');
        let label = start + ' - ' + end;
        if (start === end) {
            label = start;
        }

        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>Customers</div>
                    <div className="ml-auto">
                        <AddCustomer size={"sm"} goToCustomer={this.goToCustomer}/>
                    </div>
                </div>
                <div className="mb-3 form-inline" style={{zIndex: 1000}}>
                    <DateRangePicker
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        ranges={this.state.ranges}
                        maxDate={moment()}
                        onEvent={this.updateDates}
                        autoUpdateInput={true}
                    >
                        <button type="button" className="btn btn-primary selected-date-range-btn mr-1">
                            Summary Period:* <b>{label}</b> <span className="fas fa-caret-down fa-sm"></span>
                        </button>
                    </DateRangePicker>
                </div>
                <div className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <div>
                        <div className="card-header">
                            <div className="card-title">{this.state.customers_sort.length} customers</div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className={(this.state.customers_sort.length > 0 ? "table table-hover table-pointer text-right" : "d-none")}>
                                    <thead>
                                    <tr>
                                        <th className="text-left" onClick={e => this.onSort(e, 'name')}><i className="fas fa-sort"></i> Name</th>
                                        <th className="text-left" onClick={e => this.onSort(e, 'name')}><i className="fas fa-sort d-none"></i> Loads</th>
                                        <th className="text-left" onClick={e => this.onSort(e, 'name')}><i className="fas fa-sort d-none"></i> Billed</th>
                                        <th className="text-left" onClick={e => this.onSort(e, 'name')}><i className="fas fa-sort d-none"></i> Receivable</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {Object.entries(this.state.customers_sort).map(([key, customers]) => {
                                        return (
                                            <tr key={key} onClick={this.goToCustomer.bind(this, customers.customer_id)}>
                                                <td className="text-left">{customers.name}</td>
                                                <td className="text-right">{}</td>
                                                <td className="text-right">{}</td>
                                                <td className="text-left">{}</td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default (CustomerList);